.productos-page {


  span.ubicacion {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;

    a {
      font-family: Flama;
      font-size: 11px;
      color: #868891;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;

      &:hover {
        text-decoration: none;
      }
    }

    padding-top: 30px;
    display: block;
  }

  .the-form-sss {
    position: relative;
    z-index: 1;
    top: 300px;

    span.busqueda {
      color: #343741;
      display: block;
      margin-top: 5vw;
      font-size: 26px;
      border-top: 3px solid #AFB1B7;
    }

    form {
      margin-top: 55px;
      width: 100%;
      @media screen and (max-width: 1280px) {
        width: 100%;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 25px;
      }


      input {
        @media screen and (max-width: 1280px) {
          //padding: 12px 18px;
        }
        width: 200px;
        background-color: transparent;
        font-size: 14px;
        line-height: 18px;
        color: #343741;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        padding: 12px 0;
        outline: 0;
        font-weight: bold;
        border: 1px solid transparent;
        border-bottom: 1px solid #575756;
        border-radius: 0;
        box-shadow: none;
        //background-position: 100% center;

        //&::placeholder{
        //  font-weight: bold;
        //  color:#343741;
        //}

      }

      select {
        @media screen and (max-width: 1280px) {
          padding: 12px 18px;
        }
        width: 200px;
        background-color: transparent;
        font-size: 14px;
        line-height: 18px;
        color: #575756;
        background-image: url(../../images/arrowdown.png);
        background-repeat: no-repeat;
        background-size: 14px 8px;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        padding: 12px 0;
        outline: 0;
        font-weight: bold;
        border: 1px solid transparent;
        border-bottom: 1px solid #575756;
        border-radius: 0;
        box-shadow: none;
        background-position: 100% center;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;

      }

      button {
        width: 200px;
        background-color: transparent;
        font-size: 15px;
        line-height: 18px;
        color: #575756;
        text-transform: uppercase;
        font-weight: bold;
        background-image: url(../../images/arrowrounded.png);
        background-repeat: no-repeat;
        background-size: 18px 18px;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        padding: 12px 0;
        outline: 0;
        border: 1px solid transparent;
        border-bottom: 2px solid #575756;
        border-radius: 0;
        box-shadow: none;
        background-position: 100% center;
        transition: .5s;

        &:hover {
          //@keyframes animation-name {
          //  0% {
          //    border-left: 1px solid #575756;
          //  }
          //  50% {
          //    border-top: 1px solid #575756;
          //  }
          //  100% {
          //
          //  }
          //}
          //animation: animation-name 1s ease infinite;
          color: #9e0349;
        }

      }

      @media screen and (max-width: 768px) {
        input,
        select,
        button {
          width: 100%;
        }
      }

      @media screen and (max-width: 500px) {
        input,
        select,
        button {
          width: 100%;
        }
      }


    }

    .indicador {
      margin: 70px 0;
      width: 18px;
      height: 18px;
      background-color: #9E0349;

    }
  }



  .hero-top {

    .hero-top-image {
      position: relative;
      background-position-y: center;

      .opacidad-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.6);
        padding: 0;
        width: 100%;

        div.hero-top-info {
          padding: 65px 10% 0 1%;
          left: 0;
          @media screen and (min-width: 1200px) {
            padding: 65px 9% 0 0%;
            display: inline-block;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
          }


          span.titulo {
            font-family: Flama;
            margin: 0 0 50px 0;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 62px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0;
            }
          }

          span.sub-titulo {
            font-weight: 500;
            line-height: 28px;
            color: #343741;
            font-size: 15px;
          }

          .indicador {
            margin: 70px 0;
            width: 18px;
            height: 18px;
            background-color: #9E0349;

          }

        }

      }

    }

  }





  .contenido {
    background-color: #EFF3F6;

    .abc-pagination {
      padding: 80px 0;
      display: flex;
      align-items: center;


      @media screen and (max-width: 500px) {
        display: none;
      }


      .indicador {
        width: 18px;
        height: 18px;
        background-color: #9E0349;
      }

      div {
        width: 100%;

        a {
          margin: 0 10px;
          font-family: Flama;
          color: #343741;
          font-size: 24px;
          text-decoration: none;
        }

        a:nth-child(1) {
          margin: 0 25px;
          font-size: 24px;
          text-decoration: underline;
          text-transform: capitalize;
        }

        .active{
          background-color: #9e0349;
          padding: 0 8px;
          border-radius: 7px;
          color: white;

        }


        @media screen and (max-width: 767px) {
          a:nth-child(1) {
            display: block;
          }
        }

      }

    }

    .product-content {

      div.padre {
        &:hover {
          background-color: white;
          -webkit-box-shadow: 0px 39px 214px -41px rgba(159, 159, 159, 1);
          -moz-box-shadow: 0px 39px 214px -41px rgba(159, 159, 159, 1);
          box-shadow: 0px 39px 214px -41px rgba(159, 159, 159, 1);

          h3.nombre {
            a {
              transition: .3s;
              color: #720023 !important;

              span {
                transition: .2s;
                color: #720023 !important;
                font-weight: bold !important;
              }


              &:hover {

                text-decoration: none;

                span {
                  text-decoration: none;
                }
              }


            }

          }

        }
      }

      position: relative;

      ul {
        padding: 0 30px;
        @media screen and (max-width: 767px) {
          padding: 0 10px;
        }
        margin: 0 !important;
        border-bottom: 1px solid #eeeeee;
        border-top: 1px solid #eeeeee;
        list-style: none;

        li {
          padding: 5px 0;
          display: flex;
          align-items: center;
          position: relative;

          a.redirect {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 2;

          }

          a.numero-producto {
            margin: 0 40px;
            text-align: center;
            font-size: 18px;
            font-family: Flama;
            width: 70px;

            @media screen and (max-width: 500px) {
              margin: 0;
              width: auto;
            }

          }


          .contenedor-imagen {
            background-color: white;
            width: 300px;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 500px) {
              width: 100px;
              //height: auto;
              //display: block;
              //width: auto;
              height: auto;
              margin: 0 20px;
            }

          }

          img.imagen-producto {
            max-height: 100%;
            max-width: 100%;
            height: auto;
            margin: auto;
            @media screen and (max-width: 767px){
              max-height: none;
              max-width: none;
              width: 100%;
              object-fit: cover;
            }

          }

          div.textos {
            display: flex;
            align-items: center;
            width: 40%;
            //text-align: center;
            margin-left: 40px;
            @media screen and (max-width: 767px) {
              width: 70%;
              margin-left: 13px;

            }

            @media screen and (max-width: 500px) {
              //width: auto;
              display: block;
              text-align: initial;

            }

            h3.nombre {
              a {
                color: #343741;
                text-transform: uppercase;
                display: block;
                //margin-left: 45px;
                @media screen and (max-width: 768px) {
                  font-size: 20px;
                  display: block;

                }

                @media screen and (max-width: 500px) {
                  margin: 0;
                  font-size: 15px;
                }


                &:hover {

                  text-decoration: none;

                  span {
                    text-decoration: none;
                  }
                }

                span {
                  color: #979EA6;
                  text-transform: capitalize;
                }
              }

            }
          }

          div.tacc-image {
            width: 70px;
            height: 70px;
            background-size: cover;
            background-position: center;
            margin-left: auto;

            @media screen and (max-width: 500px) {
              width: 35px;
              height: 35px;
            }


          }
        }
      }

    }

    .numeric-pagination {
      display: flex;
      padding: 80px 0;
      align-items: center;

      @media screen and (max-width: 767px) {
        justify-content: center;
      }

      .indicador {
        width: 18px;
        height: 18px;
        background-color: #9E0349;
        margin-right: 4vw;
        @media screen and (max-width: 767px) {
          position: absolute;
          left: 20px;
        }
      }

      span {
        font-size: 24px;
        color: #C14B85;
        text-decoration: underline;
        margin: 0 .5vw;
      }

      a {
        color: #343741;
        font-size: 24px;
        margin: 0 .2vw;
      }

    }

  }

}
