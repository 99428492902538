.institucional-page {

  span.ubicacion {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    a{
      font-family: Flama;
      font-size: 11px;
      color: #868891;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
      &:hover{
        text-decoration: none;
      }
    }
    padding-top: 30px;
    display: block;
  }


  .hero-top {

    .hero-top-image {
      position: relative;
      background-position-y: center;

      .opacidad-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.6);
        text-align: right;

        //@media screen and (max-width: 1023px) {
        //  width: 100%;
        //}

        //background-color: rgba(255, 255, 255, 0.6);
        //background: rgba(255, 255, 255, 0.6);

        div.hero-top-info {
          display: inline-block;
          max-width: 685px;
          text-align: left;

          @media screen and (max-width: 1280px) {
            padding: 65px 10% 0 4%;
          }


          span.titulo {

            font-family: Flama;
            margin: 50px 0;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 62px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0;
            }
          }

          span.sub-titulo {
            font-weight: 500;
            line-height: 28px;
            color: #343741;
            font-size: 15px;
          }

          .indicador {
            margin: 70px 0;
            width: 18px;
            height: 18px;
            background-color: #9E0349;

          }

        }

      }

    }

  }

  .contenido {

    .entrada {

      .single {
        margin: 100px 0;
        display: flex;

        @media screen and (max-width: 1023px) {
          flex-direction: column;
        }

        .entrada-info {
          width: 50%;
          @media screen and (max-width: 1023px) {
            width: 100%;
            flex-direction: column;
            div {
              flex-direction: column;
            }
          }

          .primer-columna-entrada {
            width: 50%;
            display: flex;

            @media screen and (max-width: 1023px){
              width: 100%;
            }
          }

          .segunda-columna-entrada {
            width: 50%;
            @media screen and (max-width: 1023px){
              width: 100%;
            }
          }

          .indicador {
            width: 18px;
            height: 18px;
            background-color: #9E0349;

          }

          .titulo {
            display: block;
            //margin-top: 70px;
            margin-top: 40px;
            margin-bottom: 50px;
            font-family: Flama;
            font-size: 48px;
            font-weight: 500;
            line-height: 58px;
            color: #343741;
          }

          .titulo-mobie-margin{
            @media screen and (min-width: 1024px){
              margin:0;
            }
          }

          .sub-titulo {
            font-weight: 500;
            font-size: 15px;
            color: #343741;
            line-height: 28px;

          }
        }

        .entrada-image {
          width: 50%;
          @media screen and (max-width: 1023px) {
            width: 100%;
            padding: 0 !important;
          }
          display: flex;
          justify-content: center;
          //align-items: center;
          align-items: flex-start;


          img {
            max-width: 100%;
            height: auto;
          }

        }


      }

    }

    .izquierda {
      @media screen and (max-width: 1023px) {
        flex-direction: column-reverse !important;
      }
    }

  }

  .hero-bottom {
    background-color: #F7F9FB;
    display: flex;
    margin-top: 100px;

    .hero-bottom-info {
      width: 50%;
      padding:5%;
      //padding: 100px 13% 0 14%;

      @media screen and (max-width: 1280px) {
        padding: 65px 10% 0 2%;
      }

      .indicador {
        width: 18px;
        height: 18px;
        background-color: #9E0349;
      }

      span.titulo {
        font-family: Flama;
        //margin: 70px 0;
        margin: 40px 0;

        @media screen and (max-width: 1280px) {
          margin: 35px 0;
        }
        display: block;
        font-size: 48px;
        color: #343741;
      }

      span.sub-titulo {
        line-height: 28px;
        font-size: 15px;
        color: #343741;
        letter-spacing: 1px;
        display: block;
        font-weight: 500;
        margin-bottom: 30px;
      }

      span.info {
        color: #979EA6;
        line-height: 24px;
        font-size: 12px;
      }

    }

    .image-hero {
      width: 50%;
      height: 700px;
    }
  }

}
