.single-producto {

  span.ubicacion {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;

    a {
      font-family: Flama;
      font-size: 11px;
      color: #868891;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;

      &:hover {
        text-decoration: none;
      }
    }

    padding-top: 30px;
    display: block;
  }

  .hero-top {

    .hero-top-image {
      position: relative;

      .opacidad-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.6);

        div.hero-top-info {
          padding: 100px 0 0 0;

          @media screen and (max-width: 1280px) {
            padding: 65px 10% 0 4%;
          }


          span.titulo {

            font-family: Flama;
            margin: 30px 0;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 62px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0;
            }
          }

          span.sub-titulo {
            font-weight: 500;
            line-height: 28px;
            color: #343741;
            font-size: 15px;
          }

          span.busqueda {
            color: #343741;
            display: block;
            margin-top: 5vw;
            font-size: 26px;
            border-top: 3px solid #AFB1B7;
          }

          div.formularioSearchSingleProducto {
            margin-top: 55px;
            width: 100%;
            @media screen and (max-width: 1280px) {
              width: 100%;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: 1023px) {
              flex-direction: column;
            }

            input {
              @media screen and (max-width: 1280px) {
                padding: 12px 18px;
              }
              width: 200px;
              background-color: transparent;
              font-size: 14px;
              line-height: 18px;
              color: #343741;
              background-image: url(../../images/arrowdown.png);
              background-repeat: no-repeat;
              background-size: 14px 8px;
              backface-visibility: hidden;
              transform-style: preserve-3d;
              padding: 12px 0;
              outline: 0;
              font-weight: bold;
              border: 1px solid transparent;
              border-bottom: 1px solid #575756;
              border-radius: 0;
              box-shadow: none;
              background-position: 100% center;
            }

            select {
              @media screen and (max-width: 1280px) {
                padding: 12px 18px;
              }
              width: 200px;
              background-color: transparent;
              font-size: 14px;
              line-height: 18px;
              color: #575756;
              background-image: url(../../images/arrowdown.png);
              background-repeat: no-repeat;
              background-size: 14px 8px;
              backface-visibility: hidden;
              transform-style: preserve-3d;
              padding: 12px 0;
              outline: 0;
              font-weight: bold;
              border: 1px solid transparent;
              border-bottom: 1px solid #575756;
              border-radius: 0;
              box-shadow: none;
              background-position: 100% center;
              -moz-appearance: none; /* Firefox */
              -webkit-appearance: none; /* Safari and Chrome */
              appearance: none;

            }

            button {
              width: 200px;
              background-color: transparent;
              font-size: 15px;
              line-height: 18px;
              color: #575756;
              text-transform: uppercase;
              font-weight: bold;
              background-image: url(../../images/arrowrounded.png);
              background-repeat: no-repeat;
              background-size: 18px 18px;
              backface-visibility: hidden;
              transform-style: preserve-3d;
              padding: 12px 0;
              outline: 0;
              border: 1px solid transparent;
              border-bottom: 2px solid #575756;
              border-radius: 0;
              box-shadow: none;
              background-position: 100% center;

              &:hover {
                @keyframes animation-name {
                  0% {
                    border-left: 1px solid #575756;
                  }
                  50% {
                    border-top: 1px solid #575756;
                  }
                  100% {

                  }
                }
                animation: animation-name 1s ease infinite;
              }

            }

          }

          .indicador {
            margin: 70px 0;
            width: 18px;
            height: 18px;
            background-color: #9E0349;

          }

        }

      }

    }

  }

  .product-content {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    padding: 100px 0;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }

    .producto-imagen {
      width: 50%;

      @media screen and (max-width: 500px) {
        width: 100%;
      }

      .formato {
        max-width: 450px;
        margin: 0 auto;
        position: relative;
        z-index: 1;

        .imagen {
          width: 100%;
          height: 250px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: white;
        }

        .lupa {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .imagen-lupa {
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
          }

          a {
            text-transform: uppercase;
            color: white;
            font-family: Flama;
            font-weight: 500;

            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }

        }

        &:hover {
          background-color: #AD0455;
          opacity: 1;
          cursor: pointer;

          .imagen {
            opacity: .2;
          }

          .lupa {
            z-index: 4;
            opacity: 1 !important;


            a, span {
              position: absolute;
            }

            span {
              top: 60%;
              color: white;
              text-decoration: underline;
              font-weight: 500;

              &:hover {
                cursor: pointer;
              }
            }
          }

        }


      }


      .galeria {
        width: 90%;
        margin: auto;
        list-style: none;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .galeria li {
        margin: 5px;
      }

      .galeria li:first-child {
        display: none;
      }

      .galeria img {
        width: auto;
        height: 100px;
      }


    }

    .producto-informacion {
      width: 50%;
      display: flex;
      flex-flow: wrap;

      @media screen and (max-width: 500px) {
        width: 100%;
        text-align: center;
      }

      .ultimosdatos,
      .producto-informacion-inner-columnas {

        @media screen and (max-width: 500px) {
          flex-direction: column;
        }

        span.titulo {
          font-family: Flama;
          color: #343741;
          font-weight: 500;
          font-size: 18px;
        }

        span.info {
          color: #343741;
          font-size: 18px;
        }

        span.adicionales {
          color: #AD0455;
          font-size: 14px;

          a {
            font-family: Flama;
            font-weight: 500;
            text-decoration: underline;
            color: #AD0455;
            font-size: 14px;
          }
        }

        div.primer-columna {
          width: 200px;
          margin-right: 45px;
          @media screen and (max-width: 768px) {
            margin-right: 0;
          }
          @media screen and (max-width: 500px) {
            width: 100%;
          }

          div {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
          }

        }

        div.segunda-columna {
          width: 200px;
          @media screen and (max-width: 500px) {
            width: 100%;
          }

          div {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
          }

        }
      }


    }

  }

  .posts {

    display: flex;
    justify-content: space-around;

    .previous-post {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        font-family: Flama;
        color: #343741;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
      }

      .arrow-image {
        margin-top: 12px;
        background-image: url('../../images/arrow-1.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 42px;
        height: 17px;
      }
    }

    .next-post {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      a {
        font-family: Flama;
        color: #343741;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 1.2px;

      }

      .arrow-image {
        background-image: url('../../images/arrow-1.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 42px;
        height: 17px;
        transform: rotate(180deg);
      }

    }

  }


  .prueba {


    /*Estilos del modal*/

    .modal {
      display: none;
    }

    .modal:target {
      display: block;
      position: fixed;
      background: rgba(0, 0, 0, 0.8);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .modal h3 {
      color: #fff;
      font-size: 30px;
      text-align: center;
      margin: 15px 0;
    }

    .imagen {
      width: auto;
      height: 50%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .imagen a {
      color: #fff;
      font-size: 40px;
      text-decoration: none;
      margin: 0 10px;
    }

    .imagen a:nth-child(2) {
      margin: 0;
      height: 100%;
      flex-shrink: 2;
    }

    .imagen img {
      width: auto;
      height: 100%;
      max-width: 100%;
      border: 7px solid #fff;
      box-sizing: border-box;
    }

    .cerrar {
      display: block;
      background: #fff;
      width: 37px;
      height: 37px;
      margin: 16px auto;
      text-align: center;
      text-decoration: none;
      font-size: 25px;
      color: #000;
      padding: 3px;
      border-radius: 50%;
      line-height: 29px;
    }


  }


}
