.container {
  max-width: 1400px !important;
}

html {
  //overflow-x: hidden;
}

span {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: normal;
}

main.main {
  //padding:0 !important;
  padding-right: 0 !important;
}


.search-form {
  position: relative;
  z-index: 1;

  .search-field {
    background: none !important;
  }

  input.search-submit {
    position: absolute;
    z-index: 987;
    right: 0;
    top: 0;
    opacity: 1;
    width: 30px;
    border-radius: 0;
    height: 38px;
    background-image: url(../../images/searchmobile.png);
    background-repeat: no-repeat;
    background-size: 18px 18px;
    padding: 0;
    text-indent: -99999px;
    display: block;
    background-position: 50% 50%;
    background-color: transparent !important;
    border: 0 none;
    outline: none;
    box-shadow: 0 0 0 0 transparent !important;

    &:hover {
      background-color: transparent !important;
      background-image: url(../../images/searchmobile_2.png);
      border: 0 none;
      outline: none;
      box-shadow: 0 0 0 0 transparent !important;
    }

  }

}


/* galeria_img_list */
.galeria_img_list {
  background: transparent;
  overflow: hidden;
  width: 100%;
  display: block;

  .file-list-wrap {
    margin-bottom: 25px;
  }

  .file-list-image {
    width: 100%;
    padding: 0;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    height: 300px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

div.slider,
.general {
  visibility: hidden;
  opacity: 0;
}

body.raffo-front {
  background: url('../../images/ajax-loader.gif') 50% 200px no-repeat;
}





.a-resultados {
  color: #575756;
  font-weight: 700;
  font-size: 23px;
  background: url(../../images/arrowrounded.png) 0 16px no-repeat;
  padding: 10px 180px 10px 40px;
  margin-bottom: 10px;
  border-top: 1px solid #575756;
  display: block;
  width: 100%;
  text-decoration: none;
  position: relative;
  z-index: 1;

  span {
    background: #9e0349;
    color: #fff;
    position: absolute;
    z-index: 9;
    top: 16px;
    right: 0;
    border-radius: 25px;
    padding: 1px 25px;
    font-size: 14px;
    line-height: 30px;
  }

  &:hover {
    color: #9e0349 !important;
    text-decoration: none !important;

    span {
      background: #eee;
      color: #9e0349;
    }

  }
}

.the_posts_navigation_box {
  padding: 50px auto;

  .nav-previous a {
    background-image: url(../../images/arrow-2.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 30px;
    text-decoration: none;
    display: block;
    width: 62px;
    overflow: hidden;
    text-indent: -99999px;
    float: right;
  }

  .nav-next a {

    background-image: url(../../images/arrow-1.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 30px;
    text-decoration: none;
    display: block;
    width: 62px;
    overflow: hidden;
    text-indent: -99999px;
    float: left;
  }

}


@media screen and (max-width: 1280px) {

  .n2-ss-layer.n2-ow.text-top {
    max-width: 455px !important;
    overflow: visible !important;
    font-size: 100% !important;
    position: absolute !important;
    z-index: 987654 !important;
    top: 0 !important;
    left: 0 !important;
    padding: 95px 10% 0 2% !important;
    margin: 0 !important;
  }

  .n2-ss-slider .n2-ss-layer[data-has-maxwidth="1"][data-pm=normal] {
    margin: 0 !important;
  }

  div.slider .n2-section-smartslider div.n2-ss-control-bullet {
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    left: 0px !important;
    bottom: 2% !important;
  }

  #recuadroBlanco {
    left: 20px;
    margin-left: 2%;
    bottom: 7%;
  }

}

body #recuadroBlanco {
  display: none !important;
}

.nextend-bullet-bar.n2-ow.n2-bar-justify-content-center div.n2-ow {
  color: #000000 !important;
  margin: 0 !important;
  padding: 15px 18px !important;
  font-size: 18px;
  font-family: "IBM Plex Sans";
  cursor: pointer;

  span {
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    font-family: "IBM Plex Sans";
    cursor: pointer;
  }
}

.nextend-bullet-bar.n2-ow.n2-bar-justify-content-center div.n2-ow.n2-active span {
  color: #ffffff !important;
}

.nextend-bullet-bar.n2-ow.n2-bar-justify-content-center div.n2-ow:hover {
  color: #ffffff !important;
}
