.trazabilidad-page {

  .hero-top {

    .hero-top-image {
      position: relative;
      background-position-y: center;

      .opacidad-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.6);
        text-align: right;
        padding-right: 345px;

        @media screen and (max-width: 767px) {
          width: 100%;
        }



        div.hero-top-info {
          //padding: 100px 13% 0 28%;
          display: inline-block;
          text-align: left;
          margin-top: 280px;




          @media screen and (max-width: 1100px) {
            padding: 65px 10% 0 4%;
          }

          span.ubicacion {
            font-family: Flama;
            font-size: 11px;
            color: #868891;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 500;
          }

          span.titulo {

            font-family: Flama;
            margin: 50px 0;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 62px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0;
            }
            @media screen and (max-width: 768px) {
              font-size: 45px;
            }
          }

          span.sub-titulo {
            font-weight: 500;
            line-height: 28px;
            color: #343741;
            font-size: 15px;
          }

          .indicador {
            margin: 70px 0;
            width: 18px;
            height: 18px;
            background-color: #9E0349;

          }

        }

      }

    }

  }

  .entrada{
    display: flex;
    border-bottom:1px solid #83847f;

    @media screen and (max-width:1023px){
      flex-direction: column;
    }

    .entrada-info {
      align-items: center;

      padding: 130px 0;
      width: 50%;
      display: flex;
      @media screen and (max-width:1023px){
        padding: 55px 0;
      }
      @media screen and (max-width:900px){
        width: 100%;
        flex-direction: column;
        div{
          flex-direction: column;
        }
      }
      .indicador {
        width: 18px;
        height: 18px;
        background-color: #9E0349;
        @media screen and (max-width:1023px){
          margin-right: auto;
        }
      }

      .title-span{
        font-size: 32px;
        color:#343741;
        font-family: Flama;
        margin-left: 60px;

        @media screen and (max-width:1023px){
          text-align: center;
        }
        @media screen and (max-width: 900px){
          margin:0;
        }
      }
      .titulo {
        display: block;
        margin-top: 70px;
        margin-bottom: 50px;
        font-family: Flama;
        font-size: 48px;
        font-weight: 500;
        line-height: 58px;
        color: #343741;
      }
      .sub-titulo {
        line-height: 28px;
        font-size: 15px;
        color: #343741;
        letter-spacing: 1px;
        display: block;

      }
    }
  }
  .entrada:last-child{
    border:none;
  }




  .first-entrada-top {
    background-color: #F7F9FB;
    display: flex;



    .contenedor-first-entrada{
      display: flex;

      @media screen and (max-width:1023px){
        flex-direction: column;
          padding-bottom:65px;

      }

      .hero-bottom-info {
        width: 50%;
        padding: 100px 0;

        @media screen and (max-width: 1280px) {
          padding: 65px 0;
        }
        @media screen and (max-width:1023px){
          padding: 65px 0 0;
          width: 100%;
        }

        .indicador {
          width: 18px;
          height: 18px;
          background-color: #9E0349;

        }

        span.titulo {
          font-family: Flama;
          margin: 70px 0;

          @media screen and (max-width: 1280px) {
            margin: 35px 0;
          }
          display: block;
          font-size: 48px;
          color: #343741;
        }

        span.sub-titulo {
          line-height: 28px;
          padding-top: 65px;
          font-size: 15px;
          color: #343741;
          letter-spacing: 1px;
          display: block;
          margin-bottom: 80px;
        }

        span.info {
          color: #979EA6;
          line-height: 24px;
          font-size: 12px;
        }

      }

      .image-hero {
        width: 50%;
        background-position: center;
        height: 300px;
        margin: auto;
        background-size: contain;
        background-repeat: no-repeat;




      }
    }


  }

  .hero-bottom {
    background-color: #F7F9FB;
    display: flex;
    justify-content: center ;
    margin-top:65px;

    @media screen and (max-width:1023px){
      flex-direction: column;
      padding-bottom:35px;
    }

    .hero-bottom-info {
      display: flex;
      width: 50%;
      padding: 0px 70px;
      margin: auto;
      @media screen and (max-width:1023px){
        width: 100%;
        align-items: center;
      }

      @media screen and (max-width: 1280px) {

      }

      .primer-columna{
        img{

        }

      }
      .segunda-columna{
        width: 100%;
        padding-left: 25px;
        .indicador {
          width: 18px;
          height: 18px;
          background-color: #9E0349;

        }

        span.titulo {
          font-family: Flama;
          margin-top:-15px;
          margin-bottom:25px;

          @media screen and (max-width: 1280px) {
            margin: 35px 0;
          }
          display: block;
          font-size: 36px;
          font-weight: 500;
          color: #9e0348;
        }

        span.sub-titulo {
          line-height: 28px;
          font-size: 15px;
          color: #343741;
          letter-spacing: 1px;
          display: block;
        }

        span.info {
          color: #979EA6;
          line-height: 24px;
          font-size: 12px;
        }

      }
    }//
    .image-hero {
      width: 50%;
      height: 600px;
      background-position-x: 100%;
      background-repeat: no-repeat;
      @media screen and (max-width:1023px){
        width: 100%;
        padding:40px 0;
        height: 720px;
      }
      @media screen and (max-width:500px) {
      height: 375px;
      }
    }
  }

}
