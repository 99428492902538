#productosDestacados {
  background-color: #f7f9fb;
  padding-top: 40px;
  padding-bottom: 40px;

  .contenedor {
    display: flex;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    .bloque-izquierda {
      width: 70%;

      @media screen and (max-width: 700px) {
        width: 100%;
      }

      .imagen-producto-destacado {
        height: 385px;
        background-color: #ffffff;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 330px) {
          margin-top: 60px;
        }

        img {
          max-height: 90%;
        }


      }

      .descripcion-producto-destacado {
        border-top: 1px solid #ebebeb;
        height: 85px;
        background-color: #ffffff;
        display: flex;
        align-items: center;

        .logo {
          margin-right: auto;
        }

        .nombre {
          margin-left: auto;

          .titulo {
            font-weight: 600;
          }

          .especialidad {
            font-weight: 300;
          }
        }
      }

      .contador-producto-destacado {
        height: 90px;
        background-color: #f7f9fb;
        display: flex;


        .botones-deslizar-producto-destacado {
          justify-content: flex-end;
          display: flex;

          #destacadosRetroceder, #destacadosAvanzar {
            height: 90px;
            width: 90px;
            background-color: #d1d5d9;
            color: #ffffff;
            border: none;
            font-size: 22px;
            font-weight: bold;
            &:hover{
              cursor: pointer;
            }
          }

          #destacadosRetroceder {
            margin-right: 5px;
            &:hover{
              cursor: pointer;
            }
          }
        }
      }


      .grafico-producto-destacado {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .grafico {
          width: 66.66%;
          height: 5px;
          background-color: #ebebeb;
          display: flex;
          justify-content: space-around;

          div {
            width: 100%;
            moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
          }

          .relleno {
            height: 5px;
            background-color: #AD0455;
            moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
          }

        }

      }


    }

    .bloque-derecha {
      width: 30%;

      @media screen and (max-width: 700px) {
        width: 100%;
      }


      background: #890437; /* Old browsers */
      background: -moz-linear-gradient(top, #890437 2%, #a80a53 43%, #a90451 43%, #a90451 86%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #890437 2%, #a80a53 43%, #a90451 43%, #a90451 86%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #890437 2%, #a80a53 43%, #a90451 43%, #a90451 86%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#890437', endColorstr='#a90451', GradientType=0); /* IE6-9 */


      display: flex;
      flex-direction: column;
      padding: 50px;
      justify-content: space-between;

      .titulo-vadecum {
        font-size: 42px;
        font-family: Flama;
        color: white;
        line-height: 52px;
      }

      .descripcion-vadecum {
        font-size: 18px;
        color: white;
        font-family: "IBM Plex Sans";
        line-height: 28px;
        padding-bottom: 55%;

      }

      a.readMore {
        background-color: transparent;
        border: none;
        color: white;
        font-family: Flama;
        font-weight: 600;
        font-size: 11px;
        display: flex;
        align-items: center;
        width: max-content;
        font-size: 11px;
        moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
        border-radius: 15px;
        padding: 2px 10px;
        border: 1px solid transparent;
        text-decoration: none;

        span {
          moz-transition: all .4s ease-in-out;
          -o-transition: all .4s ease-in-out;
          -webkit-transition: all .4s ease-in-out;
          transition: all .4s ease-in-out;
          margin-left: 40px;
          font-size: 20px;

        }

        &:hover {
          cursor: pointer;
        }

        //&:hover {
        //  padding: 2px 10px;
        //  border: 1px groove rgba(255, 255, 255, 0.3);
        //  background-color: rgb(173, 4, 85);
        //  cursor: pointer;
        //  box-shadow: 0 4px 15px 0 rgba(255, 255, 255, 0.9);
        //  background-position: 100% 0;
        //  moz-transition: all .4s ease-in-out;
        //  -o-transition: all .4s ease-in-out;
        //  -webkit-transition: all .4s ease-in-out;
        //  transition: all .4s ease-in-out;
        //
        //  span {
        //    moz-transition: all .4s ease-in-out;
        //    -o-transition: all .4s ease-in-out;
        //    -webkit-transition: all .4s ease-in-out;
        //    transition: all .4s ease-in-out;
        //    margin-left: 5px;
        //  }
        //}

      }

    }
  }
}
