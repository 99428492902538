.rrhh-page {

  span.ubicacion {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    a{
      font-family: Flama;
      font-size: 11px;
      color: #868891;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
      &:hover{
        text-decoration: none;
      }
    }
    padding-top: 30px;
    display: block;
  }


  .hero-top {

    .hero-top-image {
      position: relative;
      background-position-y: center;

      .opacidad-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.6);
        text-align: right;
        @media screen and (max-width: 767px) {
          width: 100%;
        }

        div.hero-top-info {
          //padding: 100px 13% 0 28%;
          display: inline-block;
          max-width: 685px;
          text-align: left;

          @media screen and (max-width: 1280px) {
            padding: 65px 10% 0 4%;
          }



          span.titulo {

            font-family: Flama;
            margin: 50px 0;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 62px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0;
            }
            @media screen and (max-width: 768px) {
              font-size: 45px;
            }
          }

          span.sub-titulo {
            font-weight: 500;
            line-height: 28px;
            color: #343741;
            font-size: 15px;
          }

          .indicador {
            margin: 30px 0;
            width: 18px;
            height: 18px;
            background-color: #9E0349;

          }

        }

      }

    }

  }

  .contenido {

    .entrada {

      .single {
        margin: 70px 0 100px;
        display: flex;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .entrada-info {
          width: 50%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          .indicador {
            width: 18px;
            height: 18px;
            background-color: #9E0349;

          }

          .titulo {
            display: block;
            margin-top: 70px;
            margin-bottom: 50px;
            font-family: Flama;
            font-size: 48px;
            font-weight: 500;
            line-height: 58px;
            color: #343741;
          }

          .sub-titulo {
            font-weight: 500;
            font-size: 15px;
            color: #343741;
            line-height: 28px;

          }
        }

        .entrada-image {
          width: 50%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          span {
            display: block;
            margin-inline-start: auto;
            margin-right: 10px;
            font-weight: 600;
            font-size: 15px;
            margin-bottom: 30px;

          }

          img {
            max-width: 100%;
            height: auto;
          }

        }


        .image-izquierda {
          padding-right: 60px;
          @media screen and (max-width: 768px) {
            padding: 0;
          }
        }

        .image-derecha {
          padding-left: 60px;
          @media screen and (max-width: 768px) {
            padding: 0;
          }
        }

      }

      .left-image-block {
        @media screen and (max-width: 768px) {
          flex-direction: column-reverse;
        }


      }

    }

  }

  .hero-bottom {
    background-color: #F7F9FB;
    display: flex;
    margin-top: 100px;

    .hero-bottom-info {
      width: 50%;
      padding: 100px 13% 0 14%;

      @media screen and (max-width: 1280px) {
        padding: 65px 10% 0 2%;
      }

      .indicador {
        width: 18px;
        height: 18px;
        background-color: #9E0349;

      }

      span.titulo {
        font-family: Flama;
        margin: 70px 0;

        @media screen and (max-width: 1280px) {
          margin: 35px 0;
        }
        display: block;
        font-size: 48px;
        color: #343741;
      }

      span.sub-titulo {
        line-height: 28px;
        font-size: 15px;
        color: #343741;
        letter-spacing: 1px;
        display: block;
        font-weight: 500;
        margin-bottom: 30px;
      }

      span.info {
        color: #979EA6;
        line-height: 24px;
        font-size: 12px;
      }

    }

    .image-hero {
      width: 50%;
      height: 700px;
    }
  }

  .boton-cv {
    margin-bottom: 230px;
    margin-right: 35%;
    max-width: 500px;
    .center {

      float: right;

      .btn,
      button {
        background: #720023;
        color: white;
        border: none;
        font-size: 10px;
        font-size: 1rem;
        background-color: #720023;
        color: white;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        padding: 14px 21px;
        border: 2px solid #720023;
        transition: color 0.1s cubic-bezier(0.16, 0.08, 0.355, 1), background 0.1s cubic-bezier(0.16, 0.08, 0.355, 1);
        display: inline-block;
        cursor: pointer;
        width: 100%;
        min-width: 200px;
        min-width: 12rem;
        outline: none;
        vertical-align: middle;
        text-align: center;
        position: relative;
        overflow: hidden;
        @media (min-width: 400px) {
          width: auto;
        }
        @media (min-width: 800px) {
          font-size: 1.1rem;
          padding: .8rem 2.8rem;
        }

        &:hover {
          background: lighten(#720023, 5%);
          border-color: lighten(#720023, 5%);
          color: #fff;
        }
      }

      .btn-border {
        background-color: transparent;
        color: #720023;
      }

      .btn-arrow {
        position: relative;
        transition: background-color 300ms ease-out;

        span {
          display: inline-block;
          position: relative;
          transition: all 300ms ease-out;
          will-change: transform;
        }

        &:hover span {
          transform: translate3d(-1rem, 0, 0);
        }

        svg {
          position: absolute;
          width: 1.1em;
          right: 0px;
          right: 0rem;
          opacity: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: all 300ms ease-out;
          will-change: right, opacity;

          * {
            stroke-width: 5;
            stroke-color: transparent;
          }
        }

        &:hover svg {
          opacity: 1;
          right: -2rem;
        }
      }
    }

  }

}
