/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled, .comment-list {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid, .wp-caption > img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure, .wp-caption {
  display: inline-block; }

.figure-img, .wp-caption > img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption, .wp-caption-text {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea, .search-form .search-field {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .comment-form input[type="text"],
    .comment-form input[type="email"],
    .comment-form input[type="url"],
    .comment-form textarea, .search-form .search-field {
      transition: none; } }
  .form-control::-ms-expand, .comment-form input[type="text"]::-ms-expand,
  .comment-form input[type="email"]::-ms-expand,
  .comment-form input[type="url"]::-ms-expand,
  .comment-form textarea::-ms-expand, .search-form .search-field::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring, .comment-form input[type="text"]:-moz-focusring,
  .comment-form input[type="email"]:-moz-focusring,
  .comment-form input[type="url"]:-moz-focusring,
  .comment-form textarea:-moz-focusring, .search-form .search-field:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus, .comment-form input[type="text"]:focus,
  .comment-form input[type="email"]:focus,
  .comment-form input[type="url"]:focus,
  .comment-form textarea:focus, .search-form .search-field:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder, .comment-form input[type="text"]::placeholder,
  .comment-form input[type="email"]::placeholder,
  .comment-form input[type="url"]::placeholder,
  .comment-form textarea::placeholder, .search-form .search-field::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .comment-form input[type="text"]:disabled,
  .comment-form input[type="email"]:disabled,
  .comment-form input[type="url"]:disabled,
  .comment-form textarea:disabled, .search-form .search-field:disabled, .form-control[readonly], .comment-form input[readonly][type="text"],
  .comment-form input[readonly][type="email"],
  .comment-form input[readonly][type="url"],
  .comment-form textarea[readonly], .search-form [readonly].search-field {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value, .search-form select.search-field:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], .search-form select[size].search-field, select.form-control[multiple], .search-form select[multiple].search-field {
  height: auto; }

textarea.form-control,
.comment-form textarea, .search-form textarea.search-field {
  height: auto; }

.form-group, .comment-form p, .search-form label {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.form-control .was-validated :valid, .comment-form input[type="text"] .was-validated :valid,
.comment-form input[type="email"] .was-validated :valid,
.comment-form input[type="url"] .was-validated :valid,
.comment-form textarea .was-validated :valid, .search-form .search-field .was-validated :valid,
.form-control .is-valid, .comment-form input[type="text"] .is-valid,
.comment-form input[type="email"] .is-valid,
.comment-form input[type="url"] .is-valid,
.comment-form textarea .is-valid, .search-form .search-field .is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .form-control .was-validated :valid:focus, .comment-form input[type="text"] .was-validated :valid:focus,
  .comment-form input[type="email"] .was-validated :valid:focus,
  .comment-form input[type="url"] .was-validated :valid:focus,
  .comment-form textarea .was-validated :valid:focus, .search-form .search-field .was-validated :valid:focus,
  .form-control .is-valid:focus, .comment-form input[type="text"] .is-valid:focus,
  .comment-form input[type="email"] .is-valid:focus,
  .comment-form input[type="url"] .is-valid:focus,
  .comment-form textarea .is-valid:focus, .search-form .search-field .is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

textarea.form-control .was-validated :valid,
.comment-form textarea .was-validated :valid, .search-form textarea.search-field .was-validated :valid,
textarea.form-control .is-valid,
.comment-form textarea .is-valid, .search-form textarea.search-field .is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.custom-select .was-validated :valid,
.custom-select .is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .custom-select .was-validated :valid:focus,
  .custom-select .is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.form-check-input .was-validated :valid ~ .form-check-label,
.form-check-input .is-valid ~ .form-check-label {
  color: #28a745; }

.form-check-input .was-validated :valid ~ .valid-feedback,
.form-check-input .was-validated :valid ~ .valid-tooltip,
.form-check-input .is-valid ~ .valid-feedback,
.form-check-input .is-valid ~ .valid-tooltip {
  display: block; }

.custom-control-input .was-validated :valid ~ .custom-control-label,
.custom-control-input .is-valid ~ .custom-control-label {
  color: #28a745; }
  .custom-control-input .was-validated :valid ~ .custom-control-label::before,
  .custom-control-input .is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.custom-control-input .was-validated :valid:checked ~ .custom-control-label::before,
.custom-control-input .is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.custom-control-input .was-validated :valid:focus ~ .custom-control-label::before,
.custom-control-input .is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-control-input .was-validated :valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input .is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.custom-file-input .was-validated :valid ~ .custom-file-label,
.custom-file-input .is-valid ~ .custom-file-label {
  border-color: #28a745; }

.custom-file-input .was-validated :valid:focus ~ .custom-file-label,
.custom-file-input .is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.form-control .was-validated :invalid, .comment-form input[type="text"] .was-validated :invalid,
.comment-form input[type="email"] .was-validated :invalid,
.comment-form input[type="url"] .was-validated :invalid,
.comment-form textarea .was-validated :invalid, .search-form .search-field .was-validated :invalid,
.form-control .is-invalid, .comment-form input[type="text"] .is-invalid,
.comment-form input[type="email"] .is-invalid,
.comment-form input[type="url"] .is-invalid,
.comment-form textarea .is-invalid, .search-form .search-field .is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .form-control .was-validated :invalid:focus, .comment-form input[type="text"] .was-validated :invalid:focus,
  .comment-form input[type="email"] .was-validated :invalid:focus,
  .comment-form input[type="url"] .was-validated :invalid:focus,
  .comment-form textarea .was-validated :invalid:focus, .search-form .search-field .was-validated :invalid:focus,
  .form-control .is-invalid:focus, .comment-form input[type="text"] .is-invalid:focus,
  .comment-form input[type="email"] .is-invalid:focus,
  .comment-form input[type="url"] .is-invalid:focus,
  .comment-form textarea .is-invalid:focus, .search-form .search-field .is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

textarea.form-control .was-validated :invalid,
.comment-form textarea .was-validated :invalid, .search-form textarea.search-field .was-validated :invalid,
textarea.form-control .is-invalid,
.comment-form textarea .is-invalid, .search-form textarea.search-field .is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.custom-select .was-validated :invalid,
.custom-select .is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .custom-select .was-validated :invalid:focus,
  .custom-select .is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-check-input .was-validated :invalid ~ .form-check-label,
.form-check-input .is-invalid ~ .form-check-label {
  color: #dc3545; }

.form-check-input .was-validated :invalid ~ .invalid-feedback,
.form-check-input .was-validated :invalid ~ .invalid-tooltip,
.form-check-input .is-invalid ~ .invalid-feedback,
.form-check-input .is-invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input .was-validated :invalid ~ .custom-control-label,
.custom-control-input .is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .custom-control-input .was-validated :invalid ~ .custom-control-label::before,
  .custom-control-input .is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.custom-control-input .was-validated :invalid:checked ~ .custom-control-label::before,
.custom-control-input .is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.custom-control-input .was-validated :invalid:focus ~ .custom-control-label::before,
.custom-control-input .is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-control-input .was-validated :invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input .is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.custom-file-input .was-validated :invalid ~ .custom-file-label,
.custom-file-input .is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.custom-file-input .was-validated :invalid:focus ~ .custom-file-label,
.custom-file-input .is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline, .search-form {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check, .search-form .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label, .search-form label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group, .search-form .form-group, .form-inline .comment-form p, .comment-form .form-inline p, .search-form .comment-form p, .comment-form .search-form p, .form-inline .search-form label, .search-form .form-inline label, .search-form label {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .search-form .form-control, .form-inline .comment-form input[type="text"], .comment-form .form-inline input[type="text"], .search-form .comment-form input[type="text"], .comment-form .search-form input[type="text"], .form-inline
    .comment-form input[type="email"],
    .comment-form .form-inline input[type="email"], .search-form
    .comment-form input[type="email"],
    .comment-form .search-form input[type="email"], .form-inline
    .comment-form input[type="url"],
    .comment-form .form-inline input[type="url"], .search-form
    .comment-form input[type="url"],
    .comment-form .search-form input[type="url"], .form-inline
    .comment-form textarea,
    .comment-form .form-inline textarea, .search-form
    .comment-form textarea,
    .comment-form .search-form textarea, .search-form .search-field {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext, .search-form .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group, .search-form .input-group,
    .form-inline .custom-select, .search-form .custom-select {
      width: auto; }
    .form-inline .form-check, .search-form .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input, .search-form .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control, .search-form .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label, .search-form .custom-control-label {
      margin-bottom: 0; } }

.btn, .comment-form input[type="submit"], .search-form .search-submit {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .comment-form input[type="submit"], .search-form .search-submit {
      transition: none; } }
  .btn:hover, .comment-form input[type="submit"]:hover, .search-form .search-submit:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .comment-form input[type="submit"]:focus, .search-form .search-submit:focus, .btn.focus, .comment-form input.focus[type="submit"], .search-form .focus.search-submit {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .comment-form input.disabled[type="submit"], .search-form .disabled.search-submit, .btn:disabled, .comment-form input[type="submit"]:disabled, .search-form .search-submit:disabled {
    opacity: 0.65; }

a.btn.disabled, .search-form a.disabled.search-submit,
fieldset:disabled a.btn,
fieldset:disabled .search-form a.search-submit, .search-form
fieldset:disabled a.search-submit {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary, .comment-form input[type="submit"], .search-form .search-submit {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover, .comment-form input[type="submit"]:hover, .search-form .search-submit:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .comment-form input[type="submit"]:focus, .search-form .search-submit:focus, .btn-secondary.focus, .comment-form input.focus[type="submit"], .search-form .focus.search-submit {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .comment-form input.disabled[type="submit"], .search-form .disabled.search-submit, .btn-secondary:disabled, .comment-form input[type="submit"]:disabled, .search-form .search-submit:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .comment-form input[type="submit"]:not(:disabled):not(.disabled):active, .search-form .search-submit:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .comment-form input[type="submit"]:not(:disabled):not(.disabled).active, .search-form .search-submit:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle, .comment-form
  .show > input.dropdown-toggle[type="submit"], .search-form
  .show > .dropdown-toggle.search-submit {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .comment-form input[type="submit"]:not(:disabled):not(.disabled):active:focus, .search-form .search-submit:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .comment-form input[type="submit"]:not(:disabled):not(.disabled).active:focus, .search-form .search-submit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus, .comment-form
    .show > input.dropdown-toggle[type="submit"]:focus, .search-form
    .show > .dropdown-toggle.search-submit:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .comment-form .btn-group-lg > input[type="submit"], .search-form .btn-group-lg > .search-submit {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .comment-form .btn-group-sm > input[type="submit"], .search-form .btn-group-sm > .search-submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .comment-form .btn-group > input[type="submit"], .search-form .btn-group > .search-submit,
  .btn-group-vertical > .btn, .comment-form
  .btn-group-vertical > input[type="submit"], .search-form
  .btn-group-vertical > .search-submit {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .comment-form .btn-group > input[type="submit"]:hover, .search-form .btn-group > .search-submit:hover,
    .btn-group-vertical > .btn:hover, .comment-form
    .btn-group-vertical > input[type="submit"]:hover, .search-form
    .btn-group-vertical > .search-submit:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .comment-form .btn-group > input[type="submit"]:focus, .search-form .btn-group > .search-submit:focus, .btn-group > .btn:active, .comment-form .btn-group > input[type="submit"]:active, .search-form .btn-group > .search-submit:active, .btn-group > .btn.active, .comment-form .btn-group > input.active[type="submit"], .search-form .btn-group > .active.search-submit,
    .btn-group-vertical > .btn:focus, .comment-form
    .btn-group-vertical > input[type="submit"]:focus, .search-form
    .btn-group-vertical > .search-submit:focus,
    .btn-group-vertical > .btn:active, .comment-form
    .btn-group-vertical > input[type="submit"]:active, .search-form
    .btn-group-vertical > .search-submit:active,
    .btn-group-vertical > .btn.active, .comment-form
    .btn-group-vertical > input.active[type="submit"], .search-form
    .btn-group-vertical > .active.search-submit {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .comment-form .btn-group > input[type="submit"]:not(:first-child), .search-form .btn-group > .search-submit:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .comment-form .btn-group > input[type="submit"]:not(:last-child):not(.dropdown-toggle), .search-form .btn-group > .search-submit:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn, .comment-form
.btn-group > .btn-group:not(:last-child) > input[type="submit"], .search-form
.btn-group > .btn-group:not(:last-child) > .search-submit {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .comment-form .btn-group > input[type="submit"]:not(:first-child), .search-form .btn-group > .search-submit:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn, .comment-form
.btn-group > .btn-group:not(:first-child) > input[type="submit"], .search-form
.btn-group > .btn-group:not(:first-child) > .search-submit {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .comment-form .btn-group-sm > input[type="submit"] + .dropdown-toggle-split, .search-form .btn-group-sm > .search-submit + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .comment-form .btn-group-lg > input[type="submit"] + .dropdown-toggle-split, .search-form .btn-group-lg > .search-submit + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn, .comment-form .btn-group-vertical > input[type="submit"], .search-form .btn-group-vertical > .search-submit,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .comment-form .btn-group-vertical > input[type="submit"]:not(:first-child), .search-form .btn-group-vertical > .search-submit:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .comment-form .btn-group-vertical > input[type="submit"]:not(:last-child):not(.dropdown-toggle), .search-form .btn-group-vertical > .search-submit:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn, .comment-form
  .btn-group-vertical > .btn-group:not(:last-child) > input[type="submit"], .search-form
  .btn-group-vertical > .btn-group:not(:last-child) > .search-submit {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .comment-form .btn-group-vertical > input[type="submit"]:not(:first-child), .search-form .btn-group-vertical > .search-submit:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn, .comment-form
  .btn-group-vertical > .btn-group:not(:first-child) > input[type="submit"], .search-form
  .btn-group-vertical > .btn-group:not(:first-child) > .search-submit {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .comment-form .btn-group-toggle > input[type="submit"], .search-form .btn-group-toggle > .search-submit,
.btn-group-toggle > .btn-group > .btn, .comment-form
.btn-group-toggle > .btn-group > input[type="submit"], .search-form
.btn-group-toggle > .btn-group > .search-submit {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .comment-form .btn-group-toggle > input[type="submit"] input[type="radio"], .search-form .btn-group-toggle > .search-submit input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"], .comment-form
  .btn-group-toggle > input[type="submit"] input[type="checkbox"], .search-form
  .btn-group-toggle > .search-submit input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"], .comment-form
  .btn-group-toggle > .btn-group > input[type="submit"] input[type="radio"], .search-form
  .btn-group-toggle > .btn-group > .search-submit input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .comment-form
  .btn-group-toggle > .btn-group > input[type="submit"] input[type="checkbox"], .search-form
  .btn-group-toggle > .btn-group > .search-submit input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .comment-form .input-group > input[type="text"],
  .comment-form .input-group > input[type="email"],
  .comment-form .input-group > input[type="url"],
  .comment-form .input-group > textarea, .search-form .input-group > .search-field,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .comment-form .input-group > input[type="text"] + .form-control,
    .comment-form .input-group > input[type="email"] + .form-control,
    .comment-form .input-group > input[type="url"] + .form-control,
    .comment-form .input-group > textarea + .form-control, .search-form .input-group > .search-field + .form-control, .comment-form .input-group > .form-control + input[type="text"], .comment-form .input-group > input[type="text"] + input[type="text"], .comment-form .input-group > input[type="email"] + input[type="text"], .comment-form .input-group > input[type="url"] + input[type="text"], .comment-form .input-group > textarea + input[type="text"], .search-form .comment-form .input-group > .search-field + input[type="text"], .comment-form .search-form .input-group > .search-field + input[type="text"],
    .comment-form .input-group > .form-control + input[type="email"], .comment-form .input-group > input[type="text"] + input[type="email"], .comment-form .input-group > input[type="email"] + input[type="email"], .comment-form .input-group > input[type="url"] + input[type="email"], .comment-form .input-group > textarea + input[type="email"], .search-form
    .comment-form .input-group > .search-field + input[type="email"],
    .comment-form .search-form .input-group > .search-field + input[type="email"],
    .comment-form .input-group > .form-control + input[type="url"], .comment-form .input-group > input[type="text"] + input[type="url"], .comment-form .input-group > input[type="email"] + input[type="url"], .comment-form .input-group > input[type="url"] + input[type="url"], .comment-form .input-group > textarea + input[type="url"], .search-form
    .comment-form .input-group > .search-field + input[type="url"],
    .comment-form .search-form .input-group > .search-field + input[type="url"],
    .comment-form .input-group > .form-control + textarea, .comment-form .input-group > input[type="text"] + textarea, .comment-form .input-group > input[type="email"] + textarea, .comment-form .input-group > input[type="url"] + textarea, .comment-form .input-group > textarea + textarea, .search-form
    .comment-form .input-group > .search-field + textarea,
    .comment-form .search-form .input-group > .search-field + textarea, .search-form .input-group > .form-control + .search-field, .comment-form .search-form .input-group > input[type="text"] + .search-field, .search-form .comment-form .input-group > input[type="text"] + .search-field,
    .comment-form .search-form .input-group > input[type="email"] + .search-field, .search-form
    .comment-form .input-group > input[type="email"] + .search-field,
    .comment-form .search-form .input-group > input[type="url"] + .search-field, .search-form
    .comment-form .input-group > input[type="url"] + .search-field,
    .comment-form .search-form .input-group > textarea + .search-field, .search-form
    .comment-form .input-group > textarea + .search-field, .search-form .input-group > .search-field + .search-field,
    .input-group > .form-control + .custom-select, .comment-form
    .input-group > input[type="text"] + .custom-select,
    .comment-form
    .input-group > input[type="email"] + .custom-select,
    .comment-form
    .input-group > input[type="url"] + .custom-select,
    .comment-form
    .input-group > textarea + .custom-select, .search-form
    .input-group > .search-field + .custom-select,
    .input-group > .form-control + .custom-file, .comment-form
    .input-group > input[type="text"] + .custom-file,
    .comment-form
    .input-group > input[type="email"] + .custom-file,
    .comment-form
    .input-group > input[type="url"] + .custom-file,
    .comment-form
    .input-group > textarea + .custom-file, .search-form
    .input-group > .search-field + .custom-file,
    .input-group > .form-control-plaintext + .form-control, .comment-form
    .input-group > .form-control-plaintext + input[type="text"],
    .comment-form
    .input-group > .form-control-plaintext + input[type="email"],
    .comment-form
    .input-group > .form-control-plaintext + input[type="url"],
    .comment-form
    .input-group > .form-control-plaintext + textarea, .search-form
    .input-group > .form-control-plaintext + .search-field,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control, .comment-form
    .input-group > .custom-select + input[type="text"],
    .comment-form
    .input-group > .custom-select + input[type="email"],
    .comment-form
    .input-group > .custom-select + input[type="url"],
    .comment-form
    .input-group > .custom-select + textarea, .search-form
    .input-group > .custom-select + .search-field,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control, .comment-form
    .input-group > .custom-file + input[type="text"],
    .comment-form
    .input-group > .custom-file + input[type="email"],
    .comment-form
    .input-group > .custom-file + input[type="url"],
    .comment-form
    .input-group > .custom-file + textarea, .search-form
    .input-group > .custom-file + .search-field,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .comment-form .input-group > input[type="text"]:focus,
  .comment-form .input-group > input[type="email"]:focus,
  .comment-form .input-group > input[type="url"]:focus,
  .comment-form .input-group > textarea:focus, .search-form .input-group > .search-field:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .comment-form .input-group > input[type="text"]:not(:last-child),
  .comment-form .input-group > input[type="email"]:not(:last-child),
  .comment-form .input-group > input[type="url"]:not(:last-child),
  .comment-form .input-group > textarea:not(:last-child), .search-form .input-group > .search-field:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .comment-form .input-group > input[type="text"]:not(:first-child),
  .comment-form .input-group > input[type="email"]:not(:first-child),
  .comment-form .input-group > input[type="url"]:not(:first-child),
  .comment-form .input-group > textarea:not(:first-child), .search-form .input-group > .search-field:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .comment-form input[type="submit"], .comment-form .input-group-prepend input[type="submit"], .input-group-prepend .search-form .search-submit, .search-form .input-group-prepend .search-submit,
  .input-group-append .btn,
  .input-group-append .comment-form input[type="submit"], .comment-form
  .input-group-append input[type="submit"],
  .input-group-append .search-form .search-submit, .search-form
  .input-group-append .search-submit {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .comment-form input[type="submit"]:focus, .comment-form .input-group-prepend input[type="submit"]:focus, .input-group-prepend .search-form .search-submit:focus, .search-form .input-group-prepend .search-submit:focus,
    .input-group-append .btn:focus,
    .input-group-append .comment-form input[type="submit"]:focus, .comment-form
    .input-group-append input[type="submit"]:focus,
    .input-group-append .search-form .search-submit:focus, .search-form
    .input-group-append .search-submit:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .comment-form input[type="submit"] + .btn, .comment-form .input-group-prepend input[type="submit"] + .btn, .input-group-prepend .search-form .search-submit + .btn, .search-form .input-group-prepend .search-submit + .btn, .input-group-prepend .comment-form .btn + input[type="submit"], .comment-form .input-group-prepend .btn + input[type="submit"], .input-group-prepend .comment-form input[type="submit"] + input[type="submit"], .comment-form .input-group-prepend input[type="submit"] + input[type="submit"], .input-group-prepend .search-form .comment-form .search-submit + input[type="submit"], .comment-form .input-group-prepend .search-form .search-submit + input[type="submit"], .search-form .input-group-prepend .comment-form .search-submit + input[type="submit"], .comment-form .search-form .input-group-prepend .search-submit + input[type="submit"], .input-group-prepend .search-form .btn + .search-submit, .search-form .input-group-prepend .btn + .search-submit, .input-group-prepend .comment-form .search-form input[type="submit"] + .search-submit, .search-form .input-group-prepend .comment-form input[type="submit"] + .search-submit, .comment-form .input-group-prepend .search-form input[type="submit"] + .search-submit, .search-form .comment-form .input-group-prepend input[type="submit"] + .search-submit, .input-group-prepend .search-form .search-submit + .search-submit, .search-form .input-group-prepend .search-submit + .search-submit,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .comment-form input[type="submit"] + .input-group-text, .comment-form
  .input-group-prepend input[type="submit"] + .input-group-text,
  .input-group-prepend .search-form .search-submit + .input-group-text, .search-form
  .input-group-prepend .search-submit + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .comment-form .input-group-text + input[type="submit"], .comment-form
  .input-group-prepend .input-group-text + input[type="submit"],
  .input-group-prepend .search-form .input-group-text + .search-submit, .search-form
  .input-group-prepend .input-group-text + .search-submit,
  .input-group-append .btn + .btn,
  .input-group-append .comment-form input[type="submit"] + .btn, .comment-form
  .input-group-append input[type="submit"] + .btn,
  .input-group-append .search-form .search-submit + .btn, .search-form
  .input-group-append .search-submit + .btn,
  .input-group-append .comment-form .btn + input[type="submit"], .comment-form
  .input-group-append .btn + input[type="submit"],
  .input-group-append .comment-form input[type="submit"] + input[type="submit"], .comment-form
  .input-group-append input[type="submit"] + input[type="submit"],
  .input-group-append .search-form .comment-form .search-submit + input[type="submit"], .comment-form
  .input-group-append .search-form .search-submit + input[type="submit"], .search-form
  .input-group-append .comment-form .search-submit + input[type="submit"], .comment-form .search-form
  .input-group-append .search-submit + input[type="submit"],
  .input-group-append .search-form .btn + .search-submit, .search-form
  .input-group-append .btn + .search-submit,
  .input-group-append .comment-form .search-form input[type="submit"] + .search-submit, .search-form
  .input-group-append .comment-form input[type="submit"] + .search-submit, .comment-form
  .input-group-append .search-form input[type="submit"] + .search-submit, .search-form .comment-form
  .input-group-append input[type="submit"] + .search-submit,
  .input-group-append .search-form .search-submit + .search-submit, .search-form
  .input-group-append .search-submit + .search-submit,
  .input-group-append .btn + .input-group-text,
  .input-group-append .comment-form input[type="submit"] + .input-group-text, .comment-form
  .input-group-append input[type="submit"] + .input-group-text,
  .input-group-append .search-form .search-submit + .input-group-text, .search-form
  .input-group-append .search-submit + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .comment-form .input-group-text + input[type="submit"], .comment-form
  .input-group-append .input-group-text + input[type="submit"],
  .input-group-append .search-form .input-group-text + .search-submit, .search-form
  .input-group-append .input-group-text + .search-submit {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .comment-form .input-group-lg > input[type="text"]:not(textarea),
.comment-form .input-group-lg > input[type="email"]:not(textarea),
.comment-form .input-group-lg > input[type="url"]:not(textarea),
.comment-form .input-group-lg > textarea:not(textarea), .search-form .input-group-lg > .search-field:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .comment-form .input-group-lg > input[type="text"],
.comment-form .input-group-lg > input[type="email"],
.comment-form .input-group-lg > input[type="url"],
.comment-form .input-group-lg > textarea, .search-form .input-group-lg > .search-field,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn, .comment-form
.input-group-lg > .input-group-prepend > input[type="submit"], .search-form
.input-group-lg > .input-group-prepend > .search-submit,
.input-group-lg > .input-group-append > .btn, .comment-form
.input-group-lg > .input-group-append > input[type="submit"], .search-form
.input-group-lg > .input-group-append > .search-submit {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .comment-form .input-group-sm > input[type="text"]:not(textarea),
.comment-form .input-group-sm > input[type="email"]:not(textarea),
.comment-form .input-group-sm > input[type="url"]:not(textarea),
.comment-form .input-group-sm > textarea:not(textarea), .search-form .input-group-sm > .search-field:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control, .comment-form .input-group-sm > input[type="text"],
.comment-form .input-group-sm > input[type="email"],
.comment-form .input-group-sm > input[type="url"],
.comment-form .input-group-sm > textarea, .search-form .input-group-sm > .search-field,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn, .comment-form
.input-group-sm > .input-group-prepend > input[type="submit"], .search-form
.input-group-sm > .input-group-prepend > .search-submit,
.input-group-sm > .input-group-append > .btn, .comment-form
.input-group-sm > .input-group-append > input[type="submit"], .search-form
.input-group-sm > .input-group-append > .search-submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn, .comment-form .input-group > .input-group-prepend > input[type="submit"], .search-form .input-group > .input-group-prepend > .search-submit,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn, .comment-form
.input-group > .input-group-append:not(:last-child) > input[type="submit"], .search-form
.input-group > .input-group-append:not(:last-child) > .search-submit,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .comment-form
.input-group > .input-group-append:last-child > input[type="submit"]:not(:last-child):not(.dropdown-toggle), .search-form
.input-group > .input-group-append:last-child > .search-submit:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .comment-form .input-group > .input-group-append > input[type="submit"], .search-form .input-group > .input-group-append > .search-submit,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn, .comment-form
.input-group > .input-group-prepend:not(:first-child) > input[type="submit"], .search-form
.input-group > .input-group-prepend:not(:first-child) > .search-submit,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child), .comment-form
.input-group > .input-group-prepend:first-child > input[type="submit"]:not(:first-child), .search-form
.input-group > .input-group-prepend:first-child > .search-submit:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-md,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-md,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge, .comment-form input[type="submit"] .badge, .search-form .search-submit .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only, .screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .screen-reader-text:active, .sr-only-focusable:focus, .screen-reader-text:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn):not(.comment-form input[type="submit"]):not(.search-form .search-submit) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-SemiBoldItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Karla';
  src: url("../fonts/Karla-BoldItalic.woff2") format("woff2"), url("../fonts/Karla-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-Thin.woff2") format("woff2"), url("../fonts/IBMPlexSans-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-Light.woff2") format("woff2"), url("../fonts/IBMPlexSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Karla';
  src: url("../fonts/Karla-Regular.woff2") format("woff2"), url("../fonts/Karla-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-BoldItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-SemiBold.woff2") format("woff2"), url("../fonts/IBMPlexSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-Bold.woff2") format("woff2"), url("../fonts/IBMPlexSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans.woff2") format("woff2"), url("../fonts/IBMPlexSans.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Karla';
  src: url("../fonts/Karla-Italic.woff2") format("woff2"), url("../fonts/Karla-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-ThinItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-Italic.woff2") format("woff2"), url("../fonts/IBMPlexSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Karla';
  src: url("../fonts/Karla-Bold.woff2") format("woff2"), url("../fonts/Karla-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-SemiBold.woff2") format("woff2"), url("../fonts/IBMPlexSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans.woff2") format("woff2"), url("../fonts/IBMPlexSans.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-ExtraLightItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-Medium.woff2") format("woff2"), url("../fonts/IBMPlexSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-Bold.woff2") format("woff2"), url("../fonts/IBMPlexSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-LightItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-MediumItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'IBM Plex Sans';
  src: url("../fonts/IBMPlexSans-ExtraLight.woff2") format("woff2"), url("../fonts/IBMPlexSans-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-BlackItalic.woff2") format("woff2"), url("../fonts/Flama-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-Ultralight.woff2") format("woff2"), url("../fonts/Flama-Ultralight.woff") format("woff");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-Thin.woff2") format("woff2"), url("../fonts/Flama-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-Black.woff2") format("woff2"), url("../fonts/Flama-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-Extrabold.woff2") format("woff2"), url("../fonts/Flama-Extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Flama Basic';
  src: url("../fonts/Flama-BasicItalic.woff2") format("woff2"), url("../fonts/Flama-BasicItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-ExtraboldItalic.woff2") format("woff2"), url("../fonts/Flama-ExtraboldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-ThinItalic.woff2") format("woff2"), url("../fonts/Flama-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-Light.woff2") format("woff2"), url("../fonts/Flama-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama.woff2") format("woff2"), url("../fonts/Flama.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama.woff2") format("woff2"), url("../fonts/Flama.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-Bold.woff2") format("woff2"), url("../fonts/Flama-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-Semibold.woff2") format("woff2"), url("../fonts/Flama-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-UltralightItalic.woff2") format("woff2"), url("../fonts/Flama-UltralightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Flama Book';
  src: url("../fonts/Flama-BookItalic.woff2") format("woff2"), url("../fonts/Flama-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-Bold.woff2") format("woff2"), url("../fonts/Flama-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Flama Book';
  src: url("../fonts/Flama-Book.woff2") format("woff2"), url("../fonts/Flama-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-MediumItalic.woff2") format("woff2"), url("../fonts/Flama-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-LightItalic.woff2") format("woff2"), url("../fonts/Flama-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-Medium.woff2") format("woff2"), url("../fonts/Flama-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-SemiboldItalic.woff2") format("woff2"), url("../fonts/Flama-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Flama Basic';
  src: url("../fonts/Flama-Basic.woff2") format("woff2"), url("../fonts/Flama-Basic.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-Semibold.woff2") format("woff2"), url("../fonts/Flama-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Flama';
  src: url("../fonts/Flama-BoldItalic.woff2") format("woff2"), url("../fonts/Flama-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic; }

.container {
  max-width: 1400px !important; }

span {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: normal; }

main.main {
  padding-right: 0 !important; }

.search-form {
  position: relative;
  z-index: 1; }
  .search-form .search-field {
    background: none !important; }
  .search-form input.search-submit {
    position: absolute;
    z-index: 987;
    right: 0;
    top: 0;
    opacity: 1;
    width: 30px;
    border-radius: 0;
    height: 38px;
    background-image: url(../../images/searchmobile.png);
    background-repeat: no-repeat;
    background-size: 18px 18px;
    padding: 0;
    text-indent: -99999px;
    display: block;
    background-position: 50% 50%;
    background-color: transparent !important;
    border: 0 none;
    outline: none;
    box-shadow: 0 0 0 0 transparent !important; }
    .search-form input.search-submit:hover {
      background-color: transparent !important;
      background-image: url(../../images/searchmobile_2.png);
      border: 0 none;
      outline: none;
      box-shadow: 0 0 0 0 transparent !important; }

/* galeria_img_list */
.galeria_img_list {
  background: transparent;
  overflow: hidden;
  width: 100%;
  display: block; }
  .galeria_img_list .file-list-wrap {
    margin-bottom: 25px; }
  .galeria_img_list .file-list-image {
    width: 100%;
    padding: 0;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    height: 300px; }
    .galeria_img_list .file-list-image img {
      width: 100%;
      height: auto; }

div.slider,
.general {
  visibility: hidden;
  opacity: 0; }

body.raffo-front {
  background: url("../../images/ajax-loader.gif") 50% 200px no-repeat; }

.a-resultados {
  color: #575756;
  font-weight: 700;
  font-size: 23px;
  background: url(../../images/arrowrounded.png) 0 16px no-repeat;
  padding: 10px 180px 10px 40px;
  margin-bottom: 10px;
  border-top: 1px solid #575756;
  display: block;
  width: 100%;
  text-decoration: none;
  position: relative;
  z-index: 1; }
  .a-resultados span {
    background: #9e0349;
    color: #fff;
    position: absolute;
    z-index: 9;
    top: 16px;
    right: 0;
    border-radius: 25px;
    padding: 1px 25px;
    font-size: 14px;
    line-height: 30px; }
  .a-resultados:hover {
    color: #9e0349 !important;
    text-decoration: none !important; }
    .a-resultados:hover span {
      background: #eee;
      color: #9e0349; }

.the_posts_navigation_box {
  padding: 50px auto; }
  .the_posts_navigation_box .nav-previous a {
    background-image: url(../../images/arrow-2.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 30px;
    text-decoration: none;
    display: block;
    width: 62px;
    overflow: hidden;
    text-indent: -99999px;
    float: right; }
  .the_posts_navigation_box .nav-next a {
    background-image: url(../../images/arrow-1.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 30px;
    text-decoration: none;
    display: block;
    width: 62px;
    overflow: hidden;
    text-indent: -99999px;
    float: left; }

@media screen and (max-width: 1280px) {
  .n2-ss-layer.n2-ow.text-top {
    max-width: 455px !important;
    overflow: visible !important;
    font-size: 100% !important;
    position: absolute !important;
    z-index: 987654 !important;
    top: 0 !important;
    left: 0 !important;
    padding: 95px 10% 0 2% !important;
    margin: 0 !important; }
  .n2-ss-slider .n2-ss-layer[data-has-maxwidth="1"][data-pm=normal] {
    margin: 0 !important; }
  div.slider .n2-section-smartslider div.n2-ss-control-bullet {
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    left: 0px !important;
    bottom: 2% !important; }
  #recuadroBlanco {
    left: 20px;
    margin-left: 2%;
    bottom: 7%; } }

body #recuadroBlanco {
  display: none !important; }

.nextend-bullet-bar.n2-ow.n2-bar-justify-content-center div.n2-ow {
  color: #000000 !important;
  margin: 0 !important;
  padding: 15px 18px !important;
  font-size: 18px;
  font-family: "IBM Plex Sans";
  cursor: pointer; }
  .nextend-bullet-bar.n2-ow.n2-bar-justify-content-center div.n2-ow span {
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    font-family: "IBM Plex Sans";
    cursor: pointer; }

.nextend-bullet-bar.n2-ow.n2-bar-justify-content-center div.n2-ow.n2-active span {
  color: #ffffff !important; }

.nextend-bullet-bar.n2-ow.n2-bar-justify-content-center div.n2-ow:hover {
  color: #ffffff !important; }

.comment-list ol {
  list-style: none; }

.search-form label {
  font-weight: normal; }

.main {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .main {
      flex: 0 0 100%;
      max-width: 100%; }
      .sidebar-primary .main {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%; } }

.sidebar {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .sidebar {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; } }

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto; }

.aligncenter {
  display: block;
  margin: 0.5rem auto;
  height: auto; }

.alignleft,
.alignright {
  margin-bottom: 0.5rem;
  height: auto; }

@media (min-width: 576px) {
  .alignleft {
    float: left;
    margin-right: 0.5rem; }
  .alignright {
    float: right;
    margin-left: 0.5rem; } }

header.banner {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  z-index: 999;
  top: 0;
  background-color: white;
  padding: 20px 0 10px 0;
  display: flex; }
  @media screen and (max-width: 600px) {
    header.banner {
      display: none !important; } }
  @media screen and (max-width: 480px) {
    header.banner {
      margin: 0;
      height: 88px; } }
  @media screen and (min-width: 1040px) {
    header.banner .desktop-nav div {
      padding: 0 !important; } }
  @media screen and (max-width: 768px) {
    header.banner .desktop-nav {
      flex-direction: column; } }
  header.banner .desktop-nav div:nth-child(1) {
    display: flex;
    align-items: center;
    width: 100%; }
    @media screen and (max-width: 1280px) {
      header.banner .desktop-nav div:nth-child(1) {
        width: 100%; } }
    @media screen and (max-width: 768px) {
      header.banner .desktop-nav div:nth-child(1) {
        flex-direction: column;
        width: 100%; } }
    header.banner .desktop-nav div:nth-child(1) nav {
      margin-left: 40px; }
      @media screen and (max-width: 1280px) {
        header.banner .desktop-nav div:nth-child(1) nav {
          margin-left: 15px; } }
      @media screen and (max-width: 768px) {
        header.banner .desktop-nav div:nth-child(1) nav {
          margin: 20px 0; } }
      header.banner .desktop-nav div:nth-child(1) nav ul li {
        margin: 0 30px; }
        @media screen and (max-width: 1280px) {
          header.banner .desktop-nav div:nth-child(1) nav ul li {
            margin: 0 15px; } }
        header.banner .desktop-nav div:nth-child(1) nav ul li a {
          padding-bottom: 5px;
          font-weight: 500;
          font-family: "Flama";
          color: #343741 !important; }
  header.banner .desktop-nav div:nth-child(2) {
    width: 400px; }
    @media screen and (max-width: 768px) {
      header.banner .desktop-nav div:nth-child(2) {
        width: 300px; } }
    header.banner .desktop-nav div:nth-child(2) label {
      width: 100%; }
      header.banner .desktop-nav div:nth-child(2) label input.search-field {
        width: 100%;
        background-color: transparent;
        transition: transform 250ms ease-in-out;
        font-size: 14px;
        line-height: 18px;
        background-image: url(../../images/searchmobile.png);
        background-repeat: no-repeat;
        background-size: 18px 18px;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        padding-right: 25px;
        outline: 0;
        border: 1px solid transparent;
        border-bottom: 1px solid #575756;
        border-radius: 0;
        box-shadow: none;
        background-position: 100% center; }
        @media screen and (max-width: 1280px) {
          header.banner .desktop-nav div:nth-child(2) label input.search-field {
            padding: 12px 18px; } }
        header.banner .desktop-nav div:nth-child(2) label input.search-field::-webkit-search-cancel-button {
          display: none; }
        header.banner .desktop-nav div:nth-child(2) label input.search-field::placeholder {
          letter-spacing: 1.5px;
          font-size: 12px; }
  @media screen and (min-width: 700px) {
    header.banner .desktop-nav #menu-header-menu .active a {
      text-decoration: none;
      border-bottom: 5px solid #9e0349; }
    header.banner .desktop-nav #menu-header-menu a:hover {
      text-decoration: none;
      border-bottom: 5px solid #9e0349; } }

header.header-mobile {
  position: sticky;
  z-index: 40;
  top: 0;
  background-color: white;
  padding: 20px 15px; }
  @media screen and (min-width: 500px) {
    header.header-mobile {
      display: none; } }
  header.header-mobile .mobile-nav {
    display: none; }
    @media screen and (max-width: 599px) {
      header.header-mobile .mobile-nav {
        display: block;
        position: relative;
        width: 103%; } }
    header.header-mobile .mobile-nav nav {
      display: flex; }
    header.header-mobile .mobile-nav a.brand img {
      width: 80px; }
    header.header-mobile .mobile-nav a {
      text-decoration: none;
      color: #232323;
      transition: color 0.3s ease; }
    header.header-mobile .mobile-nav a:hover {
      color: tomato; }
    header.header-mobile .mobile-nav #menuToggle {
      display: block;
      position: absolute;
      top: 15px;
      right: 0;
      z-index: 1;
      padding-right: 20px;
      -webkit-user-select: none;
      user-select: none; }
    header.header-mobile .mobile-nav #searchToggle {
      display: block;
      position: absolute;
      top: 15px;
      right: 0;
      z-index: 1;
      padding-right: 20px;
      -webkit-user-select: none;
      user-select: none; }
    header.header-mobile .mobile-nav #menuToggle input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
      -webkit-touch-callout: none; }
    header.header-mobile .mobile-nav #searchToggle input.reacciona {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
      -webkit-touch-callout: none; }
    header.header-mobile .mobile-nav #menuToggle span {
      display: block;
      width: 25px;
      height: 1px;
      margin-bottom: 5px;
      position: relative;
      background: black;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease; }
    header.header-mobile .mobile-nav #searchToggle .img-search {
      display: block;
      margin-bottom: 5px;
      position: relative;
      z-index: 1; }
    header.header-mobile .mobile-nav #menuToggle span:first-child {
      transform-origin: 0% 0%; }
    header.header-mobile .mobile-nav #menuToggle span:nth-last-child(2) {
      transform-origin: 0% 100%; }
    header.header-mobile .mobile-nav #menuToggle input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323; }
    header.header-mobile .mobile-nav #menuToggle input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2); }
    header.header-mobile .mobile-nav #menuToggle input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, -1px); }
    header.header-mobile .mobile-nav #menu-header-menu-1 {
      flex-direction: column;
      margin-top: 15vh;
      text-align: right; }
    header.header-mobile .mobile-nav #menu {
      display: none;
      position: absolute;
      width: 430px;
      margin: 30px 0 0 -380px;
      padding: 20px;
      right: -5px;
      padding-top: 10px;
      height: 100vh;
      background: #860134;
      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      transform-origin: 0% 0%;
      transform: translate(100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1); }
    header.header-mobile .mobile-nav #menu li {
      padding: 10px 0;
      font-size: 22px; }
      header.header-mobile .mobile-nav #menu li a {
        color: white;
        font-family: Flama;
        font-size: 30px;
        text-transform: uppercase; }
    header.header-mobile .mobile-nav #menuSearch {
      display: none;
      position: absolute;
      width: 430px;
      margin: 30px 0 0 -380px;
      padding: 20px;
      padding-top: 10px;
      height: 100vh;
      background: #858D95;
      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      transform-origin: 0% 0%;
      transform: translate(100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1); }
      header.header-mobile .mobile-nav #menuSearch .search-submit {
        opacity: 0 !important; }
      header.header-mobile .mobile-nav #menuSearch form {
        align-items: center;
        justify-content: center;
        height: 60vh; }
        header.header-mobile .mobile-nav #menuSearch form label {
          border-bottom: 1.5px solid #cacacaab;
          width: 85%; }
          header.header-mobile .mobile-nav #menuSearch form label input {
            text-align: center;
            background-color: #858D95 !important;
            border: none;
            font-family: "IBM Plex Sans";
            font-size: 24px;
            width: 100%;
            margin-bottom: 10px; }
            header.header-mobile .mobile-nav #menuSearch form label input::placeholder {
              color: white; }
            header.header-mobile .mobile-nav #menuSearch form label input:focus {
              border: none; }
      header.header-mobile .mobile-nav #menuSearch span {
        font-size: 24px;
        color: #cacacaab;
        font-family: "IBM Plex Sans";
        position: absolute;
        top: 40%;
        left: 29%; }
    header.header-mobile .mobile-nav #menuToggle input:checked ~ ul {
      display: block;
      transform: none; }
    header.header-mobile .mobile-nav #searchToggle input.reacciona:checked ~ div {
      display: block;
      transform: none; }
    header.header-mobile .mobile-nav div.img-search {
      background-image: url("../../images/searchmobile.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px; }
    header.header-mobile .mobile-nav .divider {
      width: 2px;
      height: 45px;
      left: 88%;
      position: absolute;
      background-color: #ededed; }

div.footer {
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px; }
  @media screen and (max-width: 480px) {
    div.footer {
      display: block; } }
  div.footer .left-footer {
    width: 30%; }
    div.footer .left-footer .images-left-footer {
      display: flex;
      align-self: baseline;
      align-items: end; }
    @media screen and (max-width: 1280px) {
      div.footer .left-footer {
        width: 25%; } }
    @media screen and (max-width: 480px) {
      div.footer .left-footer {
        width: 100%; } }
    div.footer .left-footer img {
      display: block;
      margin-bottom: 45px; }
    div.footer .left-footer div.textwidget p {
      font-size: 15px; }
    div.footer .left-footer span {
      font-family: 'IBM Plex Sans', sans-serif;
      color: #343741;
      font-size: 12px;
      line-height: 30px; }
      @media screen and (max-width: 480px) {
        div.footer .left-footer span {
          display: none; } }
  div.footer .right-footer {
    width: 60%;
    margin-left: 2%; }
    @media screen and (max-width: 1280px) {
      div.footer .right-footer {
        width: 65%; } }
    @media screen and (max-width: 480px) {
      div.footer .right-footer {
        width: 100%;
        margin: 0; } }
    div.footer .right-footer .informacion {
      display: flex;
      justify-content: space-around; }
      @media screen and (max-width: 768px) {
        div.footer .right-footer .informacion {
          flex-flow: wrap; } }
      @media screen and (max-width: 480px) {
        div.footer .right-footer .informacion {
          justify-content: space-between; } }
      div.footer .right-footer .informacion div {
        margin: 20px 15px;
        display: flex;
        flex-direction: column; }
        @media screen and (max-width: 1280px) {
          div.footer .right-footer .informacion div {
            margin: 20px 0; } }
        @media screen and (max-width: 768px) {
          div.footer .right-footer .informacion div {
            margin: 20px 30px; } }
        @media screen and (max-width: 480px) {
          div.footer .right-footer .informacion div {
            margin: 0 0 50px 0;
            justify-content: space-between;
            height: min-content; } }
        div.footer .right-footer .informacion div span {
          color: #343741;
          margin-bottom: 30px;
          font-family: Flama, sans-serif;
          font-weight: 500;
          font-size: 11px; }
        div.footer .right-footer .informacion div a {
          color: #979EA6;
          font-family: "IBM Plex Sans", sans-serif;
          font-weight: normal;
          font-size: 15px;
          line-height: 30px; }
    div.footer .right-footer .leyenda {
      margin: 20px 30px;
      color: #343741;
      font-family: Flama, sans-serif; }
      div.footer .right-footer .leyenda:hover {
        text-decoration: none;
        cursor: pointer; }

div.bottom-footer {
  display: flex; }
  @media screen and (max-width: 480px) {
    div.bottom-footer {
      text-align: center; } }
  @media screen and (max-width: 480px) {
    div.bottom-footer {
      flex-direction: column-reverse; } }
  div.bottom-footer .copyright {
    width: 30%; }
    @media screen and (max-width: 1280px) {
      div.bottom-footer .copyright {
        width: 25%; } }
    @media screen and (max-width: 480px) {
      div.bottom-footer .copyright {
        width: 100%; } }
    div.bottom-footer .copyright span {
      color: #979EA6;
      font-size: 13px; }
  div.bottom-footer div.div-newsletter {
    margin-left: 2%;
    width: 60%; }
    @media screen and (max-width: 1280px) {
      div.bottom-footer div.div-newsletter {
        width: 65%;
        margin-left: 0; } }
    @media screen and (max-width: 480px) {
      div.bottom-footer div.div-newsletter {
        width: 100%; } }
    div.bottom-footer div.div-newsletter .newsletter div {
      margin: 0 30px; }
      div.bottom-footer div.div-newsletter .newsletter div form p label {
        display: none !important; }
      div.bottom-footer div.div-newsletter .newsletter div form p input {
        width: 340px !important;
        background-color: transparent;
        transition: transform 250ms ease-in-out;
        font-size: 14px;
        line-height: 18px;
        color: #575756;
        background-image: url(../images/arrow-newsletter.svg);
        background-repeat: no-repeat;
        background-size: 18px 18px;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        padding: 12px 0;
        outline: 0;
        border: 1px solid transparent;
        border-bottom: 1px solid #575756;
        border-radius: 0;
        box-shadow: none;
        background-position: 100% center; }
        @media screen and (max-width: 768px) {
          div.bottom-footer div.div-newsletter .newsletter div form p input {
            width: auto !important;
            min-width: 300px;
            padding-right: 38px !important; } }
        @media screen and (max-width: 480px) {
          div.bottom-footer div.div-newsletter .newsletter div form p input {
            width: 284px !important; } }
        div.bottom-footer div.div-newsletter .newsletter div form p input::placeholder {
          letter-spacing: 1.5px;
          font-size: 12px; }
      div.bottom-footer div.div-newsletter .newsletter div form p input[type=submit] {
        display: none; }

footer.content-info {
  border-top: 2px solid #f7f9fb; }

body#tinymce {
  margin: 12px !important; }

div.slider {
  position: relative;
  max-width: 2500px;
  margin: 0 auto; }
  div.slider .n2-ss-slider-3 .transparentFixHero {
    position: absolute;
    width: 60%;
    height: 100%;
    background-color: white;
    z-index: 12;
    opacity: .6;
    pointer-events: none; }
  @media screen and (max-width: 480px) {
    div.slider .n2-section-smartslider span.titulo {
      font-size: 33px !important; }
    div.slider .n2-section-smartslider .text-top {
      margin: 0 0 0 3.5% !important; }
      div.slider .n2-section-smartslider .text-top p {
        line-height: 26px !important; }
    div.slider .n2-section-smartslider .text-bot {
      margin: 0 0 3% 3.5% !important; }
    div.slider .n2-section-smartslider p {
      line-height: 22px !important; }
      div.slider .n2-section-smartslider p span.info {
        display: block;
        margin-top: 22px; }
    div.slider .n2-section-smartslider #n2-ss-2-align .n2-padding #n2-ss-2 .n2-ss-slider-1 {
      width: 100% !important; } }
  @media screen and (max-width: 480px) and (min-width: 1024px) {
    div.slider .n2-section-smartslider #n2-ss-2-align .n2-padding #n2-ss-2 .n2-ss-slider-1 {
      width: 103% !important; } }
  @media screen and (max-width: 480px) {
      div.slider .n2-section-smartslider #n2-ss-2-align .n2-padding #n2-ss-2 .n2-ss-slider-1 .n2-ss-slider-2 {
        height: 158%; }
      div.slider .n2-section-smartslider #n2-ss-2-align .n2-padding #n2-ss-2 .n2-ss-slider-1 .n2-ss-widget {
        bottom: 0;
        top: 123%; } }
    @media screen and (max-width: 480px) and (max-width: 500px) {
      div.slider .n2-section-smartslider #n2-ss-2-align .n2-padding #n2-ss-2 .n2-ss-slider-1 .n2-ss-widget {
        top: 275px; } }
    @media screen and (max-width: 480px) and (max-width: 410px) {
      div.slider .n2-section-smartslider #n2-ss-2-align .n2-padding #n2-ss-2 .n2-ss-slider-1 .n2-ss-widget {
        top: 300px; } }
    @media screen and (max-width: 480px) and (max-width: 375px) {
      div.slider .n2-section-smartslider #n2-ss-2-align .n2-padding #n2-ss-2 .n2-ss-slider-1 .n2-ss-widget {
        top: 360px; } }
  @media screen and (max-width: 480px) {
    div.slider .n2-section-smartslider {
      height: 354px; } }
  @media screen and (max-width: 1280px) {
    div.slider .n2-section-smartslider span.titulo {
      font-size: 30px; } }
  @media screen and (max-width: 1280px) {
    div.slider .n2-section-smartslider span.sub-titulo {
      font-size: 30px;
      line-height: 42px; } }
  @media screen and (max-width: 480px) {
    div.slider .n2-section-smartslider div#n2-ss-2 .nextend-bullet-bar div.n2-ow {
      padding: 25px 10px 10px 25px; } }
  div.slider .n2-section-smartslider div#n2-ss-2-arrow-previous {
    position: relative !important;
    left: 0 !important;
    width: 32px !important;
    height: 32px !important;
    margin-left: 32vw;
    top: -11vw !important;
    z-index: 20; }
    @media screen and (max-width: 2500px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-previous {
        top: -4vw !important; } }
    @media screen and (max-width: 2350px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-previous {
        margin-left: 42vw !important; } }
    @media screen and (max-width: 2000px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-previous {
        margin-left: 37vw !important;
        top: -8vw !important; } }
    @media screen and (max-width: 1919px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-previous {
        margin-left: 30vw !important;
        top: -5vw !important; } }
    @media screen and (max-width: 1500px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-previous {
        margin-left: 28vw !important;
        top: -5vw !important; } }
    @media screen and (max-width: 1440px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-previous {
        margin-left: 26%; } }
    @media screen and (max-width: 1280px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-previous {
        margin-left: 20%;
        top: -7vw !important; } }
    @media screen and (max-width: 1024px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-previous {
        margin-left: 23vw !important; } }
    @media screen and (max-width: 768px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-previous {
        top: -50px !important;
        margin-left: 27%; } }
  div.slider .n2-section-smartslider div#n2-ss-2-arrow-next {
    position: relative !important;
    left: 0 !important;
    width: 32px !important;
    height: 32px !important;
    top: -11vw !important;
    z-index: 20; }
    @media screen and (max-width: 2500px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-next {
        top: -4vw !important; } }
    @media screen and (max-width: 2000px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-next {
        top: -8vw !important; } }
    @media screen and (max-width: 1919px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-next {
        top: -5vw !important; } }
    @media screen and (max-width: 1500px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-next {
        top: -5vw !important; } }
    @media screen and (max-width: 1280px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-next {
        top: -7vw !important; } }
    @media screen and (max-width: 768px) {
      div.slider .n2-section-smartslider div#n2-ss-2-arrow-next {
        top: -50px !important; } }
  @media screen and (max-width: 1440px) {
    div.slider .n2-section-smartslider .text-top {
      margin: 0 !important;
      padding: 0;
      padding-left: 0 !important; } }
  @media screen and (min-width: 500px) and (max-width: 1280px) {
    div.slider .n2-section-smartslider .text-top p {
      font-size: 36px !important;
      line-height: 42px !important; } }
  @media screen and (max-width: 1440px) {
    div.slider .n2-section-smartslider .text-bot {
      margin: 0 0 0 12.5% !important; } }
  @media screen and (min-width: 500px) and (max-width: 1280px) {
    div.slider .n2-section-smartslider .text-bot {
      margin: 0 0 3% 3.5% !important; }
      div.slider .n2-section-smartslider .text-bot p {
        font-size: 14px !important;
        line-height: 20px !important; } }
  div.slider .n2-section-smartslider div.n2-ss-control-bullet {
    margin-bottom: 3% !important;
    bottom: -3% !important; }
    @media screen and (max-width: 1280px) {
      div.slider .n2-section-smartslider div.n2-ss-control-bullet {
        margin-bottom: 1% !important;
        bottom: 2% !important; } }
  @media screen and (max-width: 480px) {
    div.slider .n2-section-smartslider div.n2-ss-slide-background-image {
      opacity: .6 !important; } }

#recuadroBlanco {
  position: absolute;
  z-index: 987;
  pointer-events: none;
  margin-left: 10.5% !important;
  left: 20px;
  bottom: 4% !important; }
  @media screen and (max-width: 1280px) {
    #recuadroBlanco {
      left: 20px;
      margin-left: 4% !important;
      bottom: 7%; } }
  #recuadroBlanco span {
    width: 45px;
    height: 45px;
    display: inline-block;
    color: #000000;
    font-size: 18px;
    font-family: "IBM Plex Sans"; }
    #recuadroBlanco span:nth-child(even) {
      margin: 0 10px; }
  @media screen and (max-width: 480px) {
    #recuadroBlanco {
      bottom: 0;
      left: 13px;
      top: 79%;
      margin-left: 4%; }
      #recuadroBlanco span {
        width: 25px;
        height: 30px;
        font-size: 16px; } }

@media screen and (min-width: 1700px) {
  #recuadroBlanco {
    position: absolute;
    z-index: 987;
    pointer-events: none;
    bottom: 5% !important; } }

@media screen and (min-width: 1600px) {
  #recuadroBlanco {
    margin-left: 10.5% !important; }
  .n2-ss-section-main-content.n2-ss-layer-content.n2-ow {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100% !important; }
  .n2-ss-layer.n2-ow.text-top {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important; }
  .n2-ss-slider .n2-ss-layer[data-has-maxwidth="1"][data-pm=normal] {
    margin: 100px 0 0 0 !important;
    max-width: 100% !important; }
  .n2-ss-item-content.n2-ow .info {
    font-weight: 500 !important;
    line-height: 28px !important;
    color: #343741 !important;
    font-size: 15px !important; }
  .n2-ss-layer.n2-ow.text-bot {
    padding: 0 13% 0 28% !important;
    margin: 0 !important; }
  div#n2-ss-2 .n2-ss-slider-1 {
    width: 100%; }
  div#n2-ss-2 .n2-ss-layers-container {
    margin: 0 auto !important; }
  div.slider .n2-section-smartslider div.n2-ss-control-bullet {
    position: relative !important;
    bottom: 25% !important; } }
  @media screen and (min-width: 1600px) and (min-width: 2500px) {
    div.slider .n2-section-smartslider div.n2-ss-control-bullet {
      bottom: 40% !important; } }

@media screen and (min-width: 1600px) {
  #recuadroBlanco {
    position: absolute;
    z-index: 987;
    pointer-events: none;
    left: 26px;
    bottom: 4% !important; } }

.n2-ss-slider-2.n2-ow .titulo {
  font-family: Flama;
  font-size: 62px; }

.n2-ss-slider-2.n2-ow .sub-titulo {
  font-family: Flama;
  font-size: 62px; }

.n2-ss-slider-2.n2-ow .info {
  font-weight: 500;
  line-height: 28px;
  color: #343741;
  font-size: 15px; }

html {
  margin: 0 !important; }

@media screen and (max-width: 1699px) {
  .n2-ss-slider-2.n2-ow .titulo,
  .n2-ss-slider-2.n2-ow .sub-titulo {
    font-family: Flama;
    font-size: 38px;
    line-height: 42px; } }

@media screen and (max-width: 1599px) {
  .n2-ss-slider-2.n2-ow .titulo .n2-ss-slider-2.n2-ow .sub-titulo {
    font-family: Flama;
    font-size: 32px;
    line-height: 42px; } }

@media screen and (min-width: 1368px) and (max-width: 1698px) {
  div.slider .n2-section-smartslider div.n2-ss-control-bullet {
    margin-left: 12.5% !important; }
  #recuadroBlanco {
    margin-left: 12.5% !important; } }

@media screen and (max-width: 1280px) {
  div.slider .n2-section-smartslider span.titulo {
    font-size: 30px;
    line-height: 42px; }
  div.slider .n2-section-smartslider div.n2-ss-control-bullet {
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    left: 0px !important;
    bottom: 2% !important; } }

@media screen and (max-width: 1024px) {
  .n2-ss-slider .n2-ss-slider-1 .n2-ss-layer-content {
    padding: 0 !important; } }

@media screen and (max-width: 1023px) {
  div#n2-ss-2 .n2-ss-layers-container {
    margin-top: 0 !important; }
  header.banner .desktop-nav #menu-header-menu .active a, header.banner .desktop-nav #menu-header-menu a:hover {
    text-decoration: none;
    border-bottom: 5px solid #9e0349;
    padding-bottom: 5px; }
  div.slider .n2-section-smartslider span.titulo {
    font-size: 32px !important;
    line-height: 42px !important; }
  .n2-ss-slider .n2-ss-layer-content[data-verticalalign=center] {
    padding: 0 !important; }
  div.slider .n2-section-smartslider .text-top {
    margin: 0 !important; }
  div.slider .n2-section-smartslider .text-bot {
    margin: 0 !important; }
  .n2-ss-slider .n2-ss-slider-1 .n2-ss-layer-content {
    padding: 65px 10% 0 5% !important; }
  div.slider .n2-section-smartslider #n2-ss-2-align .n2-padding #n2-ss-2 .n2-ss-slider-1 .n2-ss-widget {
    visibility: hidden; } }

div#n2-ss-2 .n2-ss-control-bullet {
  visibility: visible !important;
  text-align: center;
  justify-content: left !important;
  opacity: 1 !important;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1440px; }

body .n2-ss-bullet-thumbnail-container {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important; }

.contact-form-home {
  display: flex; }
  .contact-form-home div.dynamic-sidebar-contactform {
    width: 65%; }
    @media screen and (max-width: 430px) {
      .contact-form-home div.dynamic-sidebar-contactform {
        width: 100%; } }
    .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario {
      padding: 20px 20% 0 35px;
      background-color: #838A93; }
      @media screen and (max-width: 1280px) {
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario {
          padding: 40px 5% 0 35px; } }
      @media screen and (max-width: 480px) {
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario {
          padding: 60px 5% 0 6%; } }
      .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos {
        display: flex;
        align-items: flex-end; }
        @media screen and (max-width: 430px) {
          .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos {
            flex-direction: column;
            align-items: normal; }
            .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .sub-titulo {
              display: none; } }
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-left {
          padding-right: 80px; }
          .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-left .titulo {
            font-family: Flama;
            font-size: 42px;
            line-height: 52px;
            color: white;
            margin-bottom: 65px; }
            @media screen and (max-width: 1280px) {
              .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-left .titulo {
                margin-bottom: 11px; } }
          @media screen and (max-width: 1280px) {
            .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-left {
              padding-right: 45px; } }
          .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-left input {
            width: 350px;
            padding: 12px 0;
            outline: 0;
            border: 1px solid transparent;
            border-bottom: 1px solid #D4D4D4;
            border-radius: 0;
            box-shadow: none;
            background-position: 100% center;
            background-color: transparent;
            color: white;
            font-weight: 500;
            font-family: "IBM Plex Sans"; }
            .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-left input:focus {
              color: white; }
              .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-left input:focus::placeholder {
                transition: .3s;
                font-weight: 500;
                color: white; }
            .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-left input::placeholder {
              font-family: "IBM Plex Sans";
              font-weight: 200;
              color: #EFF3F6; }
            @media screen and (max-width: 1280px) {
              .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-left input {
                width: 260px; } }
            @media screen and (max-width: 480px) {
              .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-left input {
                width: 100%; } }
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-right .sub-titulo {
          font-family: "IBM Plex Sans";
          font-size: 18px;
          color: white;
          padding-bottom: 35px;
          line-height: 28px;
          margin-bottom: 20px;
          font-weight: 500; }
          @media screen and (max-width: 1280px) {
            .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-right .sub-titulo {
              padding-bottom: 0;
              font-size: 12px; } }
        @media screen and (max-width: 480px) {
          .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-right {
            padding-right: 45px; } }
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-right input {
          width: 350px;
          padding: 12px 0;
          outline: 0;
          border: 1px solid transparent;
          border-bottom: 1px solid #D4D4D4;
          border-radius: 0;
          box-shadow: none;
          background-position: 100% center;
          background-color: transparent;
          color: white;
          font-weight: 500;
          font-family: "IBM Plex Sans"; }
          .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-right input:focus {
            color: white; }
            .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-right input:focus::placeholder {
              transition: .3s;
              font-weight: 500;
              color: white; }
          .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-right input::placeholder {
            font-family: "IBM Plex Sans";
            font-weight: 200;
            color: #EFF3F6; }
          @media screen and (max-width: 1280px) {
            .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-right input {
              width: 260px; } }
          @media screen and (max-width: 480px) {
            .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .formulario .campos .formulario-right input {
              width: 100%; } }
    .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mensaje-div {
      padding: 0 8% 0 35px;
      background-color: #838A93; }
      @media screen and (max-width: 1280px) {
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mensaje-div {
          padding: 0 8% 0 35px; } }
      @media screen and (max-width: 480px) {
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mensaje-div {
          padding: 0 66px 0 6%; } }
      .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mensaje-div textarea {
        width: 100%;
        max-width: 1200px;
        height: 50px;
        padding: 12px 0;
        outline: 0;
        border: 1px solid transparent;
        border-bottom: 1px solid #D4D4D4;
        border-radius: 0;
        box-shadow: none;
        background-position: 100% center;
        background-color: transparent;
        color: white;
        font-weight: 500;
        font-family: "IBM Plex Sans"; }
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mensaje-div textarea:focus {
          color: white; }
          .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mensaje-div textarea:focus::placeholder {
            transition: .3s;
            font-weight: 500;
            color: white; }
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mensaje-div textarea::placeholder {
          font-family: "IBM Plex Sans";
          font-weight: 200;
          color: #EFF3F6; }
    .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form {
      padding: 0 20% 0 35px;
      display: flex;
      background-color: #838A93;
      justify-content: space-between;
      align-items: center; }
      @media screen and (max-width: 480px) {
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form {
          padding: 10px 20% 60px 6%; } }
      .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form p {
        width: 100%; }
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form p span:nth-child(1) span {
          display: flex;
          align-items: center; }
      .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form input {
        padding: 0 !important;
        margin-right: 5px !important; }
      .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form .sub-titulo-mobile {
        display: none; }
      @media screen and (max-width: 430px) {
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form {
          display: block; }
          .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form .sub-titulo-mobile {
            margin: 18px 0;
            display: block; } }
      .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form span {
        font-size: 15px;
        color: #FDFDFD;
        font-family: "IBM Plex Sans";
        font-weight: normal; }
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form span input {
          vertical-align: middle;
          margin: 15px 0;
          border: 1px solid #D4D4D4;
          height: 15px;
          width: 15px; }
          @media screen and (max-width: 430px) {
            .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form span input {
              margin: 10px 0; } }
      .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form input {
        margin-bottom: 15px;
        font-size: 15px;
        background-color: Transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
        font-family: Flama;
        font-weight: 500;
        color: white;
        transition: .6s; }
      .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form .wpcf7-checkbox span.first {
        margin-left: 0 !important; }
      .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .mid-form .wpcf7-checkbox span.last {
        margin-left: 10%; }
    .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .bottom-form {
      background-color: #F7F9FB;
      padding: 40px 0 35px 35px;
      display: flex;
      justify-content: left; }
      @media screen and (max-width: 1280px) {
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .bottom-form {
          padding: 40px 0 35px 35px; } }
      @media screen and (max-width: 480px) {
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .bottom-form {
          padding: 37px 5% 60px 6%; } }
      .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .bottom-form div {
        margin: 0 50px 0 0; }
        .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .bottom-form div p {
          margin-bottom: 5px;
          color: #343741;
          font-size: 11px;
          font-weight: 700; }
          .contact-form-home div.dynamic-sidebar-contactform div.formulario-de-contacto .bottom-form div p span {
            color: #979EA6;
            font-family: "IBM Plex Sans";
            font-size: 15px; }
  .contact-form-home div.sideimg {
    width: 35%;
    background-image: url("../../images/homecontact-1024x683.jpg");
    background-position: center;
    background-size: cover; }
    @media screen and (max-width: 430px) {
      .contact-form-home div.sideimg {
        display: none; } }

.institucional-page span.ubicacion {
  font-family: Flama;
  font-size: 11px;
  color: #868891;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  padding-top: 30px;
  display: block; }
  .institucional-page span.ubicacion a {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500; }
    .institucional-page span.ubicacion a:hover {
      text-decoration: none; }

.institucional-page .hero-top .hero-top-image {
  position: relative;
  background-position-y: center; }
  .institucional-page .hero-top .hero-top-image .opacidad-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.6);
    text-align: right; }
    .institucional-page .hero-top .hero-top-image .opacidad-background div.hero-top-info {
      display: inline-block;
      max-width: 685px;
      text-align: left; }
      @media screen and (max-width: 1280px) {
        .institucional-page .hero-top .hero-top-image .opacidad-background div.hero-top-info {
          padding: 65px 10% 0 4%; } }
      .institucional-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
        font-family: Flama;
        margin: 50px 0;
        line-height: 64px;
        color: #9E0348;
        display: block;
        font-size: 62px; }
        @media screen and (max-width: 1280px) {
          .institucional-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
            margin: 30px 0; } }
      .institucional-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.sub-titulo {
        font-weight: 500;
        line-height: 28px;
        color: #343741;
        font-size: 15px; }
      .institucional-page .hero-top .hero-top-image .opacidad-background div.hero-top-info .indicador {
        margin: 70px 0;
        width: 18px;
        height: 18px;
        background-color: #9E0349; }

.institucional-page .contenido .entrada .single {
  margin: 100px 0;
  display: flex; }
  @media screen and (max-width: 1023px) {
    .institucional-page .contenido .entrada .single {
      flex-direction: column; } }
  .institucional-page .contenido .entrada .single .entrada-info {
    width: 50%; }
    @media screen and (max-width: 1023px) {
      .institucional-page .contenido .entrada .single .entrada-info {
        width: 100%;
        flex-direction: column; }
        .institucional-page .contenido .entrada .single .entrada-info div {
          flex-direction: column; } }
    .institucional-page .contenido .entrada .single .entrada-info .primer-columna-entrada {
      width: 50%;
      display: flex; }
      @media screen and (max-width: 1023px) {
        .institucional-page .contenido .entrada .single .entrada-info .primer-columna-entrada {
          width: 100%; } }
    .institucional-page .contenido .entrada .single .entrada-info .segunda-columna-entrada {
      width: 50%; }
      @media screen and (max-width: 1023px) {
        .institucional-page .contenido .entrada .single .entrada-info .segunda-columna-entrada {
          width: 100%; } }
    .institucional-page .contenido .entrada .single .entrada-info .indicador {
      width: 18px;
      height: 18px;
      background-color: #9E0349; }
    .institucional-page .contenido .entrada .single .entrada-info .titulo {
      display: block;
      margin-top: 40px;
      margin-bottom: 50px;
      font-family: Flama;
      font-size: 48px;
      font-weight: 500;
      line-height: 58px;
      color: #343741; }
    @media screen and (min-width: 1024px) {
      .institucional-page .contenido .entrada .single .entrada-info .titulo-mobie-margin {
        margin: 0; } }
    .institucional-page .contenido .entrada .single .entrada-info .sub-titulo {
      font-weight: 500;
      font-size: 15px;
      color: #343741;
      line-height: 28px; }
  .institucional-page .contenido .entrada .single .entrada-image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    @media screen and (max-width: 1023px) {
      .institucional-page .contenido .entrada .single .entrada-image {
        width: 100%;
        padding: 0 !important; } }
    .institucional-page .contenido .entrada .single .entrada-image img {
      max-width: 100%;
      height: auto; }

@media screen and (max-width: 1023px) {
  .institucional-page .contenido .izquierda {
    flex-direction: column-reverse !important; } }

.institucional-page .hero-bottom {
  background-color: #F7F9FB;
  display: flex;
  margin-top: 100px; }
  .institucional-page .hero-bottom .hero-bottom-info {
    width: 50%;
    padding: 5%; }
    @media screen and (max-width: 1280px) {
      .institucional-page .hero-bottom .hero-bottom-info {
        padding: 65px 10% 0 2%; } }
    .institucional-page .hero-bottom .hero-bottom-info .indicador {
      width: 18px;
      height: 18px;
      background-color: #9E0349; }
    .institucional-page .hero-bottom .hero-bottom-info span.titulo {
      font-family: Flama;
      margin: 40px 0;
      display: block;
      font-size: 48px;
      color: #343741; }
      @media screen and (max-width: 1280px) {
        .institucional-page .hero-bottom .hero-bottom-info span.titulo {
          margin: 35px 0; } }
    .institucional-page .hero-bottom .hero-bottom-info span.sub-titulo {
      line-height: 28px;
      font-size: 15px;
      color: #343741;
      letter-spacing: 1px;
      display: block;
      font-weight: 500;
      margin-bottom: 30px; }
    .institucional-page .hero-bottom .hero-bottom-info span.info {
      color: #979EA6;
      line-height: 24px;
      font-size: 12px; }
  .institucional-page .hero-bottom .image-hero {
    width: 50%;
    height: 700px; }

.rrhh-page span.ubicacion {
  font-family: Flama;
  font-size: 11px;
  color: #868891;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  padding-top: 30px;
  display: block; }
  .rrhh-page span.ubicacion a {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500; }
    .rrhh-page span.ubicacion a:hover {
      text-decoration: none; }

.rrhh-page .hero-top .hero-top-image {
  position: relative;
  background-position-y: center; }
  .rrhh-page .hero-top .hero-top-image .opacidad-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.6);
    text-align: right; }
    @media screen and (max-width: 767px) {
      .rrhh-page .hero-top .hero-top-image .opacidad-background {
        width: 100%; } }
    .rrhh-page .hero-top .hero-top-image .opacidad-background div.hero-top-info {
      display: inline-block;
      max-width: 685px;
      text-align: left; }
      @media screen and (max-width: 1280px) {
        .rrhh-page .hero-top .hero-top-image .opacidad-background div.hero-top-info {
          padding: 65px 10% 0 4%; } }
      .rrhh-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
        font-family: Flama;
        margin: 50px 0;
        line-height: 64px;
        color: #9E0348;
        display: block;
        font-size: 62px; }
        @media screen and (max-width: 1280px) {
          .rrhh-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
            margin: 30px 0; } }
        @media screen and (max-width: 768px) {
          .rrhh-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
            font-size: 45px; } }
      .rrhh-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.sub-titulo {
        font-weight: 500;
        line-height: 28px;
        color: #343741;
        font-size: 15px; }
      .rrhh-page .hero-top .hero-top-image .opacidad-background div.hero-top-info .indicador {
        margin: 30px 0;
        width: 18px;
        height: 18px;
        background-color: #9E0349; }

.rrhh-page .contenido .entrada .single {
  margin: 70px 0 100px;
  display: flex; }
  @media screen and (max-width: 768px) {
    .rrhh-page .contenido .entrada .single {
      flex-direction: column; } }
  .rrhh-page .contenido .entrada .single .entrada-info {
    width: 50%; }
    @media screen and (max-width: 768px) {
      .rrhh-page .contenido .entrada .single .entrada-info {
        width: 100%; } }
    .rrhh-page .contenido .entrada .single .entrada-info .indicador {
      width: 18px;
      height: 18px;
      background-color: #9E0349; }
    .rrhh-page .contenido .entrada .single .entrada-info .titulo {
      display: block;
      margin-top: 70px;
      margin-bottom: 50px;
      font-family: Flama;
      font-size: 48px;
      font-weight: 500;
      line-height: 58px;
      color: #343741; }
    .rrhh-page .contenido .entrada .single .entrada-info .sub-titulo {
      font-weight: 500;
      font-size: 15px;
      color: #343741;
      line-height: 28px; }
  .rrhh-page .contenido .entrada .single .entrada-image {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .rrhh-page .contenido .entrada .single .entrada-image {
        width: 100%; } }
    .rrhh-page .contenido .entrada .single .entrada-image span {
      display: block;
      margin-inline-start: auto;
      margin-right: 10px;
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 30px; }
    .rrhh-page .contenido .entrada .single .entrada-image img {
      max-width: 100%;
      height: auto; }
  .rrhh-page .contenido .entrada .single .image-izquierda {
    padding-right: 60px; }
    @media screen and (max-width: 768px) {
      .rrhh-page .contenido .entrada .single .image-izquierda {
        padding: 0; } }
  .rrhh-page .contenido .entrada .single .image-derecha {
    padding-left: 60px; }
    @media screen and (max-width: 768px) {
      .rrhh-page .contenido .entrada .single .image-derecha {
        padding: 0; } }

@media screen and (max-width: 768px) {
  .rrhh-page .contenido .entrada .left-image-block {
    flex-direction: column-reverse; } }

.rrhh-page .hero-bottom {
  background-color: #F7F9FB;
  display: flex;
  margin-top: 100px; }
  .rrhh-page .hero-bottom .hero-bottom-info {
    width: 50%;
    padding: 100px 13% 0 14%; }
    @media screen and (max-width: 1280px) {
      .rrhh-page .hero-bottom .hero-bottom-info {
        padding: 65px 10% 0 2%; } }
    .rrhh-page .hero-bottom .hero-bottom-info .indicador {
      width: 18px;
      height: 18px;
      background-color: #9E0349; }
    .rrhh-page .hero-bottom .hero-bottom-info span.titulo {
      font-family: Flama;
      margin: 70px 0;
      display: block;
      font-size: 48px;
      color: #343741; }
      @media screen and (max-width: 1280px) {
        .rrhh-page .hero-bottom .hero-bottom-info span.titulo {
          margin: 35px 0; } }
    .rrhh-page .hero-bottom .hero-bottom-info span.sub-titulo {
      line-height: 28px;
      font-size: 15px;
      color: #343741;
      letter-spacing: 1px;
      display: block;
      font-weight: 500;
      margin-bottom: 30px; }
    .rrhh-page .hero-bottom .hero-bottom-info span.info {
      color: #979EA6;
      line-height: 24px;
      font-size: 12px; }
  .rrhh-page .hero-bottom .image-hero {
    width: 50%;
    height: 700px; }

.rrhh-page .boton-cv {
  margin-bottom: 230px;
  margin-right: 35%;
  max-width: 500px; }
  .rrhh-page .boton-cv .center {
    float: right; }
    .rrhh-page .boton-cv .center .btn, .rrhh-page .boton-cv .center .comment-form input[type="submit"], .comment-form .rrhh-page .boton-cv .center input[type="submit"], .rrhh-page .boton-cv .center .search-form .search-submit, .search-form .rrhh-page .boton-cv .center .search-submit,
    .rrhh-page .boton-cv .center button {
      background: #720023;
      color: white;
      border: none;
      font-size: 10px;
      font-size: 1rem;
      background-color: #720023;
      color: white;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      padding: 14px 21px;
      border: 2px solid #720023;
      transition: color 0.1s cubic-bezier(0.16, 0.08, 0.355, 1), background 0.1s cubic-bezier(0.16, 0.08, 0.355, 1);
      display: inline-block;
      cursor: pointer;
      width: 100%;
      min-width: 200px;
      min-width: 12rem;
      outline: none;
      vertical-align: middle;
      text-align: center;
      position: relative;
      overflow: hidden; }
      @media (min-width: 400px) {
        .rrhh-page .boton-cv .center .btn, .rrhh-page .boton-cv .center .comment-form input[type="submit"], .comment-form .rrhh-page .boton-cv .center input[type="submit"], .rrhh-page .boton-cv .center .search-form .search-submit, .search-form .rrhh-page .boton-cv .center .search-submit,
        .rrhh-page .boton-cv .center button {
          width: auto; } }
      @media (min-width: 800px) {
        .rrhh-page .boton-cv .center .btn, .rrhh-page .boton-cv .center .comment-form input[type="submit"], .comment-form .rrhh-page .boton-cv .center input[type="submit"], .rrhh-page .boton-cv .center .search-form .search-submit, .search-form .rrhh-page .boton-cv .center .search-submit,
        .rrhh-page .boton-cv .center button {
          font-size: 1.1rem;
          padding: .8rem 2.8rem; } }
      .rrhh-page .boton-cv .center .btn:hover, .rrhh-page .boton-cv .center .comment-form input[type="submit"]:hover, .comment-form .rrhh-page .boton-cv .center input[type="submit"]:hover, .rrhh-page .boton-cv .center .search-form .search-submit:hover, .search-form .rrhh-page .boton-cv .center .search-submit:hover,
      .rrhh-page .boton-cv .center button:hover {
        background: #8c002b;
        border-color: #8c002b;
        color: #fff; }
    .rrhh-page .boton-cv .center .btn-border {
      background-color: transparent;
      color: #720023; }
    .rrhh-page .boton-cv .center .btn-arrow {
      position: relative;
      transition: background-color 300ms ease-out; }
      .rrhh-page .boton-cv .center .btn-arrow span {
        display: inline-block;
        position: relative;
        transition: all 300ms ease-out;
        will-change: transform; }
      .rrhh-page .boton-cv .center .btn-arrow:hover span {
        transform: translate3d(-1rem, 0, 0); }
      .rrhh-page .boton-cv .center .btn-arrow svg {
        position: absolute;
        width: 1.1em;
        right: 0px;
        right: 0rem;
        opacity: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 300ms ease-out;
        will-change: right, opacity; }
        .rrhh-page .boton-cv .center .btn-arrow svg * {
          stroke-width: 5;
          stroke-color: transparent; }
      .rrhh-page .boton-cv .center .btn-arrow:hover svg {
        opacity: 1;
        right: -2rem; }

.productos-page span.ubicacion {
  font-family: Flama;
  font-size: 11px;
  color: #868891;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  padding-top: 30px;
  display: block; }
  .productos-page span.ubicacion a {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500; }
    .productos-page span.ubicacion a:hover {
      text-decoration: none; }

.productos-page .the-form-sss {
  position: relative;
  z-index: 1;
  top: 300px; }
  .productos-page .the-form-sss span.busqueda {
    color: #343741;
    display: block;
    margin-top: 5vw;
    font-size: 26px;
    border-top: 3px solid #AFB1B7; }
  .productos-page .the-form-sss form {
    margin-top: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 1280px) {
      .productos-page .the-form-sss form {
        width: 100%; } }
    @media screen and (max-width: 767px) {
      .productos-page .the-form-sss form {
        flex-direction: column;
        margin-top: 25px; } }
    .productos-page .the-form-sss form input {
      width: 200px;
      background-color: transparent;
      font-size: 14px;
      line-height: 18px;
      color: #343741;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      padding: 12px 0;
      outline: 0;
      font-weight: bold;
      border: 1px solid transparent;
      border-bottom: 1px solid #575756;
      border-radius: 0;
      box-shadow: none; }
    .productos-page .the-form-sss form select {
      width: 200px;
      background-color: transparent;
      font-size: 14px;
      line-height: 18px;
      color: #575756;
      background-image: url(../../images/arrowdown.png);
      background-repeat: no-repeat;
      background-size: 14px 8px;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      padding: 12px 0;
      outline: 0;
      font-weight: bold;
      border: 1px solid transparent;
      border-bottom: 1px solid #575756;
      border-radius: 0;
      box-shadow: none;
      background-position: 100% center;
      -moz-appearance: none;
      /* Firefox */
      -webkit-appearance: none;
      /* Safari and Chrome */
      appearance: none; }
      @media screen and (max-width: 1280px) {
        .productos-page .the-form-sss form select {
          padding: 12px 18px; } }
    .productos-page .the-form-sss form button {
      width: 200px;
      background-color: transparent;
      font-size: 15px;
      line-height: 18px;
      color: #575756;
      text-transform: uppercase;
      font-weight: bold;
      background-image: url(../../images/arrowrounded.png);
      background-repeat: no-repeat;
      background-size: 18px 18px;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      padding: 12px 0;
      outline: 0;
      border: 1px solid transparent;
      border-bottom: 2px solid #575756;
      border-radius: 0;
      box-shadow: none;
      background-position: 100% center;
      transition: .5s; }
      .productos-page .the-form-sss form button:hover {
        color: #9e0349; }
    @media screen and (max-width: 768px) {
      .productos-page .the-form-sss form input,
      .productos-page .the-form-sss form select,
      .productos-page .the-form-sss form button {
        width: 100%; } }
    @media screen and (max-width: 500px) {
      .productos-page .the-form-sss form input,
      .productos-page .the-form-sss form select,
      .productos-page .the-form-sss form button {
        width: 100%; } }
  .productos-page .the-form-sss .indicador {
    margin: 70px 0;
    width: 18px;
    height: 18px;
    background-color: #9E0349; }

.productos-page .hero-top .hero-top-image {
  position: relative;
  background-position-y: center; }
  .productos-page .hero-top .hero-top-image .opacidad-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.6);
    padding: 0;
    width: 100%; }
    .productos-page .hero-top .hero-top-image .opacidad-background div.hero-top-info {
      padding: 65px 10% 0 1%;
      left: 0; }
      @media screen and (min-width: 1200px) {
        .productos-page .hero-top .hero-top-image .opacidad-background div.hero-top-info {
          padding: 65px 9% 0 0%;
          display: inline-block;
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0; } }
      .productos-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
        font-family: Flama;
        margin: 0 0 50px 0;
        line-height: 64px;
        color: #9E0348;
        display: block;
        font-size: 62px; }
        @media screen and (max-width: 1280px) {
          .productos-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
            margin: 30px 0; } }
      .productos-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.sub-titulo {
        font-weight: 500;
        line-height: 28px;
        color: #343741;
        font-size: 15px; }
      .productos-page .hero-top .hero-top-image .opacidad-background div.hero-top-info .indicador {
        margin: 70px 0;
        width: 18px;
        height: 18px;
        background-color: #9E0349; }

.productos-page .contenido {
  background-color: #EFF3F6; }
  .productos-page .contenido .abc-pagination {
    padding: 80px 0;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 500px) {
      .productos-page .contenido .abc-pagination {
        display: none; } }
    .productos-page .contenido .abc-pagination .indicador {
      width: 18px;
      height: 18px;
      background-color: #9E0349; }
    .productos-page .contenido .abc-pagination div {
      width: 100%; }
      .productos-page .contenido .abc-pagination div a {
        margin: 0 10px;
        font-family: Flama;
        color: #343741;
        font-size: 24px;
        text-decoration: none; }
      .productos-page .contenido .abc-pagination div a:nth-child(1) {
        margin: 0 25px;
        font-size: 24px;
        text-decoration: underline;
        text-transform: capitalize; }
      .productos-page .contenido .abc-pagination div .active {
        background-color: #9e0349;
        padding: 0 8px;
        border-radius: 7px;
        color: white; }
      @media screen and (max-width: 767px) {
        .productos-page .contenido .abc-pagination div a:nth-child(1) {
          display: block; } }
  .productos-page .contenido .product-content {
    position: relative; }
    .productos-page .contenido .product-content div.padre:hover {
      background-color: white;
      -webkit-box-shadow: 0px 39px 214px -41px #9f9f9f;
      -moz-box-shadow: 0px 39px 214px -41px #9f9f9f;
      box-shadow: 0px 39px 214px -41px #9f9f9f; }
      .productos-page .contenido .product-content div.padre:hover h3.nombre a {
        transition: .3s;
        color: #720023 !important; }
        .productos-page .contenido .product-content div.padre:hover h3.nombre a span {
          transition: .2s;
          color: #720023 !important;
          font-weight: bold !important; }
        .productos-page .contenido .product-content div.padre:hover h3.nombre a:hover {
          text-decoration: none; }
          .productos-page .contenido .product-content div.padre:hover h3.nombre a:hover span {
            text-decoration: none; }
    .productos-page .contenido .product-content ul {
      padding: 0 30px;
      margin: 0 !important;
      border-bottom: 1px solid #eeeeee;
      border-top: 1px solid #eeeeee;
      list-style: none; }
      @media screen and (max-width: 767px) {
        .productos-page .contenido .product-content ul {
          padding: 0 10px; } }
      .productos-page .contenido .product-content ul li {
        padding: 5px 0;
        display: flex;
        align-items: center;
        position: relative; }
        .productos-page .contenido .product-content ul li a.redirect {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 2; }
        .productos-page .contenido .product-content ul li a.numero-producto {
          margin: 0 40px;
          text-align: center;
          font-size: 18px;
          font-family: Flama;
          width: 70px; }
          @media screen and (max-width: 500px) {
            .productos-page .contenido .product-content ul li a.numero-producto {
              margin: 0;
              width: auto; } }
        .productos-page .contenido .product-content ul li .contenedor-imagen {
          background-color: white;
          width: 300px;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center; }
          @media screen and (max-width: 500px) {
            .productos-page .contenido .product-content ul li .contenedor-imagen {
              width: 100px;
              height: auto;
              margin: 0 20px; } }
        .productos-page .contenido .product-content ul li img.imagen-producto {
          max-height: 100%;
          max-width: 100%;
          height: auto;
          margin: auto; }
          @media screen and (max-width: 767px) {
            .productos-page .contenido .product-content ul li img.imagen-producto {
              max-height: none;
              max-width: none;
              width: 100%;
              object-fit: cover; } }
        .productos-page .contenido .product-content ul li div.textos {
          display: flex;
          align-items: center;
          width: 40%;
          margin-left: 40px; }
          @media screen and (max-width: 767px) {
            .productos-page .contenido .product-content ul li div.textos {
              width: 70%;
              margin-left: 13px; } }
          @media screen and (max-width: 500px) {
            .productos-page .contenido .product-content ul li div.textos {
              display: block;
              text-align: initial; } }
          .productos-page .contenido .product-content ul li div.textos h3.nombre a {
            color: #343741;
            text-transform: uppercase;
            display: block; }
            @media screen and (max-width: 768px) {
              .productos-page .contenido .product-content ul li div.textos h3.nombre a {
                font-size: 20px;
                display: block; } }
            @media screen and (max-width: 500px) {
              .productos-page .contenido .product-content ul li div.textos h3.nombre a {
                margin: 0;
                font-size: 15px; } }
            .productos-page .contenido .product-content ul li div.textos h3.nombre a:hover {
              text-decoration: none; }
              .productos-page .contenido .product-content ul li div.textos h3.nombre a:hover span {
                text-decoration: none; }
            .productos-page .contenido .product-content ul li div.textos h3.nombre a span {
              color: #979EA6;
              text-transform: capitalize; }
        .productos-page .contenido .product-content ul li div.tacc-image {
          width: 70px;
          height: 70px;
          background-size: cover;
          background-position: center;
          margin-left: auto; }
          @media screen and (max-width: 500px) {
            .productos-page .contenido .product-content ul li div.tacc-image {
              width: 35px;
              height: 35px; } }
  .productos-page .contenido .numeric-pagination {
    display: flex;
    padding: 80px 0;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .productos-page .contenido .numeric-pagination {
        justify-content: center; } }
    .productos-page .contenido .numeric-pagination .indicador {
      width: 18px;
      height: 18px;
      background-color: #9E0349;
      margin-right: 4vw; }
      @media screen and (max-width: 767px) {
        .productos-page .contenido .numeric-pagination .indicador {
          position: absolute;
          left: 20px; } }
    .productos-page .contenido .numeric-pagination span {
      font-size: 24px;
      color: #C14B85;
      text-decoration: underline;
      margin: 0 .5vw; }
    .productos-page .contenido .numeric-pagination a {
      color: #343741;
      font-size: 24px;
      margin: 0 .2vw; }

.single-producto span.ubicacion {
  font-family: Flama;
  font-size: 11px;
  color: #868891;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  padding-top: 30px;
  display: block; }
  .single-producto span.ubicacion a {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500; }
    .single-producto span.ubicacion a:hover {
      text-decoration: none; }

.single-producto .hero-top .hero-top-image {
  position: relative; }
  .single-producto .hero-top .hero-top-image .opacidad-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.6); }
    .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info {
      padding: 100px 0 0 0; }
      @media screen and (max-width: 1280px) {
        .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info {
          padding: 65px 10% 0 4%; } }
      .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
        font-family: Flama;
        margin: 30px 0;
        line-height: 64px;
        color: #9E0348;
        display: block;
        font-size: 62px; }
        @media screen and (max-width: 1280px) {
          .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
            margin: 30px 0; } }
      .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info span.sub-titulo {
        font-weight: 500;
        line-height: 28px;
        color: #343741;
        font-size: 15px; }
      .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info span.busqueda {
        color: #343741;
        display: block;
        margin-top: 5vw;
        font-size: 26px;
        border-top: 3px solid #AFB1B7; }
      .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info div.formularioSearchSingleProducto {
        margin-top: 55px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        @media screen and (max-width: 1280px) {
          .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info div.formularioSearchSingleProducto {
            width: 100%; } }
        @media screen and (max-width: 1023px) {
          .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info div.formularioSearchSingleProducto {
            flex-direction: column; } }
        .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info div.formularioSearchSingleProducto input {
          width: 200px;
          background-color: transparent;
          font-size: 14px;
          line-height: 18px;
          color: #343741;
          background-image: url(../../images/arrowdown.png);
          background-repeat: no-repeat;
          background-size: 14px 8px;
          backface-visibility: hidden;
          transform-style: preserve-3d;
          padding: 12px 0;
          outline: 0;
          font-weight: bold;
          border: 1px solid transparent;
          border-bottom: 1px solid #575756;
          border-radius: 0;
          box-shadow: none;
          background-position: 100% center; }
          @media screen and (max-width: 1280px) {
            .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info div.formularioSearchSingleProducto input {
              padding: 12px 18px; } }
        .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info div.formularioSearchSingleProducto select {
          width: 200px;
          background-color: transparent;
          font-size: 14px;
          line-height: 18px;
          color: #575756;
          background-image: url(../../images/arrowdown.png);
          background-repeat: no-repeat;
          background-size: 14px 8px;
          backface-visibility: hidden;
          transform-style: preserve-3d;
          padding: 12px 0;
          outline: 0;
          font-weight: bold;
          border: 1px solid transparent;
          border-bottom: 1px solid #575756;
          border-radius: 0;
          box-shadow: none;
          background-position: 100% center;
          -moz-appearance: none;
          /* Firefox */
          -webkit-appearance: none;
          /* Safari and Chrome */
          appearance: none; }
          @media screen and (max-width: 1280px) {
            .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info div.formularioSearchSingleProducto select {
              padding: 12px 18px; } }
        .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info div.formularioSearchSingleProducto button {
          width: 200px;
          background-color: transparent;
          font-size: 15px;
          line-height: 18px;
          color: #575756;
          text-transform: uppercase;
          font-weight: bold;
          background-image: url(../../images/arrowrounded.png);
          background-repeat: no-repeat;
          background-size: 18px 18px;
          backface-visibility: hidden;
          transform-style: preserve-3d;
          padding: 12px 0;
          outline: 0;
          border: 1px solid transparent;
          border-bottom: 2px solid #575756;
          border-radius: 0;
          box-shadow: none;
          background-position: 100% center; }
          .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info div.formularioSearchSingleProducto button:hover {
            animation: animation-name 1s ease infinite; }

@keyframes animation-name {
  0% {
    border-left: 1px solid #575756; }
  50% {
    border-top: 1px solid #575756; }
  100% { } }
      .single-producto .hero-top .hero-top-image .opacidad-background div.hero-top-info .indicador {
        margin: 70px 0;
        width: 18px;
        height: 18px;
        background-color: #9E0349; }

.single-producto .product-content {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  padding: 100px 0; }
  @media screen and (max-width: 500px) {
    .single-producto .product-content {
      flex-direction: column; } }
  .single-producto .product-content .producto-imagen {
    width: 50%; }
    @media screen and (max-width: 500px) {
      .single-producto .product-content .producto-imagen {
        width: 100%; } }
    .single-producto .product-content .producto-imagen .formato {
      max-width: 450px;
      margin: 0 auto;
      position: relative;
      z-index: 1; }
      .single-producto .product-content .producto-imagen .formato .imagen {
        width: 100%;
        height: 250px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: white; }
      .single-producto .product-content .producto-imagen .formato .lupa {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        .single-producto .product-content .producto-imagen .formato .lupa .imagen-lupa {
          width: 50px;
          height: 50px;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center; }
        .single-producto .product-content .producto-imagen .formato .lupa a {
          text-transform: uppercase;
          color: white;
          font-family: Flama;
          font-weight: 500; }
          .single-producto .product-content .producto-imagen .formato .lupa a:hover {
            text-decoration: underline;
            cursor: pointer; }
      .single-producto .product-content .producto-imagen .formato:hover {
        background-color: #AD0455;
        opacity: 1;
        cursor: pointer; }
        .single-producto .product-content .producto-imagen .formato:hover .imagen {
          opacity: .2; }
        .single-producto .product-content .producto-imagen .formato:hover .lupa {
          z-index: 4;
          opacity: 1 !important; }
          .single-producto .product-content .producto-imagen .formato:hover .lupa a, .single-producto .product-content .producto-imagen .formato:hover .lupa span {
            position: absolute; }
          .single-producto .product-content .producto-imagen .formato:hover .lupa span {
            top: 60%;
            color: white;
            text-decoration: underline;
            font-weight: 500; }
            .single-producto .product-content .producto-imagen .formato:hover .lupa span:hover {
              cursor: pointer; }
    .single-producto .product-content .producto-imagen .galeria {
      width: 90%;
      margin: auto;
      list-style: none;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
    .single-producto .product-content .producto-imagen .galeria li {
      margin: 5px; }
    .single-producto .product-content .producto-imagen .galeria li:first-child {
      display: none; }
    .single-producto .product-content .producto-imagen .galeria img {
      width: auto;
      height: 100px; }
  .single-producto .product-content .producto-informacion {
    width: 50%;
    display: flex;
    flex-flow: wrap; }
    @media screen and (max-width: 500px) {
      .single-producto .product-content .producto-informacion {
        width: 100%;
        text-align: center; } }
    @media screen and (max-width: 500px) {
      .single-producto .product-content .producto-informacion .ultimosdatos,
      .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas {
        flex-direction: column; } }
    .single-producto .product-content .producto-informacion .ultimosdatos span.titulo,
    .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas span.titulo {
      font-family: Flama;
      color: #343741;
      font-weight: 500;
      font-size: 18px; }
    .single-producto .product-content .producto-informacion .ultimosdatos span.info,
    .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas span.info {
      color: #343741;
      font-size: 18px; }
    .single-producto .product-content .producto-informacion .ultimosdatos span.adicionales,
    .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas span.adicionales {
      color: #AD0455;
      font-size: 14px; }
      .single-producto .product-content .producto-informacion .ultimosdatos span.adicionales a,
      .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas span.adicionales a {
        font-family: Flama;
        font-weight: 500;
        text-decoration: underline;
        color: #AD0455;
        font-size: 14px; }
    .single-producto .product-content .producto-informacion .ultimosdatos div.primer-columna,
    .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas div.primer-columna {
      width: 200px;
      margin-right: 45px; }
      @media screen and (max-width: 768px) {
        .single-producto .product-content .producto-informacion .ultimosdatos div.primer-columna,
        .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas div.primer-columna {
          margin-right: 0; } }
      @media screen and (max-width: 500px) {
        .single-producto .product-content .producto-informacion .ultimosdatos div.primer-columna,
        .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas div.primer-columna {
          width: 100%; } }
      .single-producto .product-content .producto-informacion .ultimosdatos div.primer-columna div,
      .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas div.primer-columna div {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px; }
    .single-producto .product-content .producto-informacion .ultimosdatos div.segunda-columna,
    .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas div.segunda-columna {
      width: 200px; }
      @media screen and (max-width: 500px) {
        .single-producto .product-content .producto-informacion .ultimosdatos div.segunda-columna,
        .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas div.segunda-columna {
          width: 100%; } }
      .single-producto .product-content .producto-informacion .ultimosdatos div.segunda-columna div,
      .single-producto .product-content .producto-informacion .producto-informacion-inner-columnas div.segunda-columna div {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px; }

.single-producto .posts {
  display: flex;
  justify-content: space-around; }
  .single-producto .posts .previous-post {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .single-producto .posts .previous-post a {
      font-family: Flama;
      color: #343741;
      font-size: 24px;
      text-transform: uppercase;
      letter-spacing: 1.2px; }
    .single-producto .posts .previous-post .arrow-image {
      margin-top: 12px;
      background-image: url("../../images/arrow-1.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 42px;
      height: 17px; }
  .single-producto .posts .next-post {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .single-producto .posts .next-post a {
      font-family: Flama;
      color: #343741;
      font-size: 24px;
      text-transform: uppercase;
      letter-spacing: 1.2px; }
    .single-producto .posts .next-post .arrow-image {
      background-image: url("../../images/arrow-1.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 42px;
      height: 17px;
      transform: rotate(180deg); }

.single-producto .prueba {
  /*Estilos del modal*/ }
  .single-producto .prueba .modal {
    display: none; }
  .single-producto .prueba .modal:target {
    display: block;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .single-producto .prueba .modal h3 {
    color: #fff;
    font-size: 30px;
    text-align: center;
    margin: 15px 0; }
  .single-producto .prueba .imagen {
    width: auto;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .single-producto .prueba .imagen a {
    color: #fff;
    font-size: 40px;
    text-decoration: none;
    margin: 0 10px; }
  .single-producto .prueba .imagen a:nth-child(2) {
    margin: 0;
    height: 100%;
    flex-shrink: 2; }
  .single-producto .prueba .imagen img {
    width: auto;
    height: 100%;
    max-width: 100%;
    border: 7px solid #fff;
    box-sizing: border-box; }
  .single-producto .prueba .cerrar {
    display: block;
    background: #fff;
    width: 37px;
    height: 37px;
    margin: 16px auto;
    text-align: center;
    text-decoration: none;
    font-size: 25px;
    color: #000;
    padding: 3px;
    border-radius: 50%;
    line-height: 29px; }

#productosDestacados {
  background-color: #f7f9fb;
  padding-top: 40px;
  padding-bottom: 40px; }
  #productosDestacados .contenedor {
    display: flex; }
    @media screen and (max-width: 700px) {
      #productosDestacados .contenedor {
        flex-direction: column; } }
    #productosDestacados .contenedor .bloque-izquierda {
      width: 70%; }
      @media screen and (max-width: 700px) {
        #productosDestacados .contenedor .bloque-izquierda {
          width: 100%; } }
      #productosDestacados .contenedor .bloque-izquierda .imagen-producto-destacado {
        height: 385px;
        background-color: #ffffff;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media screen and (max-width: 330px) {
          #productosDestacados .contenedor .bloque-izquierda .imagen-producto-destacado {
            margin-top: 60px; } }
        #productosDestacados .contenedor .bloque-izquierda .imagen-producto-destacado img {
          max-height: 90%; }
      #productosDestacados .contenedor .bloque-izquierda .descripcion-producto-destacado {
        border-top: 1px solid #ebebeb;
        height: 85px;
        background-color: #ffffff;
        display: flex;
        align-items: center; }
        #productosDestacados .contenedor .bloque-izquierda .descripcion-producto-destacado .logo {
          margin-right: auto; }
        #productosDestacados .contenedor .bloque-izquierda .descripcion-producto-destacado .nombre {
          margin-left: auto; }
          #productosDestacados .contenedor .bloque-izquierda .descripcion-producto-destacado .nombre .titulo {
            font-weight: 600; }
          #productosDestacados .contenedor .bloque-izquierda .descripcion-producto-destacado .nombre .especialidad {
            font-weight: 300; }
      #productosDestacados .contenedor .bloque-izquierda .contador-producto-destacado {
        height: 90px;
        background-color: #f7f9fb;
        display: flex; }
        #productosDestacados .contenedor .bloque-izquierda .contador-producto-destacado .botones-deslizar-producto-destacado {
          justify-content: flex-end;
          display: flex; }
          #productosDestacados .contenedor .bloque-izquierda .contador-producto-destacado .botones-deslizar-producto-destacado #destacadosRetroceder, #productosDestacados .contenedor .bloque-izquierda .contador-producto-destacado .botones-deslizar-producto-destacado #destacadosAvanzar {
            height: 90px;
            width: 90px;
            background-color: #d1d5d9;
            color: #ffffff;
            border: none;
            font-size: 22px;
            font-weight: bold; }
            #productosDestacados .contenedor .bloque-izquierda .contador-producto-destacado .botones-deslizar-producto-destacado #destacadosRetroceder:hover, #productosDestacados .contenedor .bloque-izquierda .contador-producto-destacado .botones-deslizar-producto-destacado #destacadosAvanzar:hover {
              cursor: pointer; }
          #productosDestacados .contenedor .bloque-izquierda .contador-producto-destacado .botones-deslizar-producto-destacado #destacadosRetroceder {
            margin-right: 5px; }
            #productosDestacados .contenedor .bloque-izquierda .contador-producto-destacado .botones-deslizar-producto-destacado #destacadosRetroceder:hover {
              cursor: pointer; }
      #productosDestacados .contenedor .bloque-izquierda .grafico-producto-destacado {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center; }
        #productosDestacados .contenedor .bloque-izquierda .grafico-producto-destacado .grafico {
          width: 66.66%;
          height: 5px;
          background-color: #ebebeb;
          display: flex;
          justify-content: space-around; }
          #productosDestacados .contenedor .bloque-izquierda .grafico-producto-destacado .grafico div {
            width: 100%;
            moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out; }
          #productosDestacados .contenedor .bloque-izquierda .grafico-producto-destacado .grafico .relleno {
            height: 5px;
            background-color: #AD0455;
            moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out; }
    #productosDestacados .contenedor .bloque-derecha {
      width: 30%;
      background: #890437;
      /* Old browsers */
      background: -moz-linear-gradient(top, #890437 2%, #a80a53 43%, #a90451 43%, #a90451 86%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #890437 2%, #a80a53 43%, #a90451 43%, #a90451 86%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #890437 2%, #a80a53 43%, #a90451 43%, #a90451 86%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#890437', endColorstr='#a90451', GradientType=0);
      /* IE6-9 */
      display: flex;
      flex-direction: column;
      padding: 50px;
      justify-content: space-between; }
      @media screen and (max-width: 700px) {
        #productosDestacados .contenedor .bloque-derecha {
          width: 100%; } }
      #productosDestacados .contenedor .bloque-derecha .titulo-vadecum {
        font-size: 42px;
        font-family: Flama;
        color: white;
        line-height: 52px; }
      #productosDestacados .contenedor .bloque-derecha .descripcion-vadecum {
        font-size: 18px;
        color: white;
        font-family: "IBM Plex Sans";
        line-height: 28px;
        padding-bottom: 55%; }
      #productosDestacados .contenedor .bloque-derecha a.readMore {
        background-color: transparent;
        border: none;
        color: white;
        font-family: Flama;
        font-weight: 600;
        font-size: 11px;
        display: flex;
        align-items: center;
        width: max-content;
        font-size: 11px;
        moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
        border-radius: 15px;
        padding: 2px 10px;
        border: 1px solid transparent;
        text-decoration: none; }
        #productosDestacados .contenedor .bloque-derecha a.readMore span {
          moz-transition: all .4s ease-in-out;
          -o-transition: all .4s ease-in-out;
          -webkit-transition: all .4s ease-in-out;
          transition: all .4s ease-in-out;
          margin-left: 40px;
          font-size: 20px; }
        #productosDestacados .contenedor .bloque-derecha a.readMore:hover {
          cursor: pointer; }

.info-cientifica-page {
  background-color: #F7F9FB; }
  .info-cientifica-page span.ubicacion {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    display: block;
    margin-top: 30px; }
    .info-cientifica-page span.ubicacion a {
      font-family: Flama;
      font-size: 11px;
      color: #868891;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500; }
      .info-cientifica-page span.ubicacion a:hover {
        text-decoration: none; }
  .info-cientifica-page .hero-top .hero-top-image {
    position: relative;
    background-position-y: center; }
    @media screen and (max-width: 767px) {
      .info-cientifica-page .hero-top .hero-top-image {
        height: 400px !important; } }
    .info-cientifica-page .hero-top .hero-top-image .opacidad-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-color: rgba(255, 255, 255, 0.6);
      background: rgba(255, 255, 255, 0.6);
      text-align: right;
      padding-right: 170px; }
      @media screen and (max-width: 767px) {
        .info-cientifica-page .hero-top .hero-top-image .opacidad-background {
          width: 100%; } }
      .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info {
        display: inline-block;
        text-align: left; }
        @media screen and (max-width: 1280px) {
          .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info {
            padding: 65px 10% 0 4%; } }
        .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
          font-family: Flama;
          margin: 50px 0;
          line-height: 64px;
          color: #9E0348;
          display: block;
          font-size: 62px; }
          @media screen and (max-width: 1280px) {
            .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
              margin: 30px 0; } }
          @media screen and (max-width: 768px) {
            .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
              font-size: 45px; } }
        .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-title {
          font-family: Flama;
          margin-bottom: 50px;
          line-height: 64px;
          color: #9E0348;
          display: block;
          font-size: 62px; }
          @media screen and (max-width: 1280px) {
            .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-title {
              margin: 30px 0; } }
          @media screen and (max-width: 768px) {
            .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-title {
              font-size: 45px; } }
        .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-sub-title {
          font-family: Flama;
          margin: 50px 0 0;
          line-height: 64px;
          color: #9E0348;
          display: block;
          font-size: 24px; }
          @media screen and (max-width: 1280px) {
            .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-sub-title {
              margin: 30px 0 0; } }
          @media screen and (max-width: 767px) {
            .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-sub-title {
              font-size: 18px;
              line-height: 24px; } }
        .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.sub-titulo {
          font-weight: 500;
          line-height: 28px;
          color: #343741;
          font-size: 15px; }
        .info-cientifica-page .hero-top .hero-top-image .opacidad-background div.hero-top-info .indicador {
          margin: 30px 0;
          width: 18px;
          height: 18px;
          background-color: #9E0349; }
  .info-cientifica-page .container .generic-block {
    padding: 30px 0 80px; }
    .info-cientifica-page .container .generic-block .top-block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .info-cientifica-page .container .generic-block .top-block .descripcion-block {
        padding-bottom: 80px; }
        .info-cientifica-page .container .generic-block .top-block .descripcion-block .titulo {
          font-family: Flama;
          color: #ad0455;
          display: block;
          font-size: 48px; }
        .info-cientifica-page .container .generic-block .top-block .descripcion-block .sub-titulo {
          color: #8c8f94;
          font-size: 30px;
          font-family: Flama; }
      .info-cientifica-page .container .generic-block .top-block .search-filter-block select {
        width: 200px;
        background-color: transparent;
        font-size: 14px;
        line-height: 18px;
        color: #575756;
        background-image: url(../../images/arrowdown.png);
        background-repeat: no-repeat;
        background-size: 14px 8px;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        padding: 12px 0;
        outline: 0;
        font-weight: bold;
        border: 1px solid transparent;
        border-bottom: 1px solid #575756;
        border-radius: 0;
        box-shadow: none;
        background-position: 100% center;
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none; }
        @media screen and (max-width: 1280px) {
          .info-cientifica-page .container .generic-block .top-block .search-filter-block select {
            padding: 12px 18px; } }
    .info-cientifica-page .container .generic-block .contenedor-materiales-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block {
      display: flex;
      flex-wrap: wrap;
      margin: -15px;
      position: relative; }
      @media screen and (max-width: 1023px) {
        .info-cientifica-page .container .generic-block .contenedor-materiales-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block {
          flex-direction: column; } }
      .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block {
        width: 50%;
        padding: 15px; }
        @media screen and (max-width: 1023px) {
          .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block {
            width: 100%; } }
        .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent {
          border: 3px solid #ebebeb;
          position: relative;
          height: 100%;
          padding: 40px; }
          .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent a.redirect, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent a.redirect, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent a.redirect, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent a.redirect {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 2; }
          .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-top, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-top, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-top, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-top, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-top {
            padding-bottom: 35px; }
            .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-top span.especialidad, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-top span.especialidad, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-top span.especialidad, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-top span.especialidad, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-top span.especialidad {
              font-family: Flama;
              color: #9e0348;
              font-size: 14px;
              border-bottom: 1px solid #9e0348;
              text-transform: uppercase;
              font-weight: bold; }
            .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-top span.date, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-top span.date, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-top span.date, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-top span.date, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-top span.date {
              color: #979ea6;
              font-weight: bold;
              padding-left: 5px; }
          .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-mid, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-mid, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-mid, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-mid, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid {
            padding-bottom: 35px; }
            .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-mid .titulo-material-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-mid .titulo-material-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-mid .titulo-material-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-mid .titulo-material-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid .titulo-material-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid .titulo-eventos-block {
              padding-bottom: 35px;
              display: block; }
              .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-mid .titulo-material-block a, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-mid .titulo-material-block a, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-mid .titulo-material-block a, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-mid .titulo-material-block a, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid .titulo-material-block a, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid .titulo-eventos-block a {
                text-decoration: none;
                color: #575756;
                font-weight: bold;
                font-size: 23px; }
            .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-mid .materia-imagen, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-mid .materia-imagen, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-mid .materia-imagen, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-mid .materia-imagen, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid .materia-imagen {
              max-width: 100%;
              height: auto;
              margin: 20px 0; }
            .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-mid .subtitulo-material-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-mid .subtitulo-material-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-mid .subtitulo-material-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-mid .subtitulo-material-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid .subtitulo-material-block, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid .subtitulo-eventos-block {
              color: #575756;
              font-size: 18px;
              display: block; }
          .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-bottom, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-bottom, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-bottom, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-bottom, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-bottom {
            padding-bottom: 15px;
            padding-top: 15px; }
            .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-bottom a, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-bottom a, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-bottom a, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-bottom a, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-bottom a {
              text-decoration: none;
              color: #343741;
              background-image: url("../../images/arrowleft.png");
              background-repeat: no-repeat;
              background-color: transparent;
              border: none;
              background-position-x: right;
              padding-right: 25px;
              background-position-y: 1px;
              text-transform: uppercase;
              font-size: 12px;
              font-family: "Rubik";
              position: absolute;
              font-weight: 500;
              width: 105px;
              bottom: 7%;
              background-size: 12px; }
              .info-cientifica-page .container .generic-block .contenedor-materiales-block .material-content-block .material-content-block-parent .material-content-block-bottom a:hover, .info-cientifica-page .container .generic-block .contenedor-eventos-block .material-content-block .material-content-block-parent .material-content-block-bottom a:hover, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .material-content-block-parent .material-content-block-bottom a:hover, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .material-content-block-bottom a:hover, .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-bottom a:hover {
                cursor: pointer; }
    .info-cientifica-page .container .generic-block .boton-cv {
      text-align: center;
      margin-top: 80px; }
      .info-cientifica-page .container .generic-block .boton-cv .center .btn, .info-cientifica-page .container .generic-block .boton-cv .center .comment-form input[type="submit"], .comment-form .info-cientifica-page .container .generic-block .boton-cv .center input[type="submit"], .info-cientifica-page .container .generic-block .boton-cv .center .search-form .search-submit, .search-form .info-cientifica-page .container .generic-block .boton-cv .center .search-submit,
      .info-cientifica-page .container .generic-block .boton-cv .center button {
        background: white;
        color: white;
        border: none;
        font-size: 10px;
        font-size: 1rem;
        background-color: transparent;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        padding: 14px 21px;
        border: 2px solid #ad0455;
        transition: color 0.3s cubic-bezier(0.16, 0.08, 0.355, 1), background 0.1s cubic-bezier(0.16, 0.08, 0.355, 1);
        display: inline-block;
        cursor: pointer;
        width: 100%;
        min-width: 200px;
        min-width: 12rem;
        outline: none;
        vertical-align: middle;
        text-align: center;
        position: relative;
        overflow: hidden; }
        @media (min-width: 400px) {
          .info-cientifica-page .container .generic-block .boton-cv .center .btn, .info-cientifica-page .container .generic-block .boton-cv .center .comment-form input[type="submit"], .comment-form .info-cientifica-page .container .generic-block .boton-cv .center input[type="submit"], .info-cientifica-page .container .generic-block .boton-cv .center .search-form .search-submit, .search-form .info-cientifica-page .container .generic-block .boton-cv .center .search-submit,
          .info-cientifica-page .container .generic-block .boton-cv .center button {
            width: auto; } }
        @media (min-width: 800px) {
          .info-cientifica-page .container .generic-block .boton-cv .center .btn, .info-cientifica-page .container .generic-block .boton-cv .center .comment-form input[type="submit"], .comment-form .info-cientifica-page .container .generic-block .boton-cv .center input[type="submit"], .info-cientifica-page .container .generic-block .boton-cv .center .search-form .search-submit, .search-form .info-cientifica-page .container .generic-block .boton-cv .center .search-submit,
          .info-cientifica-page .container .generic-block .boton-cv .center button {
            font-size: 1.1rem;
            padding: .8rem 2.8rem; } }
        .info-cientifica-page .container .generic-block .boton-cv .center .btn:hover, .info-cientifica-page .container .generic-block .boton-cv .center .comment-form input[type="submit"]:hover, .comment-form .info-cientifica-page .container .generic-block .boton-cv .center input[type="submit"]:hover, .info-cientifica-page .container .generic-block .boton-cv .center .search-form .search-submit:hover, .search-form .info-cientifica-page .container .generic-block .boton-cv .center .search-submit:hover,
        .info-cientifica-page .container .generic-block .boton-cv .center button:hover {
          background: #c60561;
          border-color: #c60561;
          -webkit-box-shadow: inset 10px 67px 68px -60x rgba(0, 0, 0, 0.35);
          -moz-box-shadow: inset 10px 67px 68px -66px rgba(0, 0, 0, 0.35);
          box-shadow: inset 10px 67px 68px -66px rgba(0, 0, 0, 0.35);
          color: #fff;
          transition: .3s; }
          .info-cientifica-page .container .generic-block .boton-cv .center .btn:hover span, .info-cientifica-page .container .generic-block .boton-cv .center .comment-form input[type="submit"]:hover span, .comment-form .info-cientifica-page .container .generic-block .boton-cv .center input[type="submit"]:hover span, .info-cientifica-page .container .generic-block .boton-cv .center .search-form .search-submit:hover span, .search-form .info-cientifica-page .container .generic-block .boton-cv .center .search-submit:hover span,
          .info-cientifica-page .container .generic-block .boton-cv .center button:hover span {
            color: white; }
        .info-cientifica-page .container .generic-block .boton-cv .center .btn span, .info-cientifica-page .container .generic-block .boton-cv .center .comment-form input[type="submit"] span, .comment-form .info-cientifica-page .container .generic-block .boton-cv .center input[type="submit"] span, .info-cientifica-page .container .generic-block .boton-cv .center .search-form .search-submit span, .search-form .info-cientifica-page .container .generic-block .boton-cv .center .search-submit span,
        .info-cientifica-page .container .generic-block .boton-cv .center button span {
          font-family: Flama;
          font-weight: 500;
          color: black; }
      .info-cientifica-page .container .generic-block .boton-cv .center .btn-border {
        background-color: transparent;
        color: #ad0455; }
      .info-cientifica-page .container .generic-block .boton-cv .center .btn-arrow {
        position: relative;
        transition: background-color 300ms ease-out; }
        .info-cientifica-page .container .generic-block .boton-cv .center .btn-arrow span {
          display: inline-block;
          position: relative;
          transition: all 300ms ease-out;
          will-change: transform; }
        .info-cientifica-page .container .generic-block .boton-cv .center .btn-arrow:hover span {
          transform: translate3d(-1rem, 0, 0); }
        .info-cientifica-page .container .generic-block .boton-cv .center .btn-arrow svg {
          position: absolute;
          width: 1.1em;
          right: 0px;
          right: 0rem;
          opacity: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: all 300ms ease-out;
          will-change: right, opacity; }
          .info-cientifica-page .container .generic-block .boton-cv .center .btn-arrow svg * {
            stroke-width: 5;
            stroke-color: transparent; }
        .info-cientifica-page .container .generic-block .boton-cv .center .btn-arrow:hover svg {
          opacity: 1;
          right: -2rem; }
    .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block {
      width: 33.33%; }
      @media screen and (max-width: 1023px) {
        .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block {
          width: 100%; } }
      .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent {
        position: relative;
        height: 100%; }
        .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid .titulo-eventos-block {
          font-size: 30px;
          font-family: Flama;
          font-weight: 500; }
        .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid .subtitulo-eventos-block {
          font-family: Flama;
          font-size: 24px;
          font-weight: 400; }
        .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-mid .evento-imagen {
          max-width: 100%;
          height: auto;
          margin: 20px 0; }
        .info-cientifica-page .container .generic-block .contenedor-eventos-block .eventos-content-block .eventos-content-block-parent .eventos-content-block-bottom {
          position: absolute;
          bottom: 5%; }
  .info-cientifica-page .materiales-hide,
  .info-cientifica-page .eventos-hide {
    display: none; }
  .info-cientifica-page .numeric-pagination {
    display: flex;
    padding: 80px 0;
    align-items: center;
    justify-content: center; }
    .info-cientifica-page .numeric-pagination span {
      font-size: 24px;
      color: white;
      width: 50px;
      height: 50px;
      background-color: #ad0455;
      margin: 0 5px;
      align-items: center;
      display: flex;
      justify-content: center; }
      .info-cientifica-page .numeric-pagination span:hover {
        cursor: pointer; }
    .info-cientifica-page .numeric-pagination a {
      color: #343741;
      font-size: 24px;
      width: 50px;
      height: 50px;
      margin: 0 5px;
      align-items: center;
      display: flex;
      justify-content: center; }
      .info-cientifica-page .numeric-pagination a:hover {
        cursor: pointer; }

.trazabilidad-page .hero-top .hero-top-image {
  position: relative;
  background-position-y: center; }
  .trazabilidad-page .hero-top .hero-top-image .opacidad-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.6);
    text-align: right;
    padding-right: 345px; }
    @media screen and (max-width: 767px) {
      .trazabilidad-page .hero-top .hero-top-image .opacidad-background {
        width: 100%; } }
    .trazabilidad-page .hero-top .hero-top-image .opacidad-background div.hero-top-info {
      display: inline-block;
      text-align: left;
      margin-top: 280px; }
      @media screen and (max-width: 1100px) {
        .trazabilidad-page .hero-top .hero-top-image .opacidad-background div.hero-top-info {
          padding: 65px 10% 0 4%; } }
      .trazabilidad-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.ubicacion {
        font-family: Flama;
        font-size: 11px;
        color: #868891;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 500; }
      .trazabilidad-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
        font-family: Flama;
        margin: 50px 0;
        line-height: 64px;
        color: #9E0348;
        display: block;
        font-size: 62px; }
        @media screen and (max-width: 1280px) {
          .trazabilidad-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
            margin: 30px 0; } }
        @media screen and (max-width: 768px) {
          .trazabilidad-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
            font-size: 45px; } }
      .trazabilidad-page .hero-top .hero-top-image .opacidad-background div.hero-top-info span.sub-titulo {
        font-weight: 500;
        line-height: 28px;
        color: #343741;
        font-size: 15px; }
      .trazabilidad-page .hero-top .hero-top-image .opacidad-background div.hero-top-info .indicador {
        margin: 70px 0;
        width: 18px;
        height: 18px;
        background-color: #9E0349; }

.trazabilidad-page .entrada {
  display: flex;
  border-bottom: 1px solid #83847f; }
  @media screen and (max-width: 1023px) {
    .trazabilidad-page .entrada {
      flex-direction: column; } }
  .trazabilidad-page .entrada .entrada-info {
    align-items: center;
    padding: 130px 0;
    width: 50%;
    display: flex; }
    @media screen and (max-width: 1023px) {
      .trazabilidad-page .entrada .entrada-info {
        padding: 55px 0; } }
    @media screen and (max-width: 900px) {
      .trazabilidad-page .entrada .entrada-info {
        width: 100%;
        flex-direction: column; }
        .trazabilidad-page .entrada .entrada-info div {
          flex-direction: column; } }
    .trazabilidad-page .entrada .entrada-info .indicador {
      width: 18px;
      height: 18px;
      background-color: #9E0349; }
      @media screen and (max-width: 1023px) {
        .trazabilidad-page .entrada .entrada-info .indicador {
          margin-right: auto; } }
    .trazabilidad-page .entrada .entrada-info .title-span {
      font-size: 32px;
      color: #343741;
      font-family: Flama;
      margin-left: 60px; }
      @media screen and (max-width: 1023px) {
        .trazabilidad-page .entrada .entrada-info .title-span {
          text-align: center; } }
      @media screen and (max-width: 900px) {
        .trazabilidad-page .entrada .entrada-info .title-span {
          margin: 0; } }
    .trazabilidad-page .entrada .entrada-info .titulo {
      display: block;
      margin-top: 70px;
      margin-bottom: 50px;
      font-family: Flama;
      font-size: 48px;
      font-weight: 500;
      line-height: 58px;
      color: #343741; }
    .trazabilidad-page .entrada .entrada-info .sub-titulo {
      line-height: 28px;
      font-size: 15px;
      color: #343741;
      letter-spacing: 1px;
      display: block; }

.trazabilidad-page .entrada:last-child {
  border: none; }

.trazabilidad-page .first-entrada-top {
  background-color: #F7F9FB;
  display: flex; }
  .trazabilidad-page .first-entrada-top .contenedor-first-entrada {
    display: flex; }
    @media screen and (max-width: 1023px) {
      .trazabilidad-page .first-entrada-top .contenedor-first-entrada {
        flex-direction: column;
        padding-bottom: 65px; } }
    .trazabilidad-page .first-entrada-top .contenedor-first-entrada .hero-bottom-info {
      width: 50%;
      padding: 100px 0; }
      @media screen and (max-width: 1280px) {
        .trazabilidad-page .first-entrada-top .contenedor-first-entrada .hero-bottom-info {
          padding: 65px 0; } }
      @media screen and (max-width: 1023px) {
        .trazabilidad-page .first-entrada-top .contenedor-first-entrada .hero-bottom-info {
          padding: 65px 0 0;
          width: 100%; } }
      .trazabilidad-page .first-entrada-top .contenedor-first-entrada .hero-bottom-info .indicador {
        width: 18px;
        height: 18px;
        background-color: #9E0349; }
      .trazabilidad-page .first-entrada-top .contenedor-first-entrada .hero-bottom-info span.titulo {
        font-family: Flama;
        margin: 70px 0;
        display: block;
        font-size: 48px;
        color: #343741; }
        @media screen and (max-width: 1280px) {
          .trazabilidad-page .first-entrada-top .contenedor-first-entrada .hero-bottom-info span.titulo {
            margin: 35px 0; } }
      .trazabilidad-page .first-entrada-top .contenedor-first-entrada .hero-bottom-info span.sub-titulo {
        line-height: 28px;
        padding-top: 65px;
        font-size: 15px;
        color: #343741;
        letter-spacing: 1px;
        display: block;
        margin-bottom: 80px; }
      .trazabilidad-page .first-entrada-top .contenedor-first-entrada .hero-bottom-info span.info {
        color: #979EA6;
        line-height: 24px;
        font-size: 12px; }
    .trazabilidad-page .first-entrada-top .contenedor-first-entrada .image-hero {
      width: 50%;
      background-position: center;
      height: 300px;
      margin: auto;
      background-size: contain;
      background-repeat: no-repeat; }

.trazabilidad-page .hero-bottom {
  background-color: #F7F9FB;
  display: flex;
  justify-content: center;
  margin-top: 65px; }
  @media screen and (max-width: 1023px) {
    .trazabilidad-page .hero-bottom {
      flex-direction: column;
      padding-bottom: 35px; } }
  .trazabilidad-page .hero-bottom .hero-bottom-info {
    display: flex;
    width: 50%;
    padding: 0px 70px;
    margin: auto; }
    @media screen and (max-width: 1023px) {
      .trazabilidad-page .hero-bottom .hero-bottom-info {
        width: 100%;
        align-items: center; } }
    .trazabilidad-page .hero-bottom .hero-bottom-info .segunda-columna {
      width: 100%;
      padding-left: 25px; }
      .trazabilidad-page .hero-bottom .hero-bottom-info .segunda-columna .indicador {
        width: 18px;
        height: 18px;
        background-color: #9E0349; }
      .trazabilidad-page .hero-bottom .hero-bottom-info .segunda-columna span.titulo {
        font-family: Flama;
        margin-top: -15px;
        margin-bottom: 25px;
        display: block;
        font-size: 36px;
        font-weight: 500;
        color: #9e0348; }
        @media screen and (max-width: 1280px) {
          .trazabilidad-page .hero-bottom .hero-bottom-info .segunda-columna span.titulo {
            margin: 35px 0; } }
      .trazabilidad-page .hero-bottom .hero-bottom-info .segunda-columna span.sub-titulo {
        line-height: 28px;
        font-size: 15px;
        color: #343741;
        letter-spacing: 1px;
        display: block; }
      .trazabilidad-page .hero-bottom .hero-bottom-info .segunda-columna span.info {
        color: #979EA6;
        line-height: 24px;
        font-size: 12px; }
  .trazabilidad-page .hero-bottom .image-hero {
    width: 50%;
    height: 600px;
    background-position-x: 100%;
    background-repeat: no-repeat; }
    @media screen and (max-width: 1023px) {
      .trazabilidad-page .hero-bottom .image-hero {
        width: 100%;
        padding: 40px 0;
        height: 720px; } }
    @media screen and (max-width: 500px) {
      .trazabilidad-page .hero-bottom .image-hero {
        height: 375px; } }

.front-page {
  background-color: #f7f9fb; }
  .front-page .contenidoDestacado {
    padding-top: 50px;
    display: flex;
    margin: 0 -15px; }
    @media screen and (max-width: 1023px) {
      .front-page .contenidoDestacado {
        flex-direction: column; } }
    .front-page .contenidoDestacado .block-container {
      padding: 15px;
      width: 33.33%;
      position: relative; }
      @media screen and (max-width: 1023px) {
        .front-page .contenidoDestacado .block-container {
          width: 100%; } }
      .front-page .contenidoDestacado .block-container .block-destacado {
        border: 1px solid #E8E9E9;
        height: 100%;
        position: relative;
        z-index: 1; }
        .front-page .contenidoDestacado .block-container .block-destacado a.redirect {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 2; }
          .front-page .contenidoDestacado .block-container .block-destacado a.redirect:hover {
            cursor: pointer; }
        .front-page .contenidoDestacado .block-container .block-destacado .top-image-destacado-first {
          background-image: url("../../images/Untitled-2.jpg");
          background-size: cover;
          width: 100%;
          height: 250px; }
          @media screen and (max-width: 1023px) {
            .front-page .contenidoDestacado .block-container .block-destacado .top-image-destacado-first {
              display: none; } }
        .front-page .contenidoDestacado .block-container .block-destacado .top-image-destacado-second {
          background-image: url("../../images/Untitled-3.jpg");
          background-size: cover;
          width: 100%;
          height: 250px; }
          @media screen and (max-width: 1023px) {
            .front-page .contenidoDestacado .block-container .block-destacado .top-image-destacado-second {
              display: none; } }
        .front-page .contenidoDestacado .block-container .block-destacado .top-image-destacado-third {
          background-image: url("../../images/Untitled-4.jpg");
          background-size: cover;
          width: 100%;
          height: 250px; }
          @media screen and (max-width: 1023px) {
            .front-page .contenidoDestacado .block-container .block-destacado .top-image-destacado-third {
              display: none; } }
        .front-page .contenidoDestacado .block-container .block-destacado .mid-content-info {
          padding: 30px 40px; }
          .front-page .contenidoDestacado .block-container .block-destacado .mid-content-info span.contador {
            color: #343741;
            font-size: 30px;
            display: block;
            font-family: Flama; }
          .front-page .contenidoDestacado .block-container .block-destacado .mid-content-info span.titulo-destacado {
            display: block;
            color: #343741;
            font-size: 30px;
            font-family: Flama; }
          .front-page .contenidoDestacado .block-container .block-destacado .mid-content-info span.info-destacado {
            padding-bottom: 40px;
            display: block;
            color: #8C8F94;
            font-family: "IBM Plex Sans";
            font-size: 15px; }
          .front-page .contenidoDestacado .block-container .block-destacado .mid-content-info a {
            font-family: 'Rubik';
            font-weight: 500; }
        .front-page .contenidoDestacado .block-container .block-destacado span.leer-mas {
          text-decoration: none;
          color: #343741;
          background-image: url("../../images/arrowleft.png");
          background-repeat: no-repeat;
          background-color: transparent;
          border: none;
          background-position-x: right;
          padding-right: 25px;
          background-position-y: 1px;
          text-transform: uppercase;
          font-size: 12px;
          font-family: "Rubik";
          position: absolute;
          width: 105px;
          font-weight: 500;
          bottom: 7%;
          background-size: 12px; }
          .front-page .contenidoDestacado .block-container .block-destacado span.leer-mas:hover {
            cursor: pointer; }

/* wpadminbar */
/*------------------------------------------------------------------------------------------------------------------*/
#wpadminbar {
  display: none !important; }

.scrollup {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: none;
  text-indent: -99999px;
  background: url(../images/arrow-orange-hi.png) no-repeat transparent;
  opacity: 0.8;
  outline: none; }
  .scrollup:hover {
    opacity: 1; }

@media screen and (max-width: 1023px) {
  .scrollup {
    visibility: hidden; } }

div.bottom-footer div.div-newsletter {
  margin-left: 0;
  width: 80%; }

div.bottom-footer div.div-newsletter .newsletter div {
  margin: 0; }

.single-material span.ubicacion {
  font-family: Flama;
  font-size: 11px;
  color: #868891;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  padding: 10px 0 50px;
  display: block; }
  .single-material span.ubicacion a {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500; }
    .single-material span.ubicacion a:hover {
      text-decoration: none; }

.single-material .hero-top .hero-top-image {
  position: relative;
  background-position-y: center; }
  .single-material .hero-top .hero-top-image .opacidad-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.6);
    text-align: right;
    padding-right: 170px; }
    @media screen and (max-width: 767px) {
      .single-material .hero-top .hero-top-image .opacidad-background {
        width: 100%; } }
    .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info {
      display: inline-block;
      text-align: left; }
      @media screen and (max-width: 1280px) {
        .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info {
          padding: 65px 10% 0 4%; } }
      .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
        font-family: Flama;
        margin: 50px 0;
        line-height: 64px;
        color: #9E0348;
        display: block;
        font-size: 62px; }
        @media screen and (max-width: 1280px) {
          .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
            margin: 30px 0; } }
        @media screen and (max-width: 768px) {
          .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
            font-size: 45px; } }
      .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-title {
        font-family: Flama;
        margin-bottom: 50px;
        line-height: 64px;
        color: #9E0348;
        display: block;
        font-size: 62px; }
        @media screen and (max-width: 1280px) {
          .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-title {
            margin: 30px 0; } }
        @media screen and (max-width: 768px) {
          .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-title {
            font-size: 45px; } }
      .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-sub-title {
        font-family: Flama;
        margin: 50px 0 0;
        line-height: 64px;
        color: #9E0348;
        display: block;
        font-size: 24px; }
        @media screen and (max-width: 1280px) {
          .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-sub-title {
            margin: 30px 0 0; } }
        @media screen and (max-width: 768px) {
          .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-sub-title {
            font-size: 18px; } }
      .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info span.sub-titulo {
        font-weight: 500;
        line-height: 28px;
        color: #343741;
        font-size: 15px; }
      .single-material .hero-top .hero-top-image .opacidad-background div.hero-top-info .indicador {
        margin: 30px 0;
        width: 18px;
        height: 18px;
        background-color: #9E0349; }

.single-material .contenido-material .back-button {
  margin-bottom: 80px;
  text-align: right; }
  .single-material .contenido-material .back-button a {
    color: #868891;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
    font-weight: 500; }
    .single-material .contenido-material .back-button a:hover {
      text-decoration: none; }

.single-material .contenido-material .indicador {
  width: 18px;
  height: 18px;
  background-color: #9E0349; }

.single-material .contenido-material .especialidad-material {
  font-weight: bold;
  color: #ad0455;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: underline;
  margin: 50px 0;
  display: block; }

.single-material .contenido-material h2 {
  font-size: 50px;
  color: #343741;
  font-weight: 500;
  width: 80%; }

.single-material .contenido-material .info-material {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  border-bottom: 2px solid #ebebeb;
  padding-bottom: 30px;
  font-weight: bold; }

.single-material .contenido-material .imagen-texto {
  display: flex;
  margin: 20px 0;
  justify-content: space-between; }
  @media screen and (max-width: 1023px) {
    .single-material .contenido-material .imagen-texto {
      flex-direction: column; } }
  .single-material .contenido-material .imagen-texto .imagen-material {
    flex-grow: 0;
    flex-shrink: 0;
    width: 48%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px; }
    @media screen and (max-width: 1023px) {
      .single-material .contenido-material .imagen-texto .imagen-material {
        width: auto; } }
    .single-material .contenido-material .imagen-texto .imagen-material img {
      height: auto;
      margin: 0 auto;
      width: 100%; }
    .single-material .contenido-material .imagen-texto .imagen-material .formato {
      position: relative;
      z-index: 1; }
      .single-material .contenido-material .imagen-texto .imagen-material .formato .lupa {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        .single-material .contenido-material .imagen-texto .imagen-material .formato .lupa .imagen-lupa {
          width: 50px;
          height: 50px;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center; }
        .single-material .contenido-material .imagen-texto .imagen-material .formato .lupa a {
          text-transform: uppercase;
          color: white;
          font-family: Flama;
          font-weight: 500; }
          .single-material .contenido-material .imagen-texto .imagen-material .formato .lupa a:hover {
            text-decoration: underline;
            cursor: pointer; }
      .single-material .contenido-material .imagen-texto .imagen-material .formato:hover {
        background-color: #AD0455;
        opacity: 1;
        cursor: pointer; }
        .single-material .contenido-material .imagen-texto .imagen-material .formato:hover .imagen {
          opacity: .2; }
        .single-material .contenido-material .imagen-texto .imagen-material .formato:hover .lupa {
          z-index: 4;
          opacity: 1 !important; }
          .single-material .contenido-material .imagen-texto .imagen-material .formato:hover .lupa a, .single-material .contenido-material .imagen-texto .imagen-material .formato:hover .lupa span {
            position: absolute; }
          .single-material .contenido-material .imagen-texto .imagen-material .formato:hover .lupa span {
            top: 53%;
            color: white;
            text-decoration: underline;
            font-weight: 500; }
            .single-material .contenido-material .imagen-texto .imagen-material .formato:hover .lupa span:hover {
              cursor: pointer; }
    .single-material .contenido-material .imagen-texto .imagen-material .galeria-imagenes-material-de-lectura {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 10px 0; }
      .single-material .contenido-material .imagen-texto .imagen-material .galeria-imagenes-material-de-lectura li span {
        font-size: 18px;
        color: black;
        width: 40px;
        height: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        transition: .3s; }
        .single-material .contenido-material .imagen-texto .imagen-material .galeria-imagenes-material-de-lectura li span:hover {
          cursor: pointer;
          background-color: #ad0455;
          color: white; }
      .single-material .contenido-material .imagen-texto .imagen-material .galeria-imagenes-material-de-lectura li span.relleno {
        background-color: #ad0455;
        color: white; }
    .single-material .contenido-material .imagen-texto .imagen-material span.info {
      color: #343741;
      font-size: 18px;
      font-family: "IBM Plex Sans";
      font-weight: bold; }
    .single-material .contenido-material .imagen-texto .imagen-material span.info-important {
      font-family: "IBM Plex Sans";
      font-size: 18px;
      font-weight: bold;
      color: #9e0348; }
  .single-material .contenido-material .imagen-texto .texto-material {
    width: 48%;
    text-align: left; }
    @media screen and (max-width: 1023px) {
      .single-material .contenido-material .imagen-texto .texto-material {
        width: 80%; } }
    @media screen and (max-width: 767px) {
      .single-material .contenido-material .imagen-texto .texto-material {
        margin: 0 25px; } }
    .single-material .contenido-material .imagen-texto .texto-material pre {
      color: #343741;
      font-size: 15px;
      font-family: "IBM Plex Sans";
      overflow: visible; }

.single-material .related-post-container {
  display: flex;
  justify-content: space-between;
  margin: 80px auto; }
  @media screen and (max-width: 767px) {
    .single-material .related-post-container {
      flex-direction: column;
      align-items: center; } }
  .single-material .related-post-container a {
    width: 32%;
    text-decoration: none;
    color: inherit; }
    @media screen and (max-width: 767px) {
      .single-material .related-post-container a {
        margin: 15px 0;
        width: 100%;
        cursor: pointer; } }
    .single-material .related-post-container a .block-related-post {
      width: 100%;
      height: 440px;
      border: 1px solid #EEE;
      word-break: break-word;
      overflow: hidden;
      position: relative;
      padding-bottom: 20px; }
      .single-material .related-post-container a .block-related-post span {
        display: block;
        padding: 0 15px; }
      .single-material .related-post-container a .block-related-post .title-related-post {
        font-size: 24px;
        color: #22252D;
        font-weight: 700;
        line-height: 25px;
        margin: 15px 0; }
      .single-material .related-post-container a .block-related-post .especialidad-related-post {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: #ad0455;
        color: white;
        padding: 5px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px; }
      .single-material .related-post-container a .block-related-post .img-related-post {
        width: 100%;
        height: 250px;
        object-fit: cover; }

.single-material .prueba {
  /*Estilos del modal*/ }
  .single-material .prueba .modal {
    display: none; }
  .single-material .prueba .modal:target {
    overflow-y: scroll;
    display: block;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .single-material .prueba .modal h3 {
    color: #fff;
    font-size: 30px;
    text-align: center;
    margin: 15px 0; }
  .single-material .prueba .imagen {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center; }
  .single-material .prueba .imagen a {
    color: #fff;
    font-size: 40px;
    text-decoration: none;
    margin: 0 10px; }
  .single-material .prueba .imagen a:nth-child(2) {
    margin: 0;
    height: 100%;
    flex-shrink: 2; }
  .single-material .prueba .imagen img {
    width: auto;
    height: 100%;
    max-width: 100%;
    border: 7px solid #fff;
    box-sizing: border-box; }
  .single-material .prueba .cerrar {
    display: block;
    background: #fff;
    width: 37px;
    height: 37px;
    margin: 16px auto;
    text-align: center;
    text-decoration: none;
    font-size: 25px;
    color: #000;
    padding: 3px;
    border-radius: 50%;
    line-height: 29px; }

.single-eventos span.ubicacion {
  font-family: Flama;
  font-size: 11px;
  color: #868891;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  padding: 10px 0 50px;
  display: block; }
  .single-eventos span.ubicacion a {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500; }
    .single-eventos span.ubicacion a:hover {
      text-decoration: none; }

.single-eventos .hero-top {
  margin-bottom: 40px; }
  .single-eventos .hero-top .hero-top-image {
    position: relative;
    background-position-y: center; }
    .single-eventos .hero-top .hero-top-image .opacidad-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-color: rgba(255, 255, 255, 0.6);
      background: rgba(255, 255, 255, 0.6);
      text-align: right;
      padding-right: 350px; }
      @media screen and (max-width: 767px) {
        .single-eventos .hero-top .hero-top-image .opacidad-background {
          width: 100%; } }
      .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info {
        display: inline-block;
        text-align: left; }
        @media screen and (max-width: 1280px) {
          .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info {
            padding: 65px 10% 0 4%; } }
        .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
          font-family: Flama;
          margin: 50px 0;
          line-height: 64px;
          color: #9E0348;
          display: block;
          font-size: 62px; }
          @media screen and (max-width: 1280px) {
            .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
              margin: 30px 0; } }
          @media screen and (max-width: 768px) {
            .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.titulo {
              font-size: 45px; } }
        .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-title {
          font-family: Flama;
          margin-bottom: 50px;
          line-height: 64px;
          color: #9E0348;
          display: block;
          font-size: 62px; }
          @media screen and (max-width: 1280px) {
            .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-title {
              margin: 30px 0; } }
          @media screen and (max-width: 768px) {
            .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-title {
              font-size: 45px; } }
        .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-sub-title {
          font-family: Flama;
          margin: 50px 0 0;
          line-height: 64px;
          color: #9E0348;
          display: block;
          font-size: 24px; }
          @media screen and (max-width: 1280px) {
            .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-sub-title {
              margin: 30px 0 0; } }
          @media screen and (max-width: 768px) {
            .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.second-sub-title {
              font-size: 18px; } }
        .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.sub-titulo {
          font-weight: 500;
          line-height: 28px;
          color: #343741;
          font-size: 15px; }
          @media screen and (max-width: 767px) {
            .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info span.sub-titulo {
              line-height: 24px; } }
        .single-eventos .hero-top .hero-top-image .opacidad-background div.hero-top-info .indicador {
          margin: 30px 0;
          width: 18px;
          height: 18px;
          background-color: #9E0349; }

.single-eventos .contenido-material .contenedor-divs {
  display: flex; }
  @media screen and (max-width: 1023px) {
    .single-eventos .contenido-material .contenedor-divs {
      flex-direction: column; } }
  .single-eventos .contenido-material .contenedor-divs .info-evento {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .single-eventos .contenido-material .contenedor-divs .info-evento .fecha-ubicacion {
      color: #343741;
      font-size: 24px; }
    .single-eventos .contenido-material .contenedor-divs .info-evento span.especialidad-material {
      font-weight: bold;
      color: #ad0455;
      font-size: 15px;
      margin-top: 20px;
      text-transform: uppercase;
      text-decoration: underline;
      display: block; }
    .single-eventos .contenido-material .contenedor-divs .info-evento span.titulo-evento {
      font-size: 40px;
      font-weight: 500;
      font-family: Flama; }
    .single-eventos .contenido-material .contenedor-divs .info-evento span.info {
      margin-top: 20%;
      color: #343741;
      font-size: 18px;
      font-family: "IBM Plex Sans";
      font-weight: bold; }
    .single-eventos .contenido-material .contenedor-divs .info-evento span.info-important {
      font-family: "IBM Plex Sans";
      font-size: 18px;
      font-weight: bold;
      color: #9e0348; }
      @media screen and (max-width: 1023px) {
        .single-eventos .contenido-material .contenedor-divs .info-evento span.info-important {
          margin-bottom: 50px; } }
  .single-eventos .contenido-material .contenedor-divs .imagen-material {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
    display: flex;
    flex-direction: column; }
    .single-eventos .contenido-material .contenedor-divs .imagen-material #map {
      width: 100%;
      height: 100%; }
    @media screen and (max-width: 1023px) {
      .single-eventos .contenido-material .contenedor-divs .imagen-material {
        width: auto; } }
    .single-eventos .contenido-material .contenedor-divs .imagen-material img {
      height: auto;
      margin: 0 auto;
      width: 100%; }
    .single-eventos .contenido-material .contenedor-divs .imagen-material .formato {
      position: relative;
      z-index: 1; }
      .single-eventos .contenido-material .contenedor-divs .imagen-material .formato .lupa {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        .single-eventos .contenido-material .contenedor-divs .imagen-material .formato .lupa .imagen-lupa {
          width: 50px;
          height: 50px;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center; }
        .single-eventos .contenido-material .contenedor-divs .imagen-material .formato .lupa a {
          text-transform: uppercase;
          color: white;
          font-family: Flama;
          font-weight: 500; }
          .single-eventos .contenido-material .contenedor-divs .imagen-material .formato .lupa a:hover {
            text-decoration: underline;
            cursor: pointer; }
      .single-eventos .contenido-material .contenedor-divs .imagen-material .formato:hover {
        background-color: #AD0455;
        opacity: 1;
        cursor: pointer; }
        .single-eventos .contenido-material .contenedor-divs .imagen-material .formato:hover .imagen {
          opacity: .2; }
        .single-eventos .contenido-material .contenedor-divs .imagen-material .formato:hover .lupa {
          z-index: 4;
          opacity: 1 !important; }
          .single-eventos .contenido-material .contenedor-divs .imagen-material .formato:hover .lupa a, .single-eventos .contenido-material .contenedor-divs .imagen-material .formato:hover .lupa span {
            position: absolute; }
          .single-eventos .contenido-material .contenedor-divs .imagen-material .formato:hover .lupa span {
            top: 53%;
            color: white;
            text-decoration: underline;
            font-weight: 500; }
            .single-eventos .contenido-material .contenedor-divs .imagen-material .formato:hover .lupa span:hover {
              cursor: pointer; }
    .single-eventos .contenido-material .contenedor-divs .imagen-material .galeria-imagenes-material-de-lectura {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 10px 0; }
      .single-eventos .contenido-material .contenedor-divs .imagen-material .galeria-imagenes-material-de-lectura li span {
        font-size: 18px;
        color: black;
        width: 40px;
        height: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        transition: .3s; }
        .single-eventos .contenido-material .contenedor-divs .imagen-material .galeria-imagenes-material-de-lectura li span:hover {
          cursor: pointer;
          background-color: #ad0455;
          color: white; }
      .single-eventos .contenido-material .contenedor-divs .imagen-material .galeria-imagenes-material-de-lectura li span.relleno {
        background-color: #ad0455;
        color: white; }
    .single-eventos .contenido-material .contenedor-divs .imagen-material span.info {
      color: #343741;
      font-size: 18px;
      font-family: "IBM Plex Sans";
      font-weight: bold; }
    .single-eventos .contenido-material .contenedor-divs .imagen-material span.info-important {
      font-family: "IBM Plex Sans";
      font-size: 18px;
      font-weight: bold;
      color: #9e0348; }

.single-eventos .contenido-material .back-button {
  text-align: right; }
  .single-eventos .contenido-material .back-button a {
    color: #868891;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
    font-weight: 500; }
    .single-eventos .contenido-material .back-button a:hover {
      text-decoration: none; }

.single-eventos .contenido-material .indicador {
  width: 18px;
  height: 18px;
  background-color: #9E0349; }

#materiales-destacados-home .contenido-destacado {
  padding-top: 65px;
  padding-bottom: 65px;
  display: flex;
  margin: -15px; }
  @media screen and (max-width: 1023px) {
    #materiales-destacados-home .contenido-destacado {
      flex-direction: column; } }
  #materiales-destacados-home .contenido-destacado div.titulo-materiales-destacados {
    width: 15%;
    margin: auto 15px;
    text-align: center; }
    #materiales-destacados-home .contenido-destacado div.titulo-materiales-destacados a {
      text-decoration: none; }
    #materiales-destacados-home .contenido-destacado div.titulo-materiales-destacados span {
      color: #343741;
      font-size: 30px; }
    @media screen and (max-width: 1023px) {
      #materiales-destacados-home .contenido-destacado div.titulo-materiales-destacados {
        width: auto; } }
  #materiales-destacados-home .contenido-destacado .block-container {
    padding: 15px;
    width: 28.33%;
    position: relative; }
    @media screen and (max-width: 1023px) {
      #materiales-destacados-home .contenido-destacado .block-container {
        width: 100%; } }
    #materiales-destacados-home .contenido-destacado .block-container .block-destacado {
      border: 1px solid #E8E9E9;
      height: 100%;
      position: relative;
      z-index: 1; }
      #materiales-destacados-home .contenido-destacado .block-container .block-destacado a.redirect {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 2; }
        #materiales-destacados-home .contenido-destacado .block-container .block-destacado a.redirect:hover {
          cursor: pointer; }
      #materiales-destacados-home .contenido-destacado .block-container .block-destacado div.imagen-top {
        background-size: cover;
        height: 250px;
        background-position: center; }
      #materiales-destacados-home .contenido-destacado .block-container .block-destacado div.mid-content-info {
        border-top: 1px solid #E8E9E9;
        padding: 15px; }
        #materiales-destacados-home .contenido-destacado .block-container .block-destacado div.mid-content-info span.titulo-destacado {
          color: #141414;
          font-weight: 500;
          font-size: 14px;
          font-family: "IBM Plex Sans"; }

#materiales-destacados-home .contenedor-noticias-destacados {
  margin-bottom: 105px; }
