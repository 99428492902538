.front-page {
  background-color: #f7f9fb;

  .contenidoDestacado {
    padding-top: 50px;
    display: flex;
    margin: 0 -15px;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }

    .block-container {
      padding: 15px;
      width: 33.33%;
      position: relative;

      @media screen and (max-width: 1023px) {
        width: 100%;
      }

      .block-destacado {
        border: 1px solid #E8E9E9;
        height: 100%;
        position: relative;
        z-index: 1;

        a.redirect {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 2;

          &:hover {
            cursor: pointer;
          }

        }

        .top-image-destacado-first {
          background-image: url("../../images/Untitled-2.jpg");
          background-size: cover;
          width: 100%;
          height: 250px;

          @media screen and (max-width: 1023px) {
            display: none;
          }

        }

        .top-image-destacado-second {
          background-image: url("../../images/Untitled-3.jpg");
          background-size: cover;
          width: 100%;
          height: 250px;

          @media screen and (max-width: 1023px) {
            display: none;
          }
        }

        .top-image-destacado-third {
          background-image: url("../../images/Untitled-4.jpg");
          background-size: cover;
          width: 100%;
          height: 250px;

          @media screen and (max-width: 1023px) {
            display: none;
          }
        }


        .mid-content-info {
          padding: 30px 40px;

          span.contador {
            color: #343741;
            font-size: 30px;
            display: block;
            font-family: Flama;

          }

          span.titulo-destacado {
            display: block;
            color: #343741;
            font-size: 30px;
            font-family: Flama;

          }

          span.info-destacado {
            padding-bottom: 40px;
            display: block;
            color: #8C8F94;
            font-family: "IBM Plex Sans";
            font-size: 15px;

          }

          a {
            font-family: 'Rubik';
            font-weight: 500;
          }


        }

        span.leer-mas {
          text-decoration: none;
          color: #343741;
          background-image: url("../../images/arrowleft.png");
          background-repeat: no-repeat;
          background-color: transparent;
          border: none;
          background-position-x: right;
          padding-right: 25px;
          background-position-y: 1px;
          text-transform: uppercase;
          font-size: 12px;
          font-family: "Rubik";
          position: absolute;
          width: 105px;
          font-weight: 500;
          bottom: 7%;
          background-size: 12px;

          &:hover {
            cursor: pointer;
          }
        }


      }


    }


  }


}

/* wpadminbar */
/*------------------------------------------------------------------------------------------------------------------*/
#wpadminbar {
  display: none !important;
}

.scrollup {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: none;
  text-indent: -99999px;
  background: url(../images/arrow-orange-hi.png) no-repeat transparent;
  opacity: 0.8;
  outline: none;

  &:hover {
    opacity: 1;
  }

}


@media screen and (max-width: 1023px) {
  .scrollup {
    visibility: hidden;
  }
}


div.bottom-footer div.div-newsletter {
  margin-left: 0;
  width: 80%;
}

div.bottom-footer div.div-newsletter .newsletter div {
  margin: 0;
}


//.n2-ss-slider-2.n2-ow {
//  .titulo {
//    font-family: Flama;
//    margin: 30px 0;
//    line-height: 64px;
//    color: #9e0348;
//    display: block;
//    font-size: 62px;
//    margin-bottom: -75px !important;
//  }
//
//  .sub-titulo {
//    font-family: Flama;
//    margin: 30px 0;
//    line-height: 64px;
//    color: #9e0348;
//    display: block;
//    font-size: 62px;
//  }
//}
