.info-cientifica-page {
  background-color: #F7F9FB;

  span.ubicacion {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    a{
      font-family: Flama;
      font-size: 11px;
      color: #868891;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
      &:hover{
        text-decoration: none;
      }
    }
    display: block;
    margin-top:30px;
  }

  .hero-top {

    .hero-top-image {
      position: relative;
      background-position-y: center;

      @media screen and (max-width: 767px) {
        height: 400px !important;
      }


      .opacidad-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.6);
        text-align: right;
        padding-right: 170px;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        div.hero-top-info {
          //padding: 100px 13% 0 28%;
          display: inline-block;
          text-align: left;
          @media screen and (max-width: 1280px) {
            padding: 65px 10% 0 4%;
          }



          span.titulo {

            font-family: Flama;
            margin: 50px 0;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 62px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0;
            }
            @media screen and (max-width: 768px) {
              font-size: 45px;
            }
          }

          span.second-title {
            font-family: Flama;
            margin-bottom: 50px;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 62px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0;
            }
            @media screen and (max-width: 768px) {
              font-size: 45px;
            }
          }

          span.second-sub-title {
            font-family: Flama;
            margin: 50px 0 0;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 24px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0 0;
            }
            @media screen and (max-width: 767px) {
              font-size: 18px;
              line-height: 24px;

            }
          }

          span.sub-titulo {
            font-weight: 500;
            line-height: 28px;
            color: #343741;
            font-size: 15px;
          }

          .indicador {
            margin: 30px 0;
            width: 18px;
            height: 18px;
            background-color: #9E0349;
          }
        }
      }
    }
  }


  .container {


    .generic-block {

      padding: 30px 0 80px;


      .top-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .descripcion-block {
          padding-bottom: 80px;


          .titulo {
            font-family: Flama;
            color: #ad0455;
            display: block;
            font-size: 48px;
          }

          .sub-titulo {
            color: #8c8f94;
            font-size: 30px;
            font-family: Flama;

          }

        }

        .search-filter-block {
          select {
            @media screen and (max-width: 1280px) {
              padding: 12px 18px;
            }
            width: 200px;
            background-color: transparent;
            font-size: 14px;
            line-height: 18px;
            color: #575756;
            background-image: url(../../images/arrowdown.png);
            background-repeat: no-repeat;
            background-size: 14px 8px;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            padding: 12px 0;
            outline: 0;
            font-weight: bold;
            border: 1px solid transparent;
            border-bottom: 1px solid #575756;
            border-radius: 0;
            box-shadow: none;
            background-position: 100% center;
            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;

          }

        }

      }


      .contenedor-materiales-block {

        display: flex;
        flex-wrap: wrap;
        margin: -15px;
        position: relative;

        @media screen and (max-width: 1023px) {
          flex-direction: column;
        }

        .material-content-block {
          width: 50%;
          padding: 15px;

          @media screen and (max-width: 1023px) {
            width: 100%;
          }


          .material-content-block-parent {
            border: 3px solid #ebebeb;
            position: relative;
            height: 100%;
            padding: 40px;

            a.redirect {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              z-index: 2;
            }

            .material-content-block-top {
              padding-bottom: 35px;

              span.especialidad {
                font-family: Flama;
                color: #9e0348;
                font-size: 14px;
                border-bottom: 1px solid #9e0348;
                text-transform: uppercase;
                font-weight: bold;
              }

              span.date {
                color: #979ea6;
                font-weight: bold;
                padding-left: 5px;
              }
            }

            .material-content-block-mid {
              padding-bottom: 35px;


              .titulo-material-block {
                padding-bottom: 35px;
                display: block;

                a {
                  text-decoration: none;
                  color: #575756;
                  font-weight: bold;
                  font-size: 23px;
                }

              }

              .materia-imagen {
                max-width: 100%;
                height: auto;
                margin: 20px 0;
              }

              .subtitulo-material-block {
                color: #575756;
                font-size: 18px;
                display: block;

              }

            }


            .material-content-block-bottom {
              padding-bottom: 15px;
              padding-top: 15px;

              a {
                text-decoration: none;
                color: #343741;
                background-image: url("../../images/arrowleft.png");
                background-repeat: no-repeat;
                background-color: transparent;
                border: none;
                background-position-x: right;
                padding-right: 25px;
                background-position-y: 1px;
                text-transform: uppercase;
                font-size: 12px;
                font-family: "Rubik";
                position: absolute;
                font-weight: 500;
                width: 105px;
                bottom: 7%;
                background-size: 12px;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }


      .boton-cv {
        text-align: center;
        margin-top: 80px;

        .center {
          .btn,
          button {
            background: white;
            color: white;
            border: none;
            font-size: 10px;
            font-size: 1rem;
            //            background-color: #ad0455;
            background-color: transparent;


            letter-spacing: 1.5px;
            text-transform: uppercase;
            padding: 14px 21px;
            border: 2px solid #ad0455;
            transition: color 0.3s cubic-bezier(0.16, 0.08, 0.355, 1), background 0.1s cubic-bezier(0.16, 0.08, 0.355, 1);
            display: inline-block;
            cursor: pointer;
            width: 100%;
            min-width: 200px;
            min-width: 12rem;
            outline: none;
            vertical-align: middle;
            text-align: center;
            position: relative;
            overflow: hidden;
            @media (min-width: 400px) {
              width: auto;
            }
            @media (min-width: 800px) {
              font-size: 1.1rem;
              padding: .8rem 2.8rem;
            }

            &:hover {
              background: lighten(#ad0455, 5%);
              border-color: lighten(#ad0455, 5%);
              -webkit-box-shadow: inset 10px 67px 68px -60x rgba(0, 0, 0, 0.35);
              -moz-box-shadow: inset 10px 67px 68px -66px rgba(0, 0, 0, 0.35);
              box-shadow: inset 10px 67px 68px -66px rgba(0, 0, 0, 0.35);
              color: #fff;
              transition: .3s;

              span {
                color: white;
              }
            }

            span {
              font-family: Flama;
              font-weight: 500;
              color: black;

            }

          }

          .btn-border {
            background-color: transparent;
            color: #ad0455;
          }

          .btn-arrow {
            position: relative;
            transition: background-color 300ms ease-out;

            span {
              display: inline-block;
              position: relative;
              transition: all 300ms ease-out;
              will-change: transform;
            }

            &:hover span {
              transform: translate3d(-1rem, 0, 0);
            }

            svg {
              position: absolute;
              width: 1.1em;
              right: 0px;
              right: 0rem;
              opacity: 0;
              top: 50%;
              transform: translateY(-50%);
              transition: all 300ms ease-out;
              will-change: right, opacity;

              * {
                stroke-width: 5;
                stroke-color: transparent;
              }
            }

            &:hover svg {
              opacity: 1;
              right: -2rem;
            }
          }
        }

      }


      .contenedor-eventos-block {
        @extend .contenedor-materiales-block;


        .eventos-content-block {
          @extend .material-content-block;
          width: 33.33%;
          @media screen and (max-width: 1023px) {
            width: 100%;
          }


          .eventos-content-block-parent {
            @extend .material-content-block-parent;
            position: relative;
            height: 100%;


            .eventos-content-block-top {

              @extend .material-content-block-top;

            }

            .eventos-content-block-mid {

              @extend .material-content-block-mid;


              .titulo-eventos-block {
                @extend .titulo-material-block;

                font-size: 30px;
                font-family: Flama;
                font-weight: 500;

              }

              .subtitulo-eventos-block {
                @extend .subtitulo-material-block;
                font-family: Flama;
                font-size: 24px;
                font-weight: 400;

              }


              .evento-imagen {
                max-width: 100%;
                height: auto;
                margin: 20px 0;
              }
            }

            .eventos-content-block-bottom {
              @extend .material-content-block-bottom;
              position: absolute;
              bottom: 5%;


            }
          }
        }


      }


    }
  }

  .materiales-hide,
  .eventos-hide {
    display: none;
  }


  .numeric-pagination {
    display: flex;
    padding: 80px 0;
    align-items: center;
    justify-content: center;



    span {
      font-size: 24px;
      color: white;
      width: 50px;
      height: 50px;
      background-color: #ad0455;
      margin:0 5px;
      align-items: center;
      display: flex;
      justify-content: center;


      &:hover{
        cursor: pointer;
      }

    }

    a {
      color: #343741;
      font-size: 24px;
      width: 50px;
      height: 50px;
      margin:0 5px;
      align-items: center;
      display: flex;
      justify-content: center;
      &:hover{
        cursor: pointer;
      }
    }

  }


}
