.contact-form-home {
  display: flex;

  div.dynamic-sidebar-contactform {
    width: 65%;
    @media screen and (max-width: 430px) {
      width: 100%;
    }

    div.formulario-de-contacto {
      .formulario {
        //padding: 20px 20% 0 30%;
        padding: 20px 20% 0 35px;

        @media screen and (max-width: 1280px) {
          padding: 40px 5% 0 35px;
        }

        @media screen and (max-width: 480px) {
          padding: 60px 5% 0 6%;
        }
        background-color: #838A93;

        .campos {
          display: flex;
          align-items: flex-end;

          @media screen and (max-width: 430px) {
            flex-direction: column;
            align-items: normal;

            .sub-titulo {
              display: none;
            }
          }

          .formulario-left {

            .titulo {
              font-family: Flama;
              font-size: 42px;
              line-height: 52px;
              color: white;
              margin-bottom: 65px;

              @media screen and (max-width: 1280px) {
                margin-bottom: 11px;
              }

            }

            padding-right: 80px;

            @media screen and (max-width: 1280px) {
              padding-right: 45px;
            }

            input {
              width: 350px;
              padding: 12px 0;
              outline: 0;
              border: 1px solid transparent;
              border-bottom: 1px solid #D4D4D4;
              border-radius: 0;
              box-shadow: none;
              background-position: 100% center;
              background-color: transparent;
              color: white;
              font-weight: 500;
              font-family: "IBM Plex Sans";

              &:focus {
                color: white;

                &::placeholder {
                  transition: .3s;
                  font-weight: 500;
                  color: white;

                }
              }

              &::placeholder {
                font-family: "IBM Plex Sans";
                font-weight: 200;
                color: #EFF3F6;

              }

              @media screen and (max-width: 1280px) {
                width: 260px;
              }
              @media screen and (max-width: 480px) {
                width: 100%;
              }

            }

          }

          .formulario-right {

            .sub-titulo {
              font-family: "IBM Plex Sans";
              font-size: 18px;
              color: white;
              padding-bottom: 35px;
              line-height: 28px;
              @media screen and (max-width: 1280px) {
                padding-bottom: 0;
                font-size: 12px;
              }
              margin-bottom: 20px;
              font-weight: 500;
            }

            @media screen and (max-width: 480px) {
              padding-right: 45px;
            }

            input {
              width: 350px;
              padding: 12px 0;
              outline: 0;
              border: 1px solid transparent;
              border-bottom: 1px solid #D4D4D4;
              border-radius: 0;
              box-shadow: none;
              background-position: 100% center;
              background-color: transparent;
              color: white;
              font-weight: 500;
              font-family: "IBM Plex Sans";

              &:focus {
                color: white;

                &::placeholder {
                  transition: .3s;
                  font-weight: 500;
                  color: white;
                }
              }

              &::placeholder {
                font-family: "IBM Plex Sans";
                font-weight: 200;
                color: #EFF3F6;
              }

              @media screen and (max-width: 1280px) {
                width: 260px;
              }
              @media screen and (max-width: 480px) {
                width: 100%;
              }
            }

          }
        }

      }

      .mensaje-div {
        //margin: -1px;

        padding: 0 8% 0 35px;
        @media screen and (max-width: 1280px) {
          padding: 0 8% 0 35px;
        }

        @media screen and (max-width: 480px) {
          padding: 0 66px 0 6%;
        }

        background-color: #838A93;

        textarea {
          width: 100%;
          max-width: 1200px;
          height: 50px;
          padding: 12px 0;
          outline: 0;
          border: 1px solid transparent;
          border-bottom: 1px solid #D4D4D4;
          border-radius: 0;
          box-shadow: none;
          background-position: 100% center;
          background-color: transparent;
          color: white;
          font-weight: 500;
          font-family: "IBM Plex Sans";

          &:focus {
            color: white;

            &::placeholder {
              transition: .3s;
              font-weight: 500;
              color: white;

            }
          }

          &::placeholder {
            font-family: "IBM Plex Sans";
            font-weight: 200;
            color: #EFF3F6;

          }

        }
      }

      .mid-form {
        padding: 0 20% 0 35px;
        @media screen and (max-width: 1280px) {
          //padding: 40px 5% 0 35px;
        }
        @media screen and (max-width: 480px) {
          padding: 10px 20% 60px 6%;
        }

        p {
          width: 100%;

          span:nth-child(1) {
            span {
              display: flex;
              align-items: center;
            }
          }


        }

        input {
          padding: 0 !important;
          margin-right: 5px !important;
        }

        display: flex;
        background-color: #838A93;
        justify-content: space-between;
        align-items: center;

        .sub-titulo-mobile {
          display: none;
        }

        @media screen and (max-width: 430px) {

          display: block;

          .sub-titulo-mobile {
            margin: 18px 0;
            display: block;
          }

        }

        span {
          font-size: 15px;
          color: #FDFDFD;
          font-family: "IBM Plex Sans";
          font-weight: normal;

          input {
            vertical-align: middle;
            margin: 15px 0;

            @media screen and (max-width: 430px) {
              margin: 10px 0;
            }

            border: 1px solid #D4D4D4;
            height: 15px;
            width: 15px;

          }
        }

        input {
          margin-bottom: 15px;
          font-size: 15px;
          background-color: Transparent;
          background-repeat: no-repeat;
          border: none;
          cursor: pointer;
          overflow: hidden;
          outline: none;
          font-family: Flama;
          font-weight: 500;
          color: white;
          transition: .6s;

        }



        .wpcf7-checkbox{
          span.first{
            margin-left: 0 !important;
          }
          span.last{
            margin-left: 10%;
          }
        }


      }

      .bottom-form {
        background-color: #F7F9FB;
        padding: 40px 0 35px 35px;

        @media screen and (max-width: 1280px) {
          padding: 40px 0 35px 35px;
        }

        @media screen and (max-width: 480px) {
          padding: 37px 5% 60px 6%;
        }
        display: flex;
        justify-content: left;

        div {

          margin: 0 50px 0 0;

          p {
            margin-bottom: 5px;
            color: #343741;
            font-size: 11px;
            //text-transform: uppercase;
            font-weight: 700;

            span {
              color: #979EA6;
              font-family: "IBM Plex Sans";
              font-size: 15px;

            }
          }

        }

      }
    }
  }

  div.sideimg {
    width: 35%;
    background-image: url("../../images/homecontact-1024x683.jpg");
    background-position: center;
    background-size: cover;

    @media screen and (max-width: 430px) {
      display: none;
    }

  }
}

