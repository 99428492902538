@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-BoldItalic.woff2') format('woff2'),
  url('../fonts/Karla-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Thin.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Light.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-Regular.woff2') format('woff2'),
  url('../fonts/Karla-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-BoldItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-SemiBold.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Bold.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans.woff2') format('woff2'),
  url('../fonts/IBMPlexSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-Italic.woff2') format('woff2'),
  url('../fonts/Karla-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-ThinItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Italic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-Bold.woff2') format('woff2'),
  url('../fonts/Karla-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-SemiBold.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans.woff2') format('woff2'),
  url('../fonts/IBMPlexSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-ExtraLightItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Medium.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Bold.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-LightItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-MediumItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-ExtraLight.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-BlackItalic.woff2') format('woff2'),
  url('../fonts/Flama-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-Ultralight.woff2') format('woff2'),
  url('../fonts/Flama-Ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-Thin.woff2') format('woff2'),
  url('../fonts/Flama-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-Black.woff2') format('woff2'),
  url('../fonts/Flama-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-Extrabold.woff2') format('woff2'),
  url('../fonts/Flama-Extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Flama Basic';
  src: url('../fonts/Flama-BasicItalic.woff2') format('woff2'),
  url('../fonts/Flama-BasicItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-ExtraboldItalic.woff2') format('woff2'),
  url('../fonts/Flama-ExtraboldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-ThinItalic.woff2') format('woff2'),
  url('../fonts/Flama-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-Light.woff2') format('woff2'),
  url('../fonts/Flama-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama.woff2') format('woff2'),
  url('../fonts/Flama.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama.woff2') format('woff2'),
  url('../fonts/Flama.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-Bold.woff2') format('woff2'),
  url('../fonts/Flama-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-Semibold.woff2') format('woff2'),
  url('../fonts/Flama-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-UltralightItalic.woff2') format('woff2'),
  url('../fonts/Flama-UltralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Flama Book';
  src: url('../fonts/Flama-BookItalic.woff2') format('woff2'),
  url('../fonts/Flama-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-Bold.woff2') format('woff2'),
  url('../fonts/Flama-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Flama Book';
  src: url('../fonts/Flama-Book.woff2') format('woff2'),
  url('../fonts/Flama-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-MediumItalic.woff2') format('woff2'),
  url('../fonts/Flama-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-LightItalic.woff2') format('woff2'),
  url('../fonts/Flama-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-Medium.woff2') format('woff2'),
  url('../fonts/Flama-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-SemiboldItalic.woff2') format('woff2'),
  url('../fonts/Flama-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Flama Basic';
  src: url('../fonts/Flama-Basic.woff2') format('woff2'),
  url('../fonts/Flama-Basic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-Semibold.woff2') format('woff2'),
  url('../fonts/Flama-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Flama';
  src: url('../fonts/Flama-BoldItalic.woff2') format('woff2'),
  url('../fonts/Flama-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

