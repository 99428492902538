.single-eventos {

  span.ubicacion {
    font-family: Flama;
    font-size: 11px;
    color: #868891;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    a{
      font-family: Flama;
      font-size: 11px;
      color: #868891;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
      &:hover{
        text-decoration: none;
      }
    }
    padding: 10px 0 50px;
    display: block;
  }


  .hero-top {

    margin-bottom: 40px;


    .hero-top-image {
      position: relative;
      background-position-y: center;

      .opacidad-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.6);
        text-align: right;
        padding-right: 350px;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        div.hero-top-info {
          //padding: 100px 13% 0 28%;
          display: inline-block;
          text-align: left;
          @media screen and (max-width: 1280px) {
            padding: 65px 10% 0 4%;
          }


          span.titulo {

            font-family: Flama;
            margin: 50px 0;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 62px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0;
            }
            @media screen and (max-width: 768px) {
              font-size: 45px;
            }
          }

          span.second-title {
            font-family: Flama;
            margin-bottom: 50px;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 62px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0;
            }
            @media screen and (max-width: 768px) {
              font-size: 45px;
            }
          }

          span.second-sub-title {
            font-family: Flama;
            margin: 50px 0 0;
            line-height: 64px;
            color: #9E0348;
            display: block;
            font-size: 24px;
            @media screen and (max-width: 1280px) {
              margin: 30px 0 0;
            }
            @media screen and (max-width: 768px) {
              font-size: 18px;
            }
          }

          span.sub-titulo {
            font-weight: 500;
            line-height: 28px;
            color: #343741;
            font-size: 15px;

            @media screen and (max-width: 767px) {
              line-height: 24px;
            }
          }

          .indicador {
            margin: 30px 0;
            width: 18px;
            height: 18px;
            background-color: #9E0349;
          }
        }
      }
    }
  }

  .contenido-material {


    .contenedor-divs {
      display: flex;
      @media screen and (max-width: 1023px) {
        flex-direction: column;
      }

      .info-evento {
        width: 100%;
        //margin: 0 25px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;


        .fecha-ubicacion {
          color: #343741;
          font-size: 24px;
        }


        span.especialidad-material {
          font-weight: bold;
          color: #ad0455;
          font-size: 15px;
          margin-top: 20px;

          text-transform: uppercase;
          text-decoration: underline;
          display: block;
        }


        span.titulo-evento {
          font-size: 40px;
          font-weight: 500;
          font-family: Flama;

        }

        span.info {

          margin-top: 20%;
          color: #343741;
          font-size: 18px;
          font-family: "IBM Plex Sans";
          font-weight: bold;
        }

        span.info-important {
          font-family: "IBM Plex Sans";
          font-size: 18px;
          font-weight: bold;
          color: #9e0348;

          @media screen and (max-width: 1023px){
            margin-bottom: 50px;
          }
        }

      }

      .imagen-material {
        flex-grow: 0;
        flex-shrink: 0;
        width: 50%;

        #map{
          width: 100%;
          height: 100%;
        }


        @media screen and (max-width: 1023px) {
          width: auto;
        }
        display: flex;
        flex-direction: column;


        img {
          height: auto;
          margin: 0 auto;
          width: 100%;
        }


        .formato {
          position: relative;
          z-index: 1;

          .lupa {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .imagen-lupa {
              width: 50px;
              height: 50px;
              background-repeat: no-repeat;
              display: flex;
              justify-content: center;
            }

            a {
              text-transform: uppercase;
              color: white;
              font-family: Flama;
              font-weight: 500;

              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }

          }

          &:hover {
            background-color: #AD0455;
            opacity: 1;
            cursor: pointer;

            .imagen {
              opacity: .2;
            }

            .lupa {
              z-index: 4;
              opacity: 1 !important;


              a, span {
                position: absolute;
              }

              span {
                top: 53%;
                color: white;
                text-decoration: underline;
                font-weight: 500;

                &:hover {
                  cursor: pointer;
                }
              }
            }

          }


        }


        .galeria-imagenes-material-de-lectura { //
          display: flex;
          list-style: none;
          padding: 0;
          margin: 10px 0;

          li {
            span {
              font-size: 18px;
              color: black;
              width: 40px;
              height: 40px;
              align-items: center;
              display: flex;
              justify-content: center;
              transition: .3s;

              &:hover {
                cursor: pointer;
                background-color: #ad0455;
                color: white;
              }
            }

            span.relleno {
              background-color: #ad0455;
              color: white;
            }
          }
        }

        span.info {
          color: #343741;
          font-size: 18px;
          font-family: "IBM Plex Sans";
          font-weight: bold;
        }

        span.info-important {
          font-family: "IBM Plex Sans";
          font-size: 18px;
          font-weight: bold;
          color: #9e0348;
        }

      }

    }


    .back-button {
      //margin-bottom: 80px;
      text-align: right;

      a {
        color: #868891;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 2px;
        font-weight: 500;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .indicador {
      width: 18px;
      height: 18px;
      background-color: #9E0349;
    }


  }


}
