div.slider {
  position: relative;
  max-width: 2500px;
  margin: 0 auto;


  .n2-ss-slider-3 {
    .transparentFixHero {
      position: absolute;
      width: 60%;
      height: 100%;
      background-color: white;
      z-index: 12;
      opacity: .6;
      pointer-events: none;
    }
  }


  @media screen and (max-width: 480px) {
    .n2-section-smartslider {
      span.titulo {
        font-size: 33px !important;
      }

      .text-top {
        margin: 0 0 0 3.5% !important;

        p {
          line-height: 26px !important;
        }
      }

      .text-bot {
        margin: 0 0 3% 3.5% !important;
      }

      p {
        line-height: 22px !important;

        span.info {
          display: block;
          margin-top: 22px;

        }

      }

      #n2-ss-2-align {
        .n2-padding {
          #n2-ss-2 {
            .n2-ss-slider-1 {
              width: 100% !important;

              @media screen and (min-width: 1024px) {
                width: 103% !important;

              }

              .n2-ss-slider-2 {
                height: 158%;
              }

              .n2-ss-widget {
                bottom: 0;
                top: 123%;

                @media screen and (max-width: 500px) {
                  top: 275px;
                }

                @media screen and (max-width: 410px) {
                  top: 300px;
                }

                @media screen and (max-width: 375px) {
                  top: 360px;
                }

              }

            }
          }
        }
      }

    }
  }

  .n2-section-smartslider {

    @media screen and (max-width: 480px) {
      height: 354px;
    }

    span.titulo {
      @media screen and (max-width: 1280px) {
        font-size: 30px;
      }
    }

    span.sub-titulo {
      @media screen and (max-width: 1280px) {
        font-size: 30px;
        line-height: 42px;
      }
    }

    div#n2-ss-2 {
      .nextend-bullet-bar {
        div.n2-ow {
          @media screen and (max-width: 480px) {
            padding: 25px 10px 10px 25px;
          }
        }
      }
    }

    div#n2-ss-2-arrow-previous {
      position: relative !important;
      left: 0 !important;
      width: 32px !important;
      height: 32px !important;
      //margin-left: 23%;
      //top: -75px !important;
      margin-left: 32vw;
      top: -11vw !important;
      z-index: 20;

      @media screen and (max-width: 2500px) {
        top: -4vw !important;
      }

      @media screen and (max-width: 2350px) {
        margin-left: 42vw !important;

      }
      @media screen and (max-width: 2000px) {
        margin-left: 37vw !important;
        top: -8vw !important;
      }

      @media screen and (max-width: 1919px){
        margin-left: 30vw!important;
        top: -5vw!important;
      }

      @media screen and (max-width: 1500px) {
        margin-left: 28vw !important;
        top: -5vw !important;
      }


      @media screen and (max-width: 1440px) {
        margin-left: 26%;

      }

      @media screen and (max-width: 1280px) {
        margin-left: 20%;
        top: -7vw !important;

      }
      @media screen and (max-width: 1024px) {
        margin-left: 23vw !important;

      }
      @media screen and (max-width: 768px) {
        top: -50px !important;
        margin-left: 27%;
      }

      img.n2-ow {
      }

      //
    }

    div#n2-ss-2-arrow-next {
      position: relative !important;
      left: 0 !important;
      width: 32px !important;
      height: 32px !important;
      //top: -75px !important;
      top: -11vw !important;
      z-index: 20;


      @media screen and (max-width: 2500px) {
        top: -4vw !important;
      }

      @media screen and (max-width: 2000px) {
        top: -8vw !important;
      }

      @media screen and (max-width: 1919px){
         top: -5vw!important;
      }

      @media screen and (max-width: 1500px) {
        top: -5vw !important;
      }

      @media screen and (max-width: 1440px) {

      }

      @media screen and (max-width: 1280px) {
        top: -7vw !important;
      }

      @media screen and (max-width: 768px) {
        top: -50px !important;
      }

      img.n2-ow {
      }
    }

    .text-top {
      @media screen and (max-width: 1440px) {
        //margin: 0 0 0 12.5% !important;
        margin: 0 !important;
        padding: 0;
        padding-left: 0 !important;
      }
      @media screen and (min-width: 500px) and (max-width: 1280px) {
        //margin: 0 0 0 3.5% !important;
        p {
          font-size: 36px !important;
          line-height: 42px !important;
        }
      }
    }

    .text-bot {
      @media screen and (max-width: 1440px) {
        margin: 0 0 0 12.5% !important;
      }
      @media screen and (min-width: 500px) and (max-width: 1280px) {
        margin: 0 0 3% 3.5% !important;
        p {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }

    }

    div.n2-ss-control-bullet {
      //margin-left: 10.5% !important;
      margin-bottom: 3% !important;
      //left: 5px !important;
      bottom: -3% !important;
      @media screen and (max-width: 1280px) {
        margin-bottom: 1% !important;
        //margin-left: 4% !important;
        //left: 5px !important;
        bottom: 2% !important;
      }
    }

    @media screen and (max-width: 480px) {
      div.n2-ss-slide-background-image {
        opacity: .6 !important;
      }
    }
  }
}


#recuadroBlanco {
  position: absolute;
  //width:85%;
  //height:100%;
  //opacity: 0.8;
  //background-color:white;
  z-index: 987;
  pointer-events: none;
  margin-left: 10.5% !important;
  left: 20px;
  bottom: 4% !important;

  @media screen and (max-width: 1280px) {
    left: 20px;
    margin-left: 4% !important;
    bottom: 7%;
  }

  span {
    width: 45px;
    height: 45px;
    display: inline-block;
    color: #000000;
    font-size: 18px;
    font-family: "IBM Plex Sans";

    &:nth-child(even) {
      margin: 0 10px;
    }

  }

  @media screen and (max-width: 480px) {
    bottom: 0;
    left: 13px;
    top: 79%;
    margin-left: 4%;

    span {
      width: 25px;
      height: 30px;
      font-size: 16px;
    }

  }

}


@media screen and (min-width: 1700px) {
  #recuadroBlanco {
    position: absolute;
    z-index: 987;
    pointer-events: none;
    //margin-left: 10.5% !important;
    //left: 34px;
    bottom: 5% !important;
  }
}

@media screen and (min-width: 1600px) {

  #recuadroBlanco {
    margin-left: 10.5% !important;
  }

  div.n2-ss-control-bullet {
    //margin-left: 10.5% !important;
  }

  //div.slider {
  //  position: relative;
  //  height: 600px;
  //}

  .n2-ss-section-main-content.n2-ss-layer-content.n2-ow {
    padding: 0 !important;
    margin: 0 !important;
    //width: 50% !important;
    max-width: 100% !important;
  }

  .n2-ss-layer.n2-ow.text-top {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    //width: 59% !important;
    //background-color: hsla(0, 0%, 100%, .6) !important;
    //background: hsla(0, 0%, 100%, .6) !important;
  }

  .n2-ss-slider .n2-ss-layer[data-has-maxwidth="1"][data-pm=normal] {
    //padding: 100px 13% 0 28% !important;
    margin: 100px 0 0 0 !important;
    max-width: 100% !important;
  }

  .n2-ss-item-content.n2-ow {
    //margin: 0 !important;

    .info {
      font-weight: 500 !important;
      line-height: 28px !important;
      color: #343741 !important;
      font-size: 15px !important;
    }

  }

  .n2-ss-layer.n2-ow.text-bot {
    padding: 0 13% 0 28% !important;
    margin: 0 !important;
  }

  div#n2-ss-2 .n2-ss-slider-1 {
    //height: 600px !important;
    //overflow: hidden !important;
    width: 100%;
  }

  div#n2-ss-2 .n2-ss-layers-container {
    margin: 0 auto !important;
  }

  div.slider .n2-section-smartslider div.n2-ss-control-bullet {
    //margin-left: 13.5% !important;
    //margin-bottom: 0 !important;
    position: relative !important;
    //left: 5px !important;
    bottom: 25% !important;

    @media screen and (min-width: 2500px) {
      bottom: 40% !important;
    }
  }

  #recuadroBlanco {
    position: absolute;
    z-index: 987;
    pointer-events: none;
    //margin-left: 10.5% !important;
    left: 26px;
    bottom: 4% !important;
  }

  #recuadroBlanco, div.n2-ss-control-bullet {
    //margin-left: 13% !important;
  }

}

.n2-ss-slider-2.n2-ow {

  .titulo {
    font-family: Flama;
    font-size: 62px;
  }

  .sub-titulo {
    font-family: Flama;
    font-size: 62px;
  }

  .info {
    font-weight: 500;
    line-height: 28px;
    color: #343741;
    font-size: 15px;
  }
}

html {
  margin: 0 !important;
}


@media screen and (max-width: 1699px) {
  .n2-ss-slider-2.n2-ow .titulo,
  .n2-ss-slider-2.n2-ow .sub-titulo {
    font-family: Flama;
    font-size: 38px;
    line-height: 42px;
  }

}


@media screen and (max-width: 1599px) {
  .n2-ss-slider-2.n2-ow .titulo
  .n2-ss-slider-2.n2-ow .sub-titulo {
    font-family: Flama;
    font-size: 32px;
    line-height: 42px;
  }

}

@media screen and (min-width: 1368px) and (max-width: 1698px) {
  div.slider .n2-section-smartslider div.n2-ss-control-bullet {
    margin-left: 12.5% !important;
  }

  #recuadroBlanco {
    margin-left: 12.5% !important;
  }
}

@media screen and (max-width: 1280px) {

  div.slider .n2-section-smartslider span.titulo {
    font-size: 30px;
    line-height: 42px;
  }

  div.slider .n2-section-smartslider div.n2-ss-control-bullet {
    margin-bottom: 1% !important;
    margin-left: 2% !important;
    left: 0px !important;
    bottom: 2% !important;
  }


}


@media screen and (max-width: 1024px) {

  .n2-ss-slider .n2-ss-slider-1 .n2-ss-layer-content {
    padding: 0 !important;
  }

}


@media screen and (max-width: 1023px) {

  div#n2-ss-2 .n2-ss-layers-container {
    margin-top: 0 !important;
  }

  header.banner .desktop-nav #menu-header-menu .active a, header.banner .desktop-nav #menu-header-menu a:hover {
    text-decoration: none;
    border-bottom: 5px solid #9e0349;
    padding-bottom: 5px;
  }

  div.slider .n2-section-smartslider span.titulo {
    font-size: 32px !important;
    line-height: 42px !important;
  }

  .n2-ss-slider .n2-ss-layer-content[data-verticalalign=center] {
    padding: 0 !important;
  }

  div.slider .n2-section-smartslider .text-top {
    margin: 0 !important;
  }

  div.slider .n2-section-smartslider .text-bot {
    margin: 0 !important;
  }

  //div.slider .n2-section-smartslider #n2-ss-2-align .n2-padding #n2-ss-2 .n2-ss-slider-1 .n2-ss-slider-2 {
  //  height: 600px !important;
  //}
  //
  //div.slider .n2-section-smartslider {
  //  height: 600px !important;
  //}

  .n2-ss-slider .n2-ss-slider-1 .n2-ss-layer-content {
    padding: 65px 10% 0 5% !important;
  }

  div.slider .n2-section-smartslider #n2-ss-2-align .n2-padding #n2-ss-2 .n2-ss-slider-1 .n2-ss-widget {
    visibility: hidden;
  }

  div#n2-ss-2 .n2-ss-slide {
    //height: 600px !important;
  }

}

div#n2-ss-2 .n2-ss-control-bullet {
  visibility: visible !important;
  text-align: center;
  justify-content: left !important;
  opacity: 1 !important;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1440px;
}


body .n2-ss-bullet-thumbnail-container {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
