#materiales-destacados-home {

  .contenido-destacado {

    padding-top: 65px;
    padding-bottom: 65px;
    display: flex;
    margin: -15px;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }

    div.titulo-materiales-destacados{
      width: 15%;
      margin: auto 15px;
      text-align: center;
      a{
        text-decoration: none;
      }
      span{
        color:#343741;
        font-size: 30px;
      }
      @media screen and (max-width: 1023px){
        width: auto;
      }
    }


    .block-container {
      padding: 15px;
      width: 28.33%;
      position: relative;


      @media screen and (max-width: 1023px) {
        width: 100%;
      }

      .block-destacado {

        border: 1px solid #E8E9E9;
        height: 100%;
        position: relative;
        z-index: 1;

        a.redirect {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 2;

          &:hover {
            cursor: pointer;
          }

        }


        div.imagen-top {
          background-size: cover;
          height: 250px;
          background-position: center;
        }

        div.mid-content-info {
          border-top: 1px solid #E8E9E9;
          padding: 15px;

          span.titulo-destacado {
            color: #141414;
            font-weight: 500;
            font-size: 14px;
            font-family: "IBM Plex Sans";
          }
        }

      }


    }


  }


  .contenedor-noticias-destacados {
    margin-bottom: 105px;
  }


}
