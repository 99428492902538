header.banner {
  @media screen and (max-width: 600px) {
    display: none !important;
  }

  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 999;
  top: 0;
  background-color: white;
  //margin: 25px 0;
  padding: 20px 0 10px 0;

  @media screen and (max-width: 480px) {
    margin: 0;
    height: 88px;
  }
  display: flex;

  .desktop-nav {

    @media screen and (min-width: 1040px) {
      div {
        padding: 0 !important;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    div:nth-child(1) {
      display: flex;
      align-items: center;
      width: 100%;

      @media screen and (max-width: 1280px) {
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        width: 100%;
      }

      nav {

        margin-left: 40px;
        @media screen and (max-width: 1280px) {
          margin-left: 15px;
        }
        @media screen and (max-width: 768px) {
          margin: 20px 0;
        }

        ul {
          li {
            margin: 0 30px;
            @media screen and (max-width: 1280px) {
              margin: 0 15px;
            }

            a {
              padding-bottom: 5px;
              font-weight: 500;
              font-family: "Flama";
              color: #343741 !important;
            }
          }
        }
      }
    }

    div:nth-child(2) {
      width: 400px;

      @media screen and (max-width: 768px){
        width: 300px;
      }

      label {
        width: 100%;


        input.search-field {
          width: 100%;
          @media screen and (max-width: 1280px) {
            padding: 12px 18px;
          }
          background-color: transparent;
          transition: transform 250ms ease-in-out;
          font-size: 14px;
          line-height: 18px;
          background-image: url(../../images/searchmobile.png);
          background-repeat: no-repeat;
          background-size: 18px 18px;
          backface-visibility: hidden;
          transform-style: preserve-3d;
          //padding: 12px 0;
          padding-right: 25px;
          outline: 0;
          border: 1px solid transparent;
          border-bottom: 1px solid #575756;
          border-radius: 0;
          box-shadow: none;
          background-position: 100% center;


          &::-webkit-search-cancel-button{
          display: none;
          }

          &::placeholder {
            color: color(#575756 a(0.8));
            letter-spacing: 1.5px;
            font-size: 12px;
          }


        }
      }

    }

@media screen and (min-width: 700px){

  #menu-header-menu{
    .active{
      a{
        text-decoration: none;
        border-bottom: 5px solid #9e0349;
        //padding-bottom: 32px;
      }
    }
    a:hover{
      text-decoration: none;
      border-bottom: 5px solid #9e0349;
      //padding-bottom: 32px;
    }
  }
}


  }

}

header.header-mobile {
  position: sticky;
  z-index: 40;
  top: 0;
  background-color: white;
  padding: 20px 15px;
  @media screen and (min-width: 500px) {
    display: none;
  }

  .mobile-nav {
    display: none;

    @media screen and (max-width: 599px) {
      display: block;
      position: relative;
      width: 103%;
    }

    nav {
      display: flex;

    }

    a.brand {
      img {
        width: 80px;
      }
    }

    a {
      text-decoration: none;
      color: #232323;

      transition: color 0.3s ease;
    }

    a:hover {
      color: tomato;
    }

    #menuToggle {
      display: block;
      position: absolute;
      top: 15px;
      right: 0;
      z-index: 1;
      padding-right: 20px;
      -webkit-user-select: none;
      user-select: none;
    }

    #searchToggle {
      display: block;
      position: absolute;
      top: 15px;
      right: 0;
      z-index: 1;
      padding-right: 20px;
      -webkit-user-select: none;
      user-select: none;
    }

    #menuToggle input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
      -webkit-touch-callout: none;
    }

    #searchToggle input.reacciona {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
      -webkit-touch-callout: none;


    }

    #menuToggle span {
      display: block;
      width: 25px;
      height: 1px;
      margin-bottom: 5px;
      position: relative;
      background: black;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      opacity 0.55s ease;
    }

    #searchToggle .img-search {
      display: block;
      margin-bottom: 5px;
      position: relative;
      z-index: 1;
    }

    #menuToggle span:first-child {
      transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }

    #menuToggle input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;
    }

    #menuToggle input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    #menuToggle input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, -1px);
    }

    #menu-header-menu-1 {
      flex-direction: column;
      margin-top: 15vh;
      text-align: right;
    }

    #menu {
      display: none;
      position: absolute;
      width: 430px;
      margin: 30px 0 0 -380px;
      padding: 20px;
      right: -5px;
      padding-top: 10px;
      height: 100vh;
      background: #860134;
      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      transform-origin: 0% 0%;
      transform: translate(100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    }

    #menu li {
      padding: 10px 0;
      font-size: 22px;

      a {
        color: white;
        font-family: Flama;
        font-size: 30px;
        text-transform: uppercase;
      }
    }

    #menuSearch {
      display: none;
      position: absolute;
      width: 430px;
      margin: 30px 0 0 -380px;
      padding: 20px;
      padding-top: 10px;
      height: 100vh;
      background: #858D95;
      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      transform-origin: 0% 0%;
      transform: translate(100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

      .search-submit {
        opacity: 0 !important;
      }

      form {
        align-items: center;
        justify-content: center;
        height: 60vh;

        label {
          border-bottom: 1.5px solid #cacacaab;
          width: 85%;

          input {
            text-align: center;
            background-color: #858D95 !important;
            border: none;
            font-family: "IBM Plex Sans";
            font-size: 24px;
            width: 100%;
            margin-bottom: 10px;

            &::placeholder {
              color: white;
            }

            &:focus {
              border: none;
            }
          }
        }
      }

      span {
        font-size: 24px;
        color: #cacacaab;
        font-family: "IBM Plex Sans";
        position: absolute;
        top: 40%;
        left: 29%;
      }
    }

    #menuToggle input:checked ~ ul {
      display: block;
      transform: none;
    }

    #searchToggle input.reacciona:checked ~ div {
      display: block;
      transform: none;
    }

    div.img-search {
      background-image: url('../../images/searchmobile.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
    }

    .divider {
      width: 2px;
      height: 45px;
      left: 88%;
      position: absolute;
      background-color: #ededed;
    }

  }
}
