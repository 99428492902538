
div.footer {
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;

  @media screen and (max-width: 480px) {
    display: block;
  }

  .left-footer {
    width: 30%;
    .images-left-footer{
      display: flex;
      align-self: baseline;
      align-items: end;
    }


    @media screen and (max-width: 1280px) {
      width: 25%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }

    img {
      display: block;
      margin-bottom: 45px;

    }


    div.textwidget {
      p {
        font-size: 15px;
      }
    }

    span {
      font-family: 'IBM Plex Sans', sans-serif;
      color: #343741;
      font-size: 12px;
      line-height: 30px;

      @media screen and (max-width: 480px) {
        display: none;
      }
    }

  }

  .right-footer {
    width: 60%;
    margin-left: 2%;

    @media screen and (max-width: 1280px) {
      width: 65%
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      margin: 0;
    }

    .informacion {
      display: flex;
      justify-content: space-around;

      @media screen and (max-width: 768px) {
        flex-flow: wrap;
      }

      @media screen and (max-width: 480px) {
        justify-content: space-between;
      }

      div {
        margin: 20px 15px;
        @media screen and (max-width: 1280px) {
          margin: 20px 0;
        }

        @media screen and (max-width: 768px) {
          margin: 20px 30px;
        }

        @media screen and (max-width: 480px) {
          margin: 0 0 50px 0;
          justify-content: space-between;
          height: min-content;

        }

        display: flex;
        flex-direction: column;

        span {
          color: #343741;
          margin-bottom: 30px;
          font-family: Flama, sans-serif;
          font-weight: 500;
          font-size: 11px;
        }

        a {
          color: #979EA6;
          font-family: "IBM Plex Sans", sans-serif;
          font-weight: normal;
          font-size: 15px;
          line-height: 30px;
        }
      }
    }


    .leyenda {
      margin: 20px 30px;
      color: #343741;
      font-family: Flama, sans-serif;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }

    }

  }

}

div.bottom-footer {
  display: flex;

  @media screen and (max-width: 480px) {
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column-reverse;
  }

  .copyright {
    width: 30%;

    @media screen and (max-width: 1280px) {
      width: 25%;
    }

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    span {
      color: #979EA6;
      font-size: 13px;
    }
  }

  div.div-newsletter {
    margin-left: 2%;
    width: 60%;

    @media screen and (max-width: 1280px) {
      width: 65%;
      margin-left: 0;
    }

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    .newsletter {
      div {
        margin: 0 30px;

        form {
          p {
            label {
              display: none !important;
            }

            input {
              width: 340px !important;

              @media screen and (max-width: 768px) {
                width: auto !important;
                min-width: 300px;
                padding-right: 38px !important;
              }


              @media screen and (max-width: 480px) {
                width: 284px !important;
              }

              background-color: transparent;
              transition: transform 250ms ease-in-out;
              font-size: 14px;
              line-height: 18px;
              color: #575756;
              background-image: url(../images/arrow-newsletter.svg);
              background-repeat: no-repeat;
              background-size: 18px 18px;
              backface-visibility: hidden;
              transform-style: preserve-3d;
              padding: 12px 0;
              outline: 0;
              border: 1px solid transparent;
              border-bottom: 1px solid #575756;
              border-radius: 0;
              box-shadow: none;
              background-position: 100% center;

              &::placeholder {
                color: color(#575756 a(0.8));
                letter-spacing: 1.5px;
                font-size: 12px;
              }

              &:hover,
              &:focus {

              }


            }

            input[type=submit] {
              display: none;
            }
          }
        }
      }
    }
  }

}


footer.content-info {
  border-top: 2px solid #f7f9fb;
}
